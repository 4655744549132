import {
    GET_BONUSES_START,
    GET_BONUSES_SUCCESS,
    GET_BONUSES_FAIL
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    list: []
};

const getBonusesStart = (state) => {
    return ({
        ...state,
        loading: true,
        list: []
    })
}

const getBonusesSuccess = (state, { list }) => {
    return ({
        ...state,
        loading: false,
        list: [...list],
    })
}

const getBonusesFail = (state, { error }) => {
    return ({
        ...state,
        loading: false,
        list: [],
        error: error
    })
}


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BONUSES_FAIL:
            return getBonusesFail(state, action)
        case GET_BONUSES_SUCCESS:
            return getBonusesSuccess(state, action)
        case GET_BONUSES_START:
            return getBonusesStart(state, action)
        default:
            return state;
    }
};