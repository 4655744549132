import React from 'react'

export default ({ locationName, street, city, phone, note, updateFromToDateTime, date, time }) => (

    <React.Fragment>
        <div className="address">
            <i className=" fas fa-map-marker-alt"></i>
            <div className="checkout-step-box-content">
                <div>{locationName}</div>
                <div>{street}</div>
                <div>{city}</div>
            </div>
        </div>
        <div className="address">
            <i className="fas fa-phone fa-rotate-90"></i>
            <div className="checkout-step-box-content">
                <div>{phone ? phone : ' '}</div>
            </div>
        </div>
        <div className="address">
            <i className="far fa-comment-alt"></i>
            <div className="checkout-step-box-content">
                <div>{note}</div>
            </div>
        </div>
        <div className="address from-to-date-time" onClick={updateFromToDateTime}>
            <i className="fas fa-calendar-alt"></i>
            <div className="checkout-step-box-content">
                <div>{date}</div>
            </div>
            <br />
            <i className="far fa-clock"></i>
            <div className="checkout-step-box-content">
                <div>{time}</div>
            </div>
        </div>
    </React.Fragment>
)