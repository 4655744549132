import { put, call } from "redux-saga/effects";

import * as actions from "../actions";

export function* GetAddresses({ token }) {

    yield put(actions.getAddressesStart());

    try {
        const response = yield call(fetch, '/api/addresses', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            }
        });

        const addresses = yield response.json();

        yield put(actions.getAddressesSuccess(addresses));

    } catch (e) {
        yield put(actions.getAddressesFail(e));
        return;
    }

}

export function* AddressSubmit({ values, token, resolve, reject }) {
    try {
        if (values["_id"]) {

            const id = values._id

            delete values._id

            const response = yield call(fetch, `/api/addresses/${id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "de-DE",
                    "x-auth-token": token
                },
                body: JSON.stringify(values)
            });

            const addresses = yield response.json();

            yield put(actions.getAddressesSuccess(addresses));

            yield call(resolve)

        } else {
            const response = yield call(fetch, "/api/addresses", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "de-DE",
                    "x-auth-token": token
                },
                body: JSON.stringify(values)
            });

            const addresses = yield response.json();

            yield put(actions.getAddressesSuccess(addresses));

            yield call(resolve)
        }

    } catch (e) {
        yield call(reject, e)
    }
}

export function* AddressDelete({ id, token }) {
    try { 
            const response = yield call(fetch, `/api/addresses/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "de-DE",
                    "x-auth-token": token
                }
            });

            const addresses = yield response.json();

            yield put(actions.getAddressesSuccess(addresses));

    } catch (e) {
        yield put(actions.getAddressesFail(e))
    }
}