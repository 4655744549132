import React from 'react'
import { Row, Col } from 'reactstrap';
import Login from './Login'
import Register from './Register'

const AuthWrapper = () => (
    <Row style={{ margin: "0px" }}>
        <Col lg="3" style={{
            paddingTop: "16px",
            paddingBottom: "16px",
            backgroundColor: "#ececea"
        }}>
            <Row className="p-0 m-0">
                <Col>
                    <Login />
                </Col>
            </Row>
            <Row className="pl-0  pr-0 pb-0 m-0" style={{ paddingTop: "40px" }}>
                <Col>
                    <Row>
                        <Col className="text-center" style={{ opacity: "0.6" }}>
                            Noch nicht registriert ?
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "10px", paddingBotom: "10px" }}>
                        <Col className="text-center">
                            <img style={{ height: "110px" }} src={require('../../img/register_bonus.png')} alt="" />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "10px" }}>
                        <Col className="text-center" style={{ lineHeight: "0.7", opacity: "0.6" }}>
                            <p>Registriere dich und</p>
                            <p>erhalte deine CHF 10.00</p>
                            <p>Willkommensbonus !</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
        <Col lg="9" style={{
            paddingTop: "16px",
            paddingBottom: "16px"
        }}>
            <Register />
        </Col>
    </Row >
)

export default AuthWrapper