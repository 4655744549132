import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from 'react-redux';
import * as actions from '../../store/actions'
import Moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from 'react-overlays'
import { Link } from 'react-router-dom'
import { getStatusName } from '../../Utils'


const CalendarContainer = ({ children }) => {
    const el = document.getElementsByClassName('table-container');
    return (
        <Portal container={el[0]}>
            {children}
        </Portal>
    )
};

class OrdersGrid extends Component {

    fetchData = (state) => {
        const { fetchOrders, token } = this.props
        fetchOrders(token, {
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
            filtered: state.filtered
        });
    }

    componentDidMount() {
        const { fetchOrders, token } = this.props
        const dataState = { pageSize: 20, page: 0, sorted: [{ id: "dateCreated", desc: true }], filtered: [] }
        fetchOrders(token, dataState);
    }

    render() {

        const { data, pages, loading } = this.props;
        return (
            <div style={{ minHeight: "calc(100vh - 65px)" }}>
                <div className="table-container">
                    <ReactTable
                        columns={[
                            {
                                Header: 'Order ID',
                                accessor: '_id',
                                sortable: false,
                                Cell: ({ row }) => (<Link to={{ pathname: `/admin/orders/${row._id}` }}>{row._id}</Link>)
                            },
                            {
                                Header: "Email",
                                id: 'email',
                                accessor: d => `${d.user.email}`
                            },
                            {
                                Header: "First Name",
                                id: 'firstName',
                                accessor: d => `${d.user.firstName}`
                            },
                            {
                                Header: "Last Name",
                                id: 'lastName',
                                accessor: d => `${d.user.lastName}`
                            },
                            {
                                Header: "Items",
                                id: "items",
                                sortable: false,
                                accessor: d => `${d.items.length}`
                            },
                            {
                                Header: "Total Price",
                                accessor: "totalPrice"
                            },
                            {
                                Header: "Date",
                                id: 'dateCreated',
                                accessor: d => {
                                    return new Moment(d.dateCreated)
                                        .local()
                                        .format("DD-MM-YYYY HH:mm")
                                },
                                Filter: ({ filter, onChange }) =>
                                    <DatePicker
                                        selected={filter ? filter.value : undefined}
                                        onChange={event => {
                                            onChange(event)
                                        }}
                                        popperContainer={CalendarContainer}
                                        isClearable={true}
                                    />
                            },
                            {
                                Header: "Status",
                                id: "status",
                                accessor: d => getStatusName(d.status),
                                Filter: ({ filter, onChange }) =>
                                    <select
                                        onChange={event => onChange(event.target.value)}
                                        style={{ width: "100%" }}
                                        value={filter ? filter.value : "Created"}
                                    >
                                        <option value="">Alles</option>
                                        <option value="0">{getStatusName(0)}</option>
                                        <option value="1">{getStatusName(1)}</option>
                                        <option value="2">{getStatusName(2)}</option>
                                        <option value="3">{getStatusName(3)}</option>
                                    </select>
                            }
                        ]}
                        defaultSorted={[
                            {
                                id: "dateCreated",
                                desc: true
                            }]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={20}
                        className="-striped -highlight"
                    />
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchOrders: (token, dataState) => dispatch(actions.getAdminOrders(token, dataState))
    };
};

const mapStateToProps = state => ({
    dataState: state.orders.dataState,
    pages: state.orders.pages,
    loading: state.orders.loading,
    data: state.orders.list,
    token: state.auth.token
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersGrid);