import React from 'react'

export default ({ longProcessing }) => {

    let text = "48 Stunden"
    if (longProcessing) {
        text = "14 Tage"
    }

    return (
        <div className="mt-2 mb-2">
            <span>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="21.505px" height="22.164px" viewBox="0 0 21.505 22.164" enableBackground="new 0 0 21.505 22.164" style={{ verticalAlign: "sub" }}>
                    <path fill="#666666" d="M2,18V7h14v3.66l2,3.455V4c0-1.1-0.9-2-2-2h-1V0h-2v2H5V0H3v2H2C0.89,2,0.01,2.9,0.01,4L0,18c0,1.1,0.89,2,2,2h4.063l1.158-2H2z" />
                    <rect x="4" y="9" fill="#666666" width="2.79" height="2.79" />
                    <rect x="7.79" y="9" fill="#666666" width="2.79" height="2.79" />
                    <path fill="rgb(42, 74, 145)" d="M13.735,8.743l-7.77,13.42h15.539L13.735,8.743z M14.512,20.501h-1.553v-1.553h1.553V20.501z M12.959,17.395v-3.106h1.553v3.106H12.959z" />
                    <rect x="4" y="13" fill="#666666" width="2.79" height="2.79" />
                </svg>
            </span>
            <span className="ml-1" style={{ color: "rgb(42, 74, 145)" }}>{text}</span>
        </div>
    )
}