/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Col } from 'reactstrap'
import { Form as FinalForm, Field } from 'react-final-form'
import { TextField, Select } from 'final-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { FORM_ERROR } from "final-form";
import MetaTags from 'react-meta-tags';
import Background from '../img/contact/photo_laundry.png'

const validate = async values => {
    const errors = {};

    if (!values.lastName) {
        errors.lastName = "Der Nachname ist erforderlich"
    }

    // if(!values.phone){
    //     errors.phone = "Der Telefon ist erforderlich"
    // }
    if (!values.email) {
        errors.email = "Die E-Mail ist erforderlich"
        // eslint-disable-next-line no-useless-escape
    } else {
        // eslint-disable-next-line no-useless-escape, no-control-regex
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        if (!expression.test(String(values.email).toLowerCase())) {
            errors.email = "Nicht gültige E-Mail-Adresse"
        }
    }
    if (!values.name) {
        errors.name = "Der Name ist erforderlich"
    }
    if (!values.message) {
        errors.message = "Der Kommentar ist erforderlich"
    }

    return errors;
}

const delay = t => new Promise(resolve => setTimeout(resolve, t));

class Contact extends Component {

    state = {
        buttonText: "Angebot anfordern"
    }

    onSubmit = async (values) => {
        try {
            await fetch('/api/messages', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values)
            });
            await delay(1000);
        } catch (error) {
            return { [FORM_ERROR]: "Something went wrong, please try later" };
        }
    }

    render() {
        const { buttonText } = this.state
        return (
            <React.Fragment>
                <div className="" style={{ marginBottom: "71px" }}>
                    <MetaTags>
                        <title>Noch Fragen? Kontaktiere uns | WÖS.CH</title>
                        <meta name="description" content="Du hast noch Fragen oder bist einfach nur neugierig? Kontaktiere uns jetzt per Mail oder Telefon." />
                        <meta name="image" content="https://www.wös.ch/images/logo.png" />
                        <meta itemProp="name" content="Noch Fragen? Kontaktiere uns | WÖS.CH" />
                        <meta itemProp="description" content="Du hast noch Fragen oder bist einfach nur neugierig? Kontaktiere uns jetzt per Mail oder Telefon." />
                        <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
                        <meta name="og:title" content="Noch Fragen? Kontaktiere uns | WÖS.CH" />
                        <meta name="og:description" content="Du hast noch Fragen oder bist einfach nur neugierig? Kontaktiere uns jetzt per Mail oder Telefon." />
                        <meta name="og:image" content="https://www.wös.ch/images/logo.png" />
                        <meta name="og:url" content="https://www.wös.ch/contact" />
                    </MetaTags>

                    <div className="services-page jumbotron" style={{ textAlign: 'center', backgroundColor: "white" }}>
                        <h1 className="carousel-text-header h1" style={{ paddingBottom: "20px", paddingTop: "15px" }}>Hello!</h1>
                        <p className="carousel-text-content py-2 t1">
                            Wenn Du Fragen hast oder einfach nur neugierig bist und mehr erfahren möchtest,<br />
                            kannst Du uns gerne unter info@woes.ch schreiben oder über die Nummer 044 304 22 98 anrufen.<br />
                            Oder besuche einfach unsere sozialen Kanäle.
                        </p>
                    </div>
                    <img alt="" src={require('../img/stitching_kontakt.png')} className="img-contact" />
                    <div className="row p-0 m-0">
                        <Col md="6" style={{ minHeight: "402px", background: `url(${Background}) no-repeat`, backgroundSize: 'cover', backgroundPosition: "50% 50%" }}>
                        </Col>
                        <Col md="6" style={{ paddingLeft: "25px", paddingRight: "15px" }}>
                            <h2 style={{
                                fontFamily: "'holtzman-textured'",
                                lineHeight: "17px",
                                margin: "0px",
                                textAlign: "start"
                            }} className="h2">Angebot anfordern</h2>
                            <FinalForm
                                onSubmit={this.onSubmit}
                                validate={validate}
                                initialValues={{ service: "Wäscherei" }}
                                subscription={{
                                    submitting: true,
                                    pristine: true,
                                    dirtySinceLastSubmit: true,
                                    submitSucceeded: true,
                                    submitError: true,
                                    submitErrors: true,
                                    hasValidationErrors: true
                                }}
                                render={({
                                    handleSubmit, submitting, pristine, validating, hasValidationErrors, form
                                }) => (
                                    <form
                                        onSubmit={event => {
                                            handleSubmit(event).then(
                                                async () => {
                                                    form.restart();
                                                    this.setState({ buttonText: "erfolgreich gesendet" });
                                                    await delay(5000);
                                                    this.setState({ buttonText: "Angebot anfordern" });
                                                })
                                        }}
                                        className="custom-form form"
                                        style={{ maxWidth: "477px" }}>
                                        <div className="controls">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Field
                                                            fullWidth
                                                            name="name"
                                                            component={TextField}
                                                            type="text"
                                                            placeholder="Vorname"
                                                            label="Vorname"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Field
                                                        fullWidth
                                                        name="lastName"
                                                        component={TextField}
                                                        type="text"
                                                        placeholder="Nachname"
                                                        label="Nachname"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Field
                                                            fullWidth
                                                            name="email"
                                                            component={TextField}
                                                            type="text"
                                                            placeholder="E-Mail-Adresse"
                                                            label="E-Mail-Adresse"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Field
                                                            fullWidth
                                                            name="phone"
                                                            component={TextField}
                                                            type="text"
                                                            placeholder="Telefon"
                                                            label="Telefon"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Field
                                                            name="service"
                                                            label="Service auswählen"
                                                            formControlProps={{ fullWidth: true }}
                                                            component={Select}
                                                            placeholder="Service auswählen"
                                                        >
                                                            <MenuItem value="Wäscherei">Wäscherei</MenuItem>
                                                            <MenuItem value="Schneiderei">Schneiderei</MenuItem>
                                                            <MenuItem value="Anderes">Anderes</MenuItem>
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Field
                                                        fullWidth
                                                        name="message"
                                                        component={TextField}
                                                        multiline
                                                        rows="5"
                                                        type="text"
                                                        placeholder="Kommentar"
                                                        label="Kommentar"
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                disabled={submitting || validating || pristine || hasValidationErrors}
                                                type="submit"
                                                className='btn message-btn' style={{ width: "100%", height: "39px" }}>{submitting ? "Senden..." : buttonText}</button>
                                        </div>
                                    </form>
                                )}
                            />
                        </Col>
                    </div>
                </div>
                <div style={{ marginBottom: "-7px" }}>
                    {/*<MapContainer/>*/}
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe title="Map" width="100%" height="600" id="gmap_canvas"
                                src="https://maps.google.com/maps?q=Hohlstrasse%20192%208004%20Z%C3%BCrich&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default Contact;