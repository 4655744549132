import React, { Component } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form, FormGroup, Row, Button } from 'reactstrap'
import { TextField, Checkbox, Select } from 'final-form-material-ui';
import { connect } from 'react-redux';
import _ from 'lodash'
import { FormControlLabel, MenuItem } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTruck,
    faCreditCard,
    faShoppingBag
} from '@fortawesome/free-solid-svg-icons';

import { FORM_ERROR } from "final-form";
import * as actions from '../../../../store/actions'
import { place } from '../../../../Utils'


const validate = values => {
    const errors = {};

    if (!values.locationName) {
        errors.locationName = "Abholort ist erforderlich"
    }
    if (!values.street) {
        errors.street = "Strasse und Hausnummer ist erforderlich"
    }
    if (!values.city) {
        errors.city = "Ort ist erforderlich"
    }
    if (!values.phone) {
        errors.phone = "Telefon ist erforderlich"
    }

    return errors;
}

class Address extends Component {

    state = { data: {} }
    componentDidMount() {
        this.setState({ data: this.props.data })
    }

    onSubmit = async (values) => {
        const { OnAddressSubmit, modalToggle, token } = this.props
        try {
            await new Promise((resolve, reject) => {
                OnAddressSubmit(_.pick(values,
                    [
                        '_id',
                        'street',
                        'city',
                        'note',
                        'phone',
                        'locationName',
                        'isDefaulDelivery',
                        'isDefaultPickUp',
                        'isDefaultBilling'
                    ]),
                    token,
                    resolve,
                    reject);
            });
            modalToggle()
        } catch (error) {
            return { [FORM_ERROR]: error };
        }
    }


    render() {

        const { data } = this.state
        return (
            <React.Fragment>
                <FinalForm
                    onSubmit={this.onSubmit}
                    validate={validate}
                    initialValues={data}
                    subscription={{ submitting: true, pristine: true, dirtySinceLastSubmit: true, submitSucceeded: true, submitError: true, submitErrors: true }}
                    render={({
                        handleSubmit,
                        submitting,
                        pristine,
                        validating,
                        values,
                        hasValidationErrors,
                        submitError,
                        submitSucceeded,
                        dirtySinceLastSubmit
                    }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Field
                                        fullWidth
                                        required
                                        name="locationName"
                                        component={TextField}
                                        type="text"
                                        placeholder="Ort der Abholung (z.B zu Hause, im Büro ...)"
                                        label="Ort der Abholung (z.B zu Hause, im Büro ...)"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        fullWidth
                                        required
                                        name="street"
                                        component={TextField}
                                        type="text"
                                        placeholder="Strasse und Hausnummer"
                                        label="Strasse und Hausnummer"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name="city"
                                        required
                                        label="PLZ/Ort"
                                        formControlProps={{ fullWidth: true }}
                                        component={Select}
                                        placeholder="PLZ/Ort"
                                    >
                                        {
                                            place.map((item, index) => (<MenuItem key={index} value={`${item.postcode} ${item.place}`}>{`${item.postcode} ${item.place}`}</MenuItem>))
                                        }
                                    </Field>
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        parse={value => (value === "" ? null : value)}
                                        allowNull
                                        fullWidth
                                        name="note"
                                        component={TextField}
                                        type="text"
                                        placeholder="Anmerkungen"
                                        label="Anmerkungen"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        fullWidth
                                        name="phone"
                                        component={TextField}
                                        type="text"
                                        placeholder="Telefon"
                                        label="Telefon"
                                    />
                                </FormGroup>
                                <FormGroup style={{ marginBottom: "0px" }}>
                                    <FormControlLabel
                                        label={
                                            <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                                                <span style={{ minWidth: "16px" }}><FontAwesomeIcon size="sm" icon={faCreditCard} /></span>
                                                <span style={{ marginLeft: "8px" }}>Dies ist die Standard-Rechnungsadresse</span>
                                            </Row>
                                        }
                                        control={
                                            <Field
                                                name="isDefaultBilling"
                                                component={Checkbox}
                                                type="checkbox"
                                                disabled={(data.isDefaultBilling || false) === true}
                                            />
                                        }
                                    />
                                </FormGroup>
                                <FormGroup style={{ marginBottom: "0px" }}>
                                    <FormControlLabel
                                        label={
                                            <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                                                <span style={{ minWidth: "16px" }}><FontAwesomeIcon size="sm" icon={faTruck} /></span>
                                                <span style={{ marginLeft: "8px" }}>Dies ist die Standard-Abholadresse</span>
                                            </Row>
                                        }
                                        control={
                                            <Field
                                                name="isDefaultPickUp"
                                                component={Checkbox}
                                                type="checkbox"
                                                disabled={(data.isDefaultPickUp || false) === true}
                                            />
                                        }
                                    />
                                </FormGroup>
                                <FormGroup style={{ marginBottom: "0px" }}>
                                    <FormControlLabel
                                        label={
                                            <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                                                <span style={{ minWidth: "16px" }}><FontAwesomeIcon size="sm" icon={faShoppingBag} /></span>
                                                <span style={{ marginLeft: "8px" }}>Dies ist die voreingestellte Lieferadresse</span>
                                            </Row>
                                        }
                                        control={
                                            <Field
                                                name="isDefaulDelivery"
                                                component={Checkbox}
                                                type="checkbox"
                                                disabled={(data.isDefaulDelivery || false) === true}
                                            />
                                        }
                                    />
                                </FormGroup>
                                {submitError && <div className="error">{submitError}</div>}
                                <div className="row d-flex flex-row pt-2 pb-2 ml-0">
                                    <Button
                                        className=""
                                        color="primary"
                                        type="submit"
                                        disabled={submitting || validating || pristine || hasValidationErrors || (submitSucceeded ? !dirtySinceLastSubmit : false)}>
                                        {submitting ? "SPEICHERN..." : "SPEICHERN"}
                                    </Button>
                                </div>
                            </Form>
                        )}
                />
            </React.Fragment>)
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        OnAddressSubmit: (values, token, resolve, reject) => dispatch(actions.addressSubmit(values, token, resolve, reject)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address)