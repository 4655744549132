import React from 'react'
import MetaTags from 'react-meta-tags';

const Faq = () => (
    <div className="container mx-auto">
        <MetaTags>
            <title>FAQ – Häufig gestellte Fragen | WÖS.CH</title>
            <meta name="description" content="Häufig gestellte Fragen im Zusammenhang mit WÖS.CH – vielleicht findest Du hier bereits wonach Du suchst." />
            <meta name="image" content="https://www.wös.ch/images/logo.png" />
            <meta itemProp="name" content="FAQ – Häufig gestellte Fragen | WÖS.CH" />
            <meta itemProp="description" content="Häufig gestellte Fragen im Zusammenhang mit WÖS.CH – vielleicht findest Du hier bereits wonach Du suchst." />
            <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
            <meta name="og:title" content="FAQ – Häufig gestellte Fragen | WÖS.CH" />
            <meta name="og:description" content="Häufig gestellte Fragen im Zusammenhang mit WÖS.CH – vielleicht findest Du hier bereits wonach Du suchst." />
            <meta name="og:image" content="https://www.wös.ch/images/logo.png" />
            <meta name="og:url" content="https://www.wös.ch/faq" />
            <meta name="og:site_name" content="wös.ch" />
            <meta name="og:locale" content="de_CH" />
            <meta name="og:type" content="website" />
        </MetaTags>
        <div data-packed="false" data-min-height="91"
            style={{
                minHeight: "91px",
                marginTop: "85px",
                marginBottom: "0px",
                verticalAlign: "bottom"
            }}>
            <h3 className="font_3" style={{ textAlign: "center", marginBottom: "0px", marginTop: "0px", verticalAlign: "bottom", color: "rgb(0, 0, 0)" }}>
                faq – häufig gestellte fragen
            </h3>
        </div>
        <div data-packed="false" data-min-height="392" className="mx-auto"
            style={{
                maxWidth: "747px",
                minHeight: "392px",
                marginTop: "0px",
                marginBottom: "80px"
            }} >
            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                I allgemeines
            </p>
            <p className="font_8" style={{ textAlign: "center" }}>&nbsp;</p>

            <p className="font_8" style={{ fontWeight: "bold" }}>Was bietet wös.ch an?</p>
            <p className="font_8">wös.ch ist eine einfache, praktische und kostengünstige Lösung für den Wäscherei- und Schneiderei-Service: Bestellungen rund um die Uhr, Abholungen und Lieferungen an sechs Tagen die Woche und 12 Stunden am Tag, höchste Qualität und 100% umweltfreundlich.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Bearbeitungszeit der bestellung</p>
            <p className="font_8">Wir garantieren eine Bearbeitungszeit von 24 Stunden bei gewöhnlichen Wäscherei- und Bügelarbeiten, 48 Stunden bei speziellen Arbeiten (Waschen von empfindlichen Textilien und Schneiderei-Service usw.) und 14 Tagen bei aufwendigeren Dienstleistungen (Reinigung von Leder, Teppichen, Vorhängen usw.).
Falls Ihre Bestellung Textilien oder Gegenstände mit unterschiedlicher Bearbeitungszeit umfasst, können Sie zwischen einer Sammellieferung oder zwei separaten Lieferungen wählen. Sie haben die freie Wahl. Bei Bestellungen ab CHF 30.00 sind Abholung und Lieferung KOSTENLOS (gilt für jede einzelne Lieferung, falls Sie sich für separate Lieferungen entscheiden).</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Wie kann ich mich registrieren?</p>
            <p className="font_8">Erstellen Sie einfach ein Konto über die Website www.wös.ch. Sie werden aufgefordert, Ihre E-Mail-Adresse, die Abhol- und Lieferadresse mit Angaben wie Etage, Treppe/Lift usw. sowie Ihre Handynummer einzugeben. Wir benötigen diese Informationen, um Ihnen einen bestmöglichen Service zu bieten.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Wird wös.ch auch an meinem wohnort angeboten?</p>
            <p className="font_8">Um zu erfahren, ob Ihr Wohnort bedient wird, können Sie auf unserer Website einfach die Postleitzahl eingeben. Falls wir den Ort noch nicht abdecken, geben Sie unbedingt Ihre E-Mail-Adresse an, damit wir Sie benachrichtigen können, sobald der Service in Ihrer Region verfügbar ist.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Wie kann ich eine bestellung aufgeben?</p>
            <p className="font_8">Es ist ganz einfach: Nach der Registrierung geben Sie auf www.wös.ch Ihren Benutzernamen und Ihr Passwort ein und gelangen mit einem Klick auf «LOGIN» auf die Bestellseite. Dort können Sie alle gewünschten Dienstleistungen (Waschen, Bügeln und Schneiderei-Service) auswählen. Vergessen Sie nicht, für jedes einzelne Kleidungsstück Ihre Sonderwünsche anzugeben (Falten, Fleckenbehandlung usw.), und personalisieren Sie Ihre Bestellung falls gewünscht mit ausgewählten Duftstoffen. Wählen Sie den Tag und die Uhrzeit für Abholung und Lieferung und stellen Sie sicher, dass Sie im gewählten Zeitfenster zuhause sind oder teilen Sie uns mit, wo wir die Lieferung sicher deponieren können. Hinterlassen Sie nach ausgeführtem Auftrag einen Kommentar oder ein Feedback. Sie helfen uns damit, unseren Service stets zu verbessern.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Wie kann ich tag und uhrzeit der abholung und lieferung ändern?</p>
            <p className="font_8">Haben sich Ihre Pläne geändert? Kein Problem! Nach der Auftragserteilung können Sie uns bis 12 Stunden vor der geplanten Abholung oder Lieferung eine E-Mail an info@woes.ch senden mit Ihrem neuen Wunschtermin.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Kann ich den service von wös.ch jede woche am selben tag und zur gleichen uhrzeit nutzen?</p>
            <p className="font_8">Ja, wir bieten auch Abonnemente an. Mit unseren Prepaid-Tarifen in Höhe von CHF 100.00, CHF 150.00, CHF 200.00 oder CHF 250.00 können Sie den Auftrag wöchentlich, alle zwei Wochen, alle drei Wochen oder alle vier Wochen ausführen lassen – zu bestmöglichen Konditionen. Wählen Sie einfach die gewünschte Frequenz und den Wunschtag für Abholung und Lieferung. Einfacher geht nicht!</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Was geschieht, wenn sich der wös.ch-kurier verspätet?</p>
            <p className="font_8">Wir tun unser Bestes, um das vereinbarte Zeitfenster einzuhalten. Aufgrund externer Faktoren können wir uns aber trotzdem einmal verspäten. Es ist uns bewusst, dass Sie noch andere Verpflichtungen haben. Falls wir uns verspäten und Sie nicht auf uns warten können, schreiben Sie uns bitte eine E-Mail an info@woes.ch. Wir werden Ihre Bestellung gerne kostenlos neu terminieren.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Was kann ich tun, wenn ich schwierigkeiten mit der webseite habe?</p>
            <p className="font_8">Unsere Website www.wös.ch wurde auch für mobile Endgeräte optimiert. Unsere Programmierer bemühen sich, sie laufend zu verbessern. Falls Sie trotzdem technische Schwierigkeiten haben, melden Sie dies bitte an info@woes.ch. </p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Wie kann ich wös.ch kontaktieren?</p>
            <p className="font_8">Haben Sie Fragen, Anregungen, Feedback zur Qualität oder zum Service, möchten Sie ein Lob aussprechen oder einfach mit uns reden? Sie können uns jederzeit an info@woes.ch schreiben oder anrufen. Wir stehen gerne für alle Fragen rund um wös.ch zur Verfügung.</p>
            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                Vor der ersten abholung
            </p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Muss ich meine kleider sortieren?</p>
            <p className="font_8">Ja. Aber keine Sorgen: es ist ganz einfach! Der wös.ch Kurier bringt passende Beutel mit, um Ihre Kleidungsstücke oder Gegenstände für die Textilreinigung, das Waschen und Bügeln oder den Schneiderei-Service abzuholen. Es reicht, wenn Sie die Sachen entsprechend trennen, um uns das Sortieren zu erleichtern.
Wir nehmen keine persönlichen Gegenstände entgegen und übernehmen keine Haftung für Uhren, Schmuck oder andere Gegenstände, die während der Reinigung verlorengehen.
</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Kann ich sonderwünsche angeben?</p>
            <p className="font_8">Natürlich! Sie können für jedes einzelne Kleidungsstück oder für mehrere Kleidungsstücke desselben Typs Ihre Präferenzen angeben. Nachdem Sie das Kleidungsstück oder den Gegenstand ausgewählt haben, das gereinigt oder repariert werden soll, können Sie in den entsprechenden Bereichen Zusatzdienste wie Bügeln, Falten oder Fleckenbehandlung hinzufügen.
Diese Sonderwünsche sind KOSTENLOS und im Bestellpreis inbegriffen.
Sie können für Kleidungsstücke oder Gegenstände, die Sie bei uns reinigen lassen, auch Schneiderarbeiten in Auftrag geben. Dabei gilt unsere Preisliste für den Schneiderei-Service.
Alle anderen Wünsche können Sie im Feld «Anmerkung» eintragen. Wir wissen, wie viel Ihnen an Ihren Kleidungstücken, Accessoires und persönlichen Gegenstände liegt. Deshalb tun wir unser Bestes, um Ihre Sonderwünsche zu erfüllen.
</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Kann die abholung oder lierferung auch stattfinden, wenn ich abwesend bin?</p>
            <p className="font_8">Ja, das ist möglich. Bitte teilen Sie uns hierzu bei der Bestellung die entsprechenden Anweisungen mit. Im letzten Schritt können Sie angeben, ob der wös.ch-Mitarbeiter Ihre Kleidung im Eingang, an der Rezeption usw. hinterlassen darf, wenn Sie am gewählten Tag und zur gewählten Zeit nicht zuhause oder an der angegebenen Adresse sind.</p>
            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                Preise & abrechnung
            </p>

            <p className="font_8" style={{ fontWeight: "bold" }}>Wie kann ich bezahlen? Welche zahlungsmöglichkeiten gibt es?</p>
            <p className="font_8">Die Bezahlung erfolgt sicher via Kreditkarte, PayPal und PostFinance. wös.ch setzt auf die wichtigsten Anbieter im Bereich der Zahlungssysteme. Nach dem Zahlungseingang erhalten Sie umgehend eine Bestellbestätigung mit allen Details.
Haben Sie Fragen zu unseren Preisen? Sie können sich jederzeit mit unserem Kundendienst in Verbindung setzen via info@woes.ch.
</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Gibt es einen mindestbestellwert?</p>
            <p className="font_8">Bei uns gibt es keinen Mindestbestellwert.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Gibt es eine abhol- oder liefergebühr?</p>
            <p className="font_8">Abholung und Lieferung sind bei Bestellungen ab CHF 30.00 KOSTENLOS. Bei Bestellungen unter CHF CHF 30.00 verrechnen wir CHF 7.50 für die Abholung und CHF 7.50 für die Lieferung.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Wie kann ich einen rabattbon einlösen?</p>
            <p className="font_8">Rabattbons wie der Willkommensbonus können Sie bei der Registrierung einlösen, und solche aus Sonderaktionen oder Einladungen Ihrer Freunde zu einem beliebigen Zeitpunkt. Sie finden alle Rabattbons in Ihrem Konto im Bereich MEINE BONI.
Vergessen Sie nicht, Ihre Lieben, Freunde und Kollegen einzuladen, wös.ch auszuprobieren. Es lohnt sich! Wenn Sie uns weiterempfehlen, erhalten Ihre Freunde als auch Sie einen Bonus von CHF 5.00, um unseren Service zu testen und bei ihrer ersten Bestellung erhalten Sie als Dank einen Willkommensbonus im Wert von CHF 10.00! Es ist ganz einfach. So haben Sie jederzeit Rabattboni für Ihren Lieblingsservice.
</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Wie kann ich einen geschenkgutschein verwenden?</p>
            <p className="font_8">Jemand hat Ihnen mit einem Gutschein von wös.ch freie Zeit geschenkt?  Grossartig! Um das Guthaben zu nutzen, geben Sie bei der Zahlung im entsprechenden Abschnitt einfach den Gutscheincode ein. Das Guthaben wird sogleich abgezogen und hat kein Ablaufdatum.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Wie viel kostet der service von wös.ch?</p>
            <p className="font_8">wös.ch bietet volle Transparenz und ein hervorragendes Preis-Leistungs-Verhältnis mit sehr günstigen Konditionen. Auf wös.ch finden Sie alle unsere Preise, damit Sie genau wissen, wie viel Sie für den gewünschten Service bezahlen und Ihre Ausgaben stets im Griff haben.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Wo finde ich meine bestellungen?</p>
            <p className="font_8">Möchten Sie eine alte Bestellung einsehen? Gehen Sie mit Ihrem Computer oder Smartphone auf wös.ch und melden Sie sich an. Klicken Sie auf "Aufträge", um alle bisherigen Transaktionen mit Details zu den einzelnen Bestellungen zu sehen.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Was geschieht, wenn ich die abholung vergesse?</p>
            <p className="font_8">Es ist uns bewusst, dass das Leben manchmal hektisch ist und sich Pläne plötzlich ändern können. Wenn Sie bereits im Voraus wissen, dass Sie zum vereinbarten Termin nicht an der angegebenen Adresse sein können, informieren Sie uns bitte mindestens 12 Stunden vor der gewählten Abhol- oder Lieferzeit via info@woes.ch. Wenn Sie uns nicht innerhalb dieser Frist informieren, berechnen wir zur Deckung unserer Kurierkosten eine Gebühr von CHF 15.00.</p>
            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                Der service von wös.ch
            </p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Welche waschmittel werden verwendet?</p>
            <p className="font_8">Wir verwenden umweltfreundliche und biologisch abbaubare Waschmittel, denn es ist uns wichtig, den Planeten, Ihre Gesundheit und Ihre Haut zu schützen!</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Ist der service von wös.ch ökologisch?</p>
            <p className="font_8">Wir setzen auf erneuerbare Energie, biologisch abbaubare und schonende Waschmittel und recyclingfähige Verpackungen. All dies, um die Umwelt zu schonen. Das macht uns aus und gehört zu unseren Werten: Wir denken und handeln nachhaltig und ökologisch, weil wir ein positives Zeichen setzen wollen!</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Gibt es materialien, die sie nicht behandeln können?</p>
            <p className="font_8">In unserer spezialisierten Wäscherei reinigen und waschen wir alle Arten von Textilien, einschliesslich Leder, Nubuk, Wildleder, Kaschmir sowie Öko-Leder.
Wir sind auch ausgerüstet, um Teppiche, Vorhänge und andere Heimtextilien zu behandeln sowie Taschen zu reparieren und reinigen.
Wir übernehmen normale Fleckenbehandlungen. Vergessen Sie nicht, uns bei der Bestellung mitzuteilen, falls Sie diese Behandlung wünschen. Wenn der Fleck bereits tief in das Gewebe eingedrungen ist, kann es schwierig oder gar unmöglich sein, ihn zu entfernen, da die wiederholte Behandlung zu Farbverlust oder sichtbaren Schäden wie raue oder ausgefranste Stellen führen würde.
Es gibt zudem einige besondere synthetische Materialien, die wir nicht behandeln können.
Falls unser Team ein Kleidungsstück oder einen Gegenstand nicht waschen oder reparieren kann, melden wir uns bei Ihnen via E-Mail oder telefonisch. Selbstverständlich schreiben wir Ihnen den Betrag gut, den Sie für den entsprechenden Service bezahlt haben.
</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Was passiert, wenn ein lästiger fleck nicht entfernt werden kann?</p>
            <p className="font_8">Wir tun unser Bestes, um Flecken zu entfernen, aber einige sind leider dauerhaft. Bei Flecken, die bereits tief in das Gewebe eingedrungen sind, führen wiederholte Behandlungen zu Farbverlust oder sichtbaren Schäden wie raue oder ausgefranste Stellen.
Falls unser Team ein Kleidungsstück oder einen Gegenstand nicht waschen oder reinigen kann, melden wir uns bei Ihnen via E-Mail oder telefonisch.
</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Haftung für knöpfe und accessoires</p>
            <p className="font_8">Wie in der Branche üblich haften wir nicht für Knöpfe und Accessoires, die uns bei der Reinigung oder Behandlung von Textilien anvertraut werden. Bitte informieren Sie uns, falls ein Knopf nicht mehr gut hält, oder entfernen Sie diesen vor der Reinigung, um Unannehmlichkeiten oder mögliche Schäden zu vermeiden. </p>
            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                Gewöhnliches waschen und trockenreinigung
            </p>

            <p className="font_8" style={{ fontWeight: "bold" }}>Was heisst gewöhnliches waschen?</p>
            <p className="font_8">Gewöhnliches Waschen ist die ideale Reinigungsart für alle Kleidungsstücke und erfolgt mit umweltfreundlichen Waschmitteln.
Wenn Sie den Standardservice ohne Sonderwünsche wählen, werden Ihre Kleidungsstücke gewaschen, getrocknet und an Kleiderbügel hängend geliefert. Wenn wir Ihre Kleidungsstücke falten sollen, können Sie dies bei den Sonderwünschen angeben.
</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Was ist trockenreinigung, und wie weiss ich, ob ein kleidungsstück trocken gereinigt werden sollte?</p>
            <p className="font_8">Gewisse Kleidungsstücke sind aus empfindlichen Materialien (Seide, Wolle, Kaschmir usw.) und sollten nur trocken gereinigt werden. Bei wös.ch verwenden wir dafür biologisch abbaubare Mittel, welche die Umwelt schonen und einen umweltverträglichen Waschservice garantieren.</p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Muss ich bei der bestellung angeben, ob die kleider gewaschen oder trocken gereinigt werden sollen?</p>
            <p className="font_8">Nein. wös.ch wählt für Ihre Kleidungsstücke jeweils die beste Reinigungsmethode. Wir garantieren eine Reinigung gemäss Anweisung auf dem Etikett und eine optimale Pflege der Stoffe. Wenn auf dem Etikett zum Beispiel das Symbol für die Trockenreinigung steht, behandeln wir das Kleidungsstück entsprechend. Wenn Sie besondere Wünsche haben, tragen Sie diese bitte ins Feld «Anmerkung» ein, nachdem Sie das Kleidungsstück ausgewählt haben. Wir werden diese befolgen und Sie informieren, falls es Schwierigkeiten geben sollte. Für spezifische Fragen können Sie sich gerne an unseren Kundendienst wenden, der Sie mit einer Fachperson verbindet.</p>
            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                Probleme mit dem konto
            </p>
            <p className="font_8" style={{ fontWeight: "bold" }}>Was muss ich tun, wenn ich probleme mit meinem konto oder mein passwort vergessen habe?</p>
            <p className="font_8">Haben Sie Ihr Passwort vergessen? Können Sie sich nicht anmelden? Versuchen Sie Ihr Passwort zurückzusetzen. Sollte es trotzdem nicht klappen, senden Sie uns eine E-Mail an info@woes.ch. Eine Mitarbeiterin oder ein Mitarbeiter von uns wird sich umgehend mit Ihnen in Verbindung setzen.</p>

            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                Kundendienst: Bitte schreiben Sie uns an info@woes.ch
            </p>

            <p className="font_8">Haben Sie Fragen, Anregungen, Feedback zur Qualität oder zum Service, möchten Sie uns ein Kompliment machen oder einfach mit uns sprechen? Sie erreichen uns unter info@woes.ch.</p>
        </div>
    </div>
)

export default Faq