import React, { Component } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form, FormGroup, Button } from 'reactstrap'
import { connect } from 'react-redux';
import { FORM_ERROR } from "final-form";
import { TextField } from 'final-form-material-ui';

import * as actions from '../../store/actions'

const emailExists = async value => {
    if (!value) {
        return "Die E-Mail ist erforderlich"
    }
    // eslint-disable-next-line no-useless-escape
    // if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))) {
    //     return "Nicht gültige E-Mail-Adresse"
    // }

    // eslint-disable-next-line no-useless-escape, no-control-regex
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    if (!expression.test(String(value).toLowerCase())) {
        return "Nicht gültige E-Mail-Adresse"
    }

    // try {
    //     var result = await fetch(`/api/users/check-email/${value}`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Accepts": "application/json",
    //         }
    //     });

    //     result = await result.text()
    //     if (result === "false") {
    //         return 'E-Mail existiert nicht!'
    //     }
    // } catch (error) {
    //     //return "Etwas ist schief gelaufen, bitte versuchen Sie es später oder kontaktieren Sie uns";
    // }
}


class ResetPassword extends Component {

    onSubmit = async (values) => {
        const { OnSendPasswordReset } = this.props
        try {
            await new Promise((resolve, reject) => {
                OnSendPasswordReset(values.email, resolve, reject);
            });
        } catch (error) {
            switch (error.error) {
                case 1:
                    return { [FORM_ERROR]: "E-Mail nicht gefunden." };
                case 2:
                    return { [FORM_ERROR]: "Passwort und E-Mail stimmen nicht überein" };
                default:
                    return { [FORM_ERROR]: "Etwas ist schief gelaufen, bitte versuchen Sie es später oder kontaktieren Sie uns" };
            }
        }
    }

    render() {

        const { token, OnResetPasswordModalToggle } = this.props

        return (
            <React.Fragment>
                <div style={{ height: "10px" }}></div>
                <FinalForm
                    onSubmit={this.onSubmit}
                    initialValues={{ token: token }}
                    render={({
                        submitError,
                        handleSubmit,
                        submitting,
                        pristine,
                        validating,
                        hasValidationErrors
                    }) => (
                            <Form onSubmit={handleSubmit}>
                                {validating && <p>E-Mail prüfen...</p>}
                                <FormGroup>
                                    <Field
                                        validate={emailExists}
                                        fullWidth
                                        required
                                        name="email"
                                        component={TextField}
                                        type="text"
                                        placeholder="E-Mail"
                                        label="E-Mail"
                                        autoComplete="username"
                                    />
                                </FormGroup>
                                {submitError && <div className="error">{submitError}</div>}
                                <br />
                                <div className="row justify-content-between">
                                    <Button
                                        className="ml-3"
                                        color="secondary"
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            OnResetPasswordModalToggle()
                                        }}>
                                        ABBRECHEN
                                            </Button>
                                    <Button
                                        className="mr-3"
                                        color="primary"
                                        type="submit"
                                        disabled={submitting || validating || pristine || hasValidationErrors}>
                                        {submitting ? "SENDEN..." : "SENDEN"}
                                    </Button>
                                </div>
                            </Form >
                        )
                    }
                />
            </React.Fragment>)
    }
}


const mapDispatchToProps = dispatch => {
    return {
        OnSendPasswordReset: (email, resolve, reject) => dispatch(actions.sendPasswordReset(email, resolve, reject)),
        OnResetPasswordModalToggle: () => dispatch(actions.resetPasswordModalToggle())
    };
};


export default connect(null, mapDispatchToProps)(ResetPassword)