import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'

export default ({
    packed,
    packedSelected,
    packedPrice,
    hanger,
    hangerSelected
}) =>
    (
        <ListGroup flush className="shopping-item-section">
            <ListGroupItem className="item-main-header">
                <strong>Lieferung</strong>
            </ListGroupItem>
            {
                packedSelected &&
                <ListGroupItem className="item-button" active={packedSelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">Gefaltet</div>
                        {
                            packedPrice === 0 &&
                            <div className="ml-auto" style={{ textTransform: "none" }}>Gratis</div>
                        }
                        {
                            packedPrice > 0 &&
                            <div className="small-price">
                                {formatNumber(packedPrice)}
                            </div>
                        }
                    </div>
                </ListGroupItem>
            }
            {
                hangerSelected &&
                <ListGroupItem className="item-button" active={hangerSelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">auf Bügel</div>
                        <div className="ml-auto" style={{ textTransform: "none" }}>Gratis</div>
                    </div>
                </ListGroupItem>
            }
        </ListGroup>
    )