import * as actionTypes from './actionTypes';

export const sendPasswordReset = (email, resolve, reject) => ({
  type: actionTypes.SEND_PASSWORD_RESET,
  email,
  resolve,
  reject
})

export const resetPasswordModalShow = () => ({
  type: actionTypes.RESET_PASSWORD_MODAL_SHOW
})

export const authStart = () => ({
  type: actionTypes.AUTH_START
});

export const resetPasswordStart = () => ({
  type: actionTypes.RESET_PASSWORD_START
});

export const setNewPasswordStart = () => ({
  type: actionTypes.SET_NEW_PASSWORD_START
});

export const authSuccess = (token, userId, userName, isAdmin, isVip) => ({
  type: actionTypes.AUTH_SUCCESS,
  token,
  userId,
  userName,
  isAdmin,
  isVip
});

export const authFail = error => ({
  type: actionTypes.AUTH_FAIL,
  error
});

export const resetPasswordFail = error => ({
  type: actionTypes.RESET_PASWORD_FAIL,
  error
})

export const checkAuthTimeout = expirationTime => ({
  type: actionTypes.AUTH_CHECK_TIMEOUT,
  expirationTime
});

export const auth = (email, password, resolve, reject) => ({
  type: actionTypes.AUTH_USER,
  email,
  password,
  resolve,
  reject
});

export const resetPassword = (token, password, resolve, reject) => ({
  type: actionTypes.SET_NEW_PASSWORD,
  token,
  password,
  resolve,
  reject
});

export const setNewPassword = (token, password, resolve, reject) => ({
  type: actionTypes.SET_NEW_PASSWORD,
  token,
  password,
  resolve,
  reject
});

export const register = (values, resolve, reject) => ({
  type: actionTypes.REGISTER_USER,
  values,
  resolve,
  reject
});

export const setAuthRedirectPath = path => ({
  type: actionTypes.SET_AUTH_REDIRECT_PATH,
  path
});

export const authModalToggle = () => ({
  type: actionTypes.AUTH_MODAL_TOGGLE
})

export const newPasswordModalToggle = (token) => ({
  type: actionTypes.NEW_PASSWORD_MODAL_TOGGLE,
  token
})

export const resetPasswordModalToggle = () => ({
  type: actionTypes.RESET_PASSWORD_MODAL_TOGGLE
})

export const authCheckState = (pathname) => {
  return {
    type: actionTypes.AUTH_CHECK_STATE,
    pathname
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT
  };
};


export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const AgbClick = () => {
  return {
    type: actionTypes.AGB_CLICK
  }
}

export const userUpdated = (values, token) => {
  return {
    type: actionTypes.USER_UPDATED,
    values,
    token
  }
}
