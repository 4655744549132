import React from 'react'
import MetaTags from 'react-meta-tags';

const Agb = () => (
    <div className="container mx-auto">
        <MetaTags>
            <title>Unsere Geschäftsbedingungen | WÖS.CH</title>
            <meta name="description" content="Auf dieser Seite findest Du unsere allgemeinen Geschäftsbedingungen." />
            <meta name="image" content="https://www.wös.ch/images/logo.png" />
            <meta itemProp="name" content="Unsere Geschäftsbedingungen | WÖS.CH" />
            <meta itemProp="description" content="Auf dieser Seite findest Du unsere allgemeinen Geschäftsbedingungen." />
            <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
            <meta name="og:title" content="Unsere Geschäftsbedingungen | WÖS.CH" />
            <meta name="og:description" content="Auf dieser Seite findest Du unsere allgemeinen Geschäftsbedingungen." />
            <meta name="og:image" content="https://www.wös.ch/images/logo.png" />
            <meta name="og:url" content="https://www.wös.ch/agb" />
            <meta name="og:site_name" content="wös.ch" />
            <meta name="og:locale" content="de_CH" />
            <meta name="og:type" content="website" />
        </MetaTags>
        <div data-packed="false" data-min-height="91"
            style={{
                minHeight: "91px",
                marginTop: "85px",
                marginBottom: "0px",
                verticalAlign: "bottom"
            }}>
            <h3 className="font_3" style={{ textAlign: "center", marginBottom: "0px", marginTop: "0px", verticalAlign: "bottom", color: "rgb(0, 0, 0)" }}>
                agb
            </h3>
        </div>
        <div data-packed="false" data-min-height="392" className="mx-auto"
            style={{
                maxWidth: "747px",
                minHeight: "392px",
                marginTop: "0px"
            }} >
            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                Allgemeine Geschäftsbedingungen
            </p>

            <p className="font_8" style={{ textAlign: "center" }}>&nbsp;</p>

            <p className="font_8"><span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>1 Zustandekommen des Vertrags mit wös.ch</span></span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Der Kaufvertrag für eine oder mehrere Dienstleistungen und Produkte auf <a href="https://www.wös.ch">www.wös.ch</a> kommt zustande, indem das elektronische Bestellformular ausgefüllt und gemäss entsprechender Anleitung auf elektronischem Weg an wös.ch übermittelt wird.<br />
                Das Bestellformular enthält einen Verweis auf die Allgemeinen Verkaufsbedingungen und eine Zusammenfassung der Informationen über die wesentlichen Merkmale der bestellten Dienstleistungen und Produkte sowie deren Preise (einschliesslich aller anwendbaren Steuern oder Abgaben), die Zahlungsmittel, mit denen Sie die Dienstleistungen und Produkte erwerben können, die Lieferbedingungen für die erworbenen Dienstleistungen und Produkte, die Versand- und Lieferkosten sowie einen Verweis auf die Voraussetzungen für die Ausübung des Widerrufsrechts und die geltenden Bedingungen und Fristen für die Rückgabe der gekauften Produkte. Der Vertrag kommt zustande, wenn der Verkäufer Ihr Bestellformular elektronisch erhalten und die Richtigkeit Ihrer Bestelldaten überprüft hat.<br />
                Vor dem Kauf der Dienstleistungen und Produkte werden Sie dazu aufgefordert, die Allgemeinen Verkaufsbedingungen und die Informationen zum Widerrufsrecht sorgfältig zu lesen, ein Exemplar auszudrucken und diese für Ihren persönlichen Gebrauch zu speichern oder zu kopieren. Das Bestellformular wird für die zur Bearbeitung der Bestellung erforderliche Zeitdauer und im Rahmen der gesetzlichen Bestimmungen in unserer Datenbank archiviert.<br />
                Sie können Ihr Bestellformular im Kundenkonto einsehen. Vor der Übermittlung des Bestellformulars werden Sie dazu aufgefordert, in der Bestellübersicht allfällige Eingabefehler zu prüfen und zu korrigieren.<br />
                Nach Abschluss des Vertrags beginnt wös.ch mit der Abwicklung Ihrer Bestellung. Dem Verkäufer steht es frei, Bestellungen zu annullieren, wenn die Kreditwürdigkeit ungenügend ist oder die Zahlungsangaben unvollständig oder fehlerhaft sind.<br />
                Sollten die zum Zeitpunkt Ihres letzten Zugriffs auf die Website oder beim Versand des Bestellformulars auf der Website präsentierten Produkte und Dienstleistungen nicht mehr verfügbar oder zum Verkauf angeboten werden, informiert der Verkäufer Sie unverzüglich, spätestens jedoch innert 72 Stunden ab dem Folgetag des Bestelleingangs, über die Nichtverfügbarkeit der bestellten Produkte und Dienstleistungen. Falls die Bestellung bereits übermittelt und die Zahlung ausgeführt wurde, werden Ihnen die Kosten vom Verkäufer erstattet. Mit der elektronischen Übermittlung des Bestellformulars akzeptieren Sie vorbehaltlos die Allgemeinen Verkaufsbedingungen und verpflichten sich, diese einzuhalten.<br />
                Falls Sie mit bestimmten in den Allgemeinen Verkaufsbedingungen enthaltenen Bedingungen nicht einverstanden sind, bitten wir Sie, uns kein Bestellformular zu übermitteln. Mit der Übermittlung des Bestellformulars bestätigen Sie, dass Sie die Allgemeinen Verkaufsbedingungen und die auf der Website enthaltenen Zusatzinformationen, einschliesslich der über Links abgerufenen Informationen, zur Kenntnis nehmen und diese akzeptieren. Nach Abschluss des Vertrags sendet Ihnen der Verkäufer per E-Mail eine Bestellbestätigung mit einer Zusammenfassung der bereits im Bestellformular enthaltenen Informationen.</span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}><span className="wixGuard">​</span></span></p>

            <p className="font_8"><span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>2 Garantien und Preise für Dienstleistungen und Produkte</span></span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Die wesentlichen Merkmale der Dienstleistungen und Produkte sind auf der Website von wös.ch dargestellt.</span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}><span className="wixGuard">​</span></span></p>

            <p className="font_8"><span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>3 Zahlungen</span></span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Für die Bezahlung der Produkte oder Dienstleistungen sowie der damit verbundenen Versand- und Lieferkosten können Sie eine der im Bestellformular angegebenen Methoden wählen. Bei Zahlung mit Kreditkarte werden die Zahlungsinformationen (z. B. die Nummer der Kredit-/Debitkarte oder das Ablaufdatum) über eine verschlüsselte Verbindung an Banken weitergeleitet, die elektronische Fernzahlungsdienste anbieten, ohne dass Dritte in irgendeiner Weise Zugang dazu haben. Diese Informationen werden vom Verkäufer ausschliesslich dazu verwendet, um Ihren Auftrag zu erfüllen und im Fall einer Rücksendung von Produkten nach Ausübung Ihres Widerrufsrechts Rückerstattungen zu leisten.<br />
                Der Verkaufspreis der Produkte und die Versand- und Lieferkosten gemäss Bestellformular werden Ihrem Konto zum Zeitpunkt der Lieferung der gekauften Produkte belastet.</span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}><span className="wixGuard">​</span></span></p>

            <p className="font_8"><span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>4 Angebotene Dienstleistungen</span></span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>wös.ch verpflichtet sich, die Dienstleistung innerhalb der zum Zeitpunkt der Bestellung vereinbarten Termine zu erbringen. Sollten aussergewöhnliche Umstände die Einhaltung der vereinbarten Fristen verunmöglichen (z. B. Streiks, Wetterbedingungen, Erdbeben usw.), verpflichtet sich wös.ch, den Kunden rechtzeitig zu informieren und den Service innerhalb von 72 Stunden nach dem vom Nutzer gewünschten Datum zu erbringen mittels Abholung der Ware an der im Bestellformular angegebenen Adresse.<br />
                wös.ch verpflichtet sich ausserdem, die abgeholte Ware gemäss den geltenden Vorschriften aufzubewahren und sie innerhalb der in der Bestellung festgelegten Frist zurückzugeben.<br />
                Sollten aussergewöhnliche Umstände die Einhaltung der vereinbarten Fristen verunmöglichen (z.B. bei hartnäckigen Flecken auf den Kleidungsstücken, die mehrere Behandlungen erfordern, aufwendigen und komplexen Reparaturarbeiten&nbsp;an&nbsp;Kleidungsstücken usw.), verpflichtet sich wös.ch, den Kunden rechtzeitig zu informieren und die Lieferung innerhalb von 72 Stunden ab dem vom Nutzer im Bestellformular angegebenen Datum auszuführen.</span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}><span className="wixGuard">​</span></span></p>

            <p className="font_8"><span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>5 Kundenservice</span></span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Sie können das Unternehmen für jegliche Fragen via Telefon oder E-Mail kontaktieren. Die Kontaktangaben von wös.ch finden Sie auf der&nbsp;Kontaktseite.</span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}><span className="wixGuard">​</span></span></p>

            <p className="font_8"><span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>6 Widerrufsrecht</span></span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Sie haben das Recht, innerhalb von zehn (10) Werktagen ab dem Tag der Bestellungsübermittlung ohne Angabe von Gründen vom mit dem Verkäufer abgeschlossenen Vertrag zurückzutreten, es sei denn, der Vertrag wurde durch Abholung der Ware an der im Bestellformular angegebenen Adresse bereits ausgeführt. Der Widerruf muss auf elektronischem Weg erfolgen.<br />
                Wird das Widerrufsrecht ordnungsgemäss ausgeübt, erstattet der Verkäufer allfällige bereits erhaltene Beträge für den Kauf von Produkten und Dienstleistungen gemäss den geltenden Bedingungen und Fristen.<br />
                Die Beträge werden so schnell wie möglich erstattet, in jedem Fall aber wird das Erstattungsverfahren innerhalb von dreissig (30) Tagen ab dem Datum, an dem der Verkäufer von der Ausübung Ihres Widerrufsrechts Kenntnis erlangt hat, in die Wege geleitet, sofern die oben genannten Fristen und Bedingungen eingehalten wurden. Werden die für die Ausübung des Widerrufsrechts geltenden Bedingungen und Fristen, wie in diesem Absatz beschrieben, nicht eingehalten, haben Sie keinen Anspruch auf Erstattung der bereits an den Verkäufer gezahlten Beträge.</span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}><span className="wixGuard">​</span></span></p>

            <p className="font_8"><span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>7 Reklamationen</span></span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Innerhalb von 7 (sieben) Tagen nach Lieferung der Produkte können Sie Schadensersatzansprüche geltend machen. Solche Reklamationen müssen auf elektronischem Weg erfolgen. Der Verkäufer erstattet nach Prüfung der Ordnungsmässigkeit der Reklamation den entstandenen Schaden. Der Nutzer verpflichtet sich, die von der Reklamation betroffenen Produkte vom Verkäufer oder einer von ihm beauftragten Person zur Beurteilung des Schadens einsehen zu lassen.</span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}><span className="wixGuard">​</span></span></p>

            <p className="font_8"><span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>8 Datenschutz</span></span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Informationen über die Verarbeitung Ihrer personenbezogenen Daten finden Sie in der Datenschutzerklärung auf der Website.</span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}><span className="wixGuard">​</span></span></p>

            <p className="font_8"><span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>9&nbsp;Anwendbares Recht und Gerichtsstand&nbsp;</span></span></p>

            <ol className="font_8" style={{ fontFamily: "Brendon-Grotseque-light" }}>
                <li>
                    <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Sämtliche Rechtsbeziehungen zwischen den Parteien unterstehen schweizerischem Recht unter Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf vom 11.&nbsp;April 1980 (Wiener Kaufrecht).</span></p>
                </li>
                <li>
                    <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Kommt keine Einigung zustande, wird der Schadenfall der Paritätischen Schadenerledigungsstelle der Konsumentenschutzorganisationen, des Verbandes Textilpflege Schweiz (VTS) und des Verbandes der Textilhändler (Swiss Fashion Stores) zur Begutachtung und Schlichtung unterbreitet.&nbsp;Die Einschätzung der Schadenerledigungsstelle ist für die Parteien verbindlich.</span></p>
                </li>
                <li>
                    <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Ausschliesslicher Gerichtsstand für sämtliche Streitigkeiten ist Zürich, Schweiz, soweit das Gesetz nicht zwingend einen anderen Gerichtstand vorsieht.</span></p>
                </li>
            </ol>

            <p className="font_8"><br />
                <span style={{ fontWeight: "bold" }}><span style={{ fontFamily: "Brendon-Grotseque-light" }}>10 Änderungen und Aktualisierung</span></span></p>

            <p className="font_8"><span style={{ fontFamily: "Brendon-Grotseque-light" }}>Die Allgemeinen Verkaufsbedingungen werden von Zeit zu Zeit, auch unter Berücksichtigung möglicher regulatorischer Änderungen, angepasst. Die neuen Allgemeinen Verkaufsbedingungen gelten jeweils ab dem Datum der Veröffentlichung auf der Website.</span></p>
        </div >
    </div >
)

export default Agb