import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';

class LinksModal extends Component {
    render() {

        return (
            <Modal isOpen={this.props.show} toggle={this.props.toggle} wrapClassName="modal-left links-modal"
                modalTransition={{ timeout: 0 }}>
                <ModalHeader toggle={this.props.toggle}>
                    <NavLink to="/" className="navbar-brand" style={{ marginRight: "30px" }}>
                        <img style={{ height: '34px' }} src={require("../img/wos_logo.png")} alt="wös.ch" />
                    </NavLink>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <ul
                            className="navbar-nav"
                            onClick={this.props.toggle}                            
                        >
                            <li className="nav-item">
                                <NavLink to="/" className="nav-link" activeClassName="active" exact>
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/how-it-works" className="nav-link" activeClassName="active">Und so geht's</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/services" className="nav-link" activeClassName="active">Service</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/price" className="nav-link" activeClassName="active">Preise</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/for-companies" className="nav-link" activeClassName="active">Für Firmen</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/eco-friendly" className="nav-link" activeClassName="active">Eco-Friendly</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/contact" className="nav-link" activeClassName="active">Kontakt</NavLink>
                            </li>
                            <li className="nav-item" style={{ border: "0", paddingLeft: "0px", textAlign: "center" }}>
                                <NavLink to="/products" className="nav-link ml-auto mr-auto" activeClassName="active" style={{
                                    border: "1.4px solid",
                                    borderRadius: "25px",
                                    paddingTop: "5px",
                                    paddingBottom: "3px",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    marginTop: "20px",
                                    width: "251px"
                                }}>JETZT BESTELLEN<FontAwesomeIcon className="ml-2" icon={faShoppingBasket} /></NavLink>
                            </li>
                        </ul>
                    </div>
                </ModalBody>

            </Modal>
        )
    }

}

export default LinksModal;