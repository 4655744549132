import {
    GET_BONUSES,
    GET_BONUSES_START,
    GET_BONUSES_SUCCESS,
    GET_BONUSES_FAIL,
} from "./actionTypes";


export const getBonuses = (token) => ({
    type: GET_BONUSES,
    token
});

export const getBonusesStart = () => ({
    type: GET_BONUSES_START
})

export const getBonusesSuccess = (list) => ({
    type: GET_BONUSES_SUCCESS,
    list
})

export const getBonusesFail = (error) => ({
    type: GET_BONUSES_FAIL,
    error
})