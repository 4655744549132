import React, { Component } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions'
import Moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ProductLoader from '../products/ProductLoader';
import ItemDetails from '../shared/ItemDetails/ItemDetails'
import StatusChange from './StatusChange'

class OrderDetails extends Component {

    componentDidMount() {
        const { id } = this.props.match.params
        const { token, fetchOrder } = this.props
        fetchOrder(token, id)
    }

    render() {

        const { loading, order } = this.props

        if (loading || !order) {
            return (<ProductLoader />)
        }

        return (
            <div className="m-10" style={{ minHeight: "calc(100vh - 89px)" }}>
                <Button color="link" style={{ fontSize: "16px", fontWeight: "600" }} onClick={() => this.props.history.push('/admin/orders')}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span className="ml-2">Back to orders</span>
                </Button>
                <div className="pl-4 pr-4 pb-4 ml-4 mr-4 mb-4">
                    <Row className="mt-2 mb-4">
                        <Col xs="4" >
                            <Row><Col><h5>Order details</h5></Col></Row>
                            <Row>
                                <Col>
                                    <Row><Col xs="4">Order ID:</Col><Col xs="8">{order._id}</Col></Row>
                                    <Row><Col xs="4">Date Created:</Col><Col xs="8">{new Moment(order.dateCreated)
                                        .local()
                                        .format("DD-MM-YYYY HH:mm")}</Col></Row>
                                    <Row><Col xs="4">Items price:</Col><Col xs="8">{order.itemsPrice} CHF</Col></Row>
                                    {order.welcomecode && <Row><Col xs="4">Welcome code:</Col><Col xs="8">{order.welcomecode}</Col></Row>}
                                    {order.giftcard && <Row><Col xs="4">Gift-Card code:</Col><Col xs="8">{order.giftcard}</Col></Row>}
                                    {order.bonus > 0 && <Row><Col xs="4">Bonus:</Col><Col xs="8">{order.bonus} CHF</Col></Row>}
                                    <Row><Col xs="4">Shipping price:</Col><Col xs="8">{order.shippingPrice} CHF</Col></Row>
                                    <Row><Col xs="4">Total price:</Col><Col xs="8">{order.totalPrice} CHF</Col></Row>
                                    <Row><Col xs="4">Concardis OrderID:</Col><Col xs="8">{order.payengineOrderId}</Col></Row>
                                    <Row><Col xs="4">Concardis TransactionId:</Col><Col xs="8">{order.payengineTransactionId}</Col></Row>
                                    <Row><Col xs="4">Status:</Col><Col xs="8">
                                        <StatusChange />
                                    </Col></Row>
                                    <Row><Col xs="4"></Col><Col xs="8"></Col></Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="4">
                            <Row><Col><h5>Customer details</h5></Col></Row>
                            <Row>
                                <Col>
                                    <Row><Col xs="4">First Name:</Col><Col xs="8">{order.user.firstName}</Col></Row>
                                    <Row><Col xs="4">Last Name:</Col><Col xs="8">{order.user.lastName}</Col></Row>
                                    <Row><Col xs="4">Email:</Col><Col xs="8">{order.user.email}</Col></Row>
                                    <Row><Col xs="4">Company:</Col><Col xs="8">{order.user.company}</Col></Row>
                                    <Row><Col xs="4">Gender:</Col><Col xs="8">{order.user.gender === "1" ? "Male" : "Female"}</Col></Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col xs="4" >
                            <Row><Col><h5>Pick up address:</h5></Col></Row>
                            {
                                order.selfPickUp &&
                                <Row><Col><strong>Zum lagern bringen</strong></Col></Row>
                            }
                            {
                                order.pickUpAddress &&
                                <React.Fragment>
                                    <Row><Col xs="4">Location Name:</Col><Col xs="8">{order.pickUpAddress.locationName}</Col></Row>
                                    <Row><Col xs="4">Street:</Col><Col xs="8">{order.pickUpAddress.street}</Col></Row>
                                    <Row><Col xs="4">Place:</Col><Col xs="8">{order.pickUpAddress.city}</Col></Row>
                                    <Row><Col xs="4">Phone:</Col><Col xs="8">{order.pickUpAddress.phone}</Col></Row>
                                    <Row><Col xs="4">Note:</Col><Col xs="8">{order.pickUpAddress.note}</Col></Row>
                                    <Row><Col xs="4">Pick up date:</Col><Col xs="8">{`${new Moment(order.pickUpAddress.pickUpDate)
                                        .local()
                                        .format("DD-MM-YYYY")} Period: ${new Moment(order.pickUpAddress.pickUpTimeFrom)
                                            .local()
                                            .format("HH")}-${new Moment(order.pickUpAddress.pickUpTimeTo)
                                                .local()
                                                .format("HH")}`}</Col>
                                    </Row>
                                </React.Fragment>
                            }
                        </Col>
                        <Col xs="4" >
                            <Row><Col><h5>Delivery address:</h5></Col></Row>
                            {
                                order.selfDelivery &&
                                <Row><Col><strong>Im Laden abholen</strong></Col></Row>
                            }
                            {
                                order.deliveryAddress &&
                                <React.Fragment>
                                    <Row><Col xs="4">Location Name:</Col><Col xs="8">{order.deliveryAddress.locationName}</Col></Row>
                                    <Row><Col xs="4">Street:</Col><Col xs="8">{order.deliveryAddress.street}</Col></Row>
                                    <Row><Col xs="4">Place:</Col><Col xs="8">{order.deliveryAddress.city}</Col></Row>
                                    <Row><Col xs="4">Phone:</Col><Col xs="8">{order.deliveryAddress.phone}</Col></Row>
                                    <Row><Col xs="4">Note:</Col><Col xs="8">{order.deliveryAddress.note}</Col></Row>
                                    <Row><Col xs="4">Delivery date:</Col><Col xs="8">{`${new Moment(order.deliveryAddress.deliveryDate)
                                        .local()
                                        .format("DD-MM-YYYY")} Period: ${new Moment(order.deliveryAddress.deliveryTimeFrom)
                                            .local()
                                            .format("HH")}-${new Moment(order.deliveryAddress.deliveryTimeTo)
                                                .local()
                                                .format("HH")}`}
                                    </Col>
                                    </Row>
                                </React.Fragment>
                            }
                        </Col>
                        <Col xs="4" >
                            <Row><Col><h5>Billing address:</h5></Col></Row>
                            <Row><Col xs="4">Location name:</Col><Col xs="8">{order.billingAddress.locationName}</Col></Row>
                            <Row><Col xs="4">Street:</Col><Col xs="8">{order.billingAddress.street}</Col></Row>
                            <Row><Col xs="4">Place:</Col><Col xs="8">{order.billingAddress.city}</Col></Row>
                            <Row><Col xs="4">Phone:</Col><Col xs="8">{order.billingAddress.phone}</Col></Row>
                            <Row><Col xs="4">Note:</Col><Col xs="8">{order.billingAddress.note}</Col></Row>
                            <Row><Col xs="4"></Col><Col xs="8"></Col></Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row><Col><h4>Items:</h4></Col></Row>
                            <Row>
                                <Col>
                                    {
                                        order.items.map((item, index) => {
                                            return (
                                                <ItemDetails item={item} key={index} />
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrder: (token, id) => dispatch(actions.getOrderDetails(token, id))
    };
};

const mapStateToProps = state => ({
    loading: state.orders.loading,
    order: state.orders.order,
    token: state.auth.token
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails));