import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'

export default ({
    napaPrice,
    napaSelected,
    velourPrice,
    velourSelected
}) =>
    (
        <ListGroup flush className="shopping-item-section">
            <ListGroupItem className="item-main-header">
                <strong>Wäscherei</strong>
            </ListGroupItem>
            {
                napaSelected &&
                <ListGroupItem className="item-button" active={napaSelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">Nappa - und Nappalan</div>
                        <div className="small-price">
                            {formatNumber(napaPrice)}
                        </div>
                    </div>
                </ListGroupItem>
            }
            {
                velourSelected &&
                <ListGroupItem className="item-button" active={velourSelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">Velour</div>
                        <div className="small-price">
                            {formatNumber(velourPrice)}
                        </div>
                    </div>
                </ListGroupItem>
            }
        </ListGroup>
    )