import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  authShowed: false,
  isAdmin: false
};

const authStart = (state, action) =>
  updateObject(state, { error: null, loading: true });

const authSuccess = (state, { token, userId, userName, isAdmin, isVip }) => {
  return updateObject(state, {
    token,
    userId,
    userName,
    error: null,
    loading: false,
    isAdmin,
    isVip
  });
}

const authFail = (state, action) =>
  updateObject(state, {
    error: action.error,
    loading: false
  });

const resetPasswordFail = (state, action) =>
  updateObject(state, {
    error: action.error,
    loading: false
  });

const authLogout = (state, action) =>
  updateObject(state, { token: null, userId: null, userName: null, isAdmin: false });

const setAuthRedirectPath = (state, action) =>
  updateObject(state, { authRedirectPath: action.path });


const authModalToggle = (state) => {
  return ({
    ...state,
    authShowed: !state.authShowed
  })
}

const newPasswordModalToggle = (state, { token }) => {
  return ({
    ...state,
    newPasswordShowed: !state.newPasswordShowed,
    passwordResetToken: token
  })
}

const resetPasswordModalToggle = (state) => {
  return ({
    ...state,
    resetPasswordShowed: !state.resetPasswordShowed
  })
}

const resetPasswordStart = (state) => {
  updateObject(state, { error: null, loading: true });
}

const setNewPasswordStart = (state) => {
  updateObject(state, { error: null, loading: true });
}

const resetPasswordModalShow = (state) => {
  return ({
    ...state,
    resetPasswordShowed: true,
    authShowed: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_MODAL_SHOW:
      return resetPasswordModalShow(state);
    case actionTypes.RESET_PASSWORD_MODAL_TOGGLE:
      return resetPasswordModalToggle(state)
    case actionTypes.SET_NEW_PASSWORD_START:
      return setNewPasswordStart(state)
    case actionTypes.NEW_PASSWORD_MODAL_TOGGLE:
      return newPasswordModalToggle(state, action)
    case actionTypes.RESET_PASWORD_FAIL:
      return resetPasswordFail(state);
    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state)
    case actionTypes.AUTH_MODAL_TOGGLE:
      return authModalToggle(state)
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    default:
      return state;
  }
};

export default reducer;

export const getUsername = store => store.auth.userName