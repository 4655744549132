import * as actionTypes from './actionTypes';

export const getProducts = () => ({
    type: actionTypes.GET_PRODUCTS
});

export const getProductsStart = () => ({
    type: actionTypes.GET_PRODUCTS_START
});

export const getProductsSuccess = (products) => ({
    type: actionTypes.GET_PRODUCTS_SUCCESS,
    products
});

export const updateProducts = (products, categoryId, searchTerm) => ({
    type: actionTypes.UPDATE_PRODUCTS,
    products,
    categoryId,
    searchTerm
});

export const getProductsFail = (error) => ({
    type: actionTypes.GET_PRODUCTS_FAIL,
    error
});