import React from 'react'
import MetaTags from 'react-meta-tags';
import Background from '../img/home/photo_home-without-title.png'
import Background2 from '../img/home/warum_wosch_centrata.jpg'

const Home = () => {
    return (
        <div>
            <MetaTags>
                <title>Deine Online Wäscherei & Schneiderei | WÖS.CH</title>
                <meta name="description" content="WÖS.CH ist Dein Online-Wäscherei- und Schneiderei-Service in Zürich – Wir holen Deine Wäsche innert 24h ab & liefern sie Dir wieder – wann immer Du willst. "/>
                <meta name="image" content="https://www.wös.ch/images/logo.png" />
                <meta itemProp="name" content="Deine Online Wäscherei & Schneiderei | WÖS.CH"/>
                <meta itemProp="description" content="WÖS.CH ist Dein Online-Wäscherei- und Schneiderei-Service in Zürich – Wir holen Deine Wäsche innert 24h ab & liefern sie Dir wieder – wann immer Du willst. "/>
                <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
                <meta name="og:title" content="Deine Online Wäscherei & Schneiderei | WÖS.CH"/>
                <meta name="og:description" content="WÖS.CH ist Dein Online-Wäscherei- und Schneiderei-Service in Zürich – Wir holen Deine Wäsche innert 24h ab & liefern sie Dir wieder – wann immer Du willst. "/>
                <meta name="og:image" content="https://www.wös.ch/images/logo.png" />
                <meta name="og:url" content="https://www.wös.ch/"/>
                <meta name="og:site_name" content="wös.ch"/>
                <meta name="og:locale" content="de_CH"/>
                <meta name="og:type" content="website"/>
            </MetaTags>
                <div className="home-bg-image" style={{ background: `url(${Background}) no-repeat`, backgroundSize: '800px', minHeight: "800px", backgroundPosition: "50% 100px" }} >
                    <h1>
                        <picture style={{ position: 'relative', zIndex: -1}}>
                            <source srcSet={require('../img/home/photo_home-title-desktop.png')} media="(min-width: 590px)"></source>
                            <img alt="Dein liebevoller, persönlicher Butler" src={require('../img/home/photo_home-title.png')} className="home-title-img" />
                        </picture>
                    </h1>
                    <div className="home-first-image-div t1">
                        <p className="home-first-image-p" style={{ textAlign: "center" }}>Dein Online-Wäscherei- und Schneiderei-Service in Zürich</p>
                        <p className="home-first-image-p" style={{ textAlign: "center" }}>Abholung und Lieferung innert 24 Stunden, wann immer Du willst.</p>
                    </div>
                </div>
                <div style={{ background: `url(${Background2}) no-repeat`, backgroundSize: 'cover', minHeight: "800px", backgroundPosition: "50% 50%" }} >
                <div className="container" style={{ textAlign: "center" }}>
                        <h2 className="carousel-text-header h1" style={{ paddingTop: "120px" }}>Warum wös.ch?</h2>
                        <p className="carousel-text-content with-bg t1">wös.ch ist menschlich und gleichzeitig extrem digital.</p>
                        <picture>
                            <source srcSet={require('../img/home/stitching_warum_mobile.png')} media="(max-width: 590px)"></source>
                            <img alt="Rahmen" src={require('../img/home/stitching_warum_wösch.png')} className="img-why-wos" />
                        </picture>
                        <br />
                        <p className="carousel-text-content t1"  style={{ paddingTop: "155px" }}>
                            Dein digitaler Butler ist zum Greifen nah und bietet Dir ein einzigartiges und emotionales Service-Erlebnis mit starker menschlicher Seite rund um Deine Person: Alles dreht sich um Dich!
                            Wir schenken Dir die Zeit für die schönen Dinge im Leben. Denn wir kümmern uns um Deine Kleidung und Deine Accessoires.
                        </p>
                        <br />
                        <p className="carousel-text-content t1">
                            Lass Dich von uns verwöhnen:
                        </p>
                        <br />
                        <p className="carousel-text-content t1" style={{ paddingBottom: "50px"}}>
                            Mit einem einfachen Klick sagst Du uns, «was wir wann und wo tun sollen». Wir lassen Deiner Kleidung und Deinen Accessoires eine «massgeschneiderte Pflege» zukommen, denn sie liegen uns genauso sehr am Herzen wie Dir. Schweizer Handwerkskunst mit Flair und Auge fürs Detail.
                            wös.ch ist ein Service von Menschen für Menschen, bei dem zwar die Technologie als Instrument der Erleichterung eingesetzt wird, aber niemals die Dynamiken des Zuhörens und der zwischenmenschlichen Beziehung ersetzt: Der menschliche Touch bleibt der Schlüssel für unser einzigartiges, hervorragendes und individuelles Service-Angebot.
                        </p>
                    </div>
                </div>
                <div className="container" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                    <h2 className="home-page-header h1" style={{paddingBottom: "30px"}}>unvergleichlicher service wann immer du willst</h2>
                    <p className="home-page-text t1">Wir bieten Dir einen ökologisch nachhaltigen, individuellen Qualitäts-Service mit Auge fürs Detail.
                        Du erteilst den Auftrag wann und wo Du willst, zu Hause, unterwegs, aus dem Büro oder in der Wäscherei.
                        Abholung und Lieferung von 7.00 bis 19.00 Uhr, sechs Tage die Woche, wann Du willst.
                        Auftragsverfolgung in Echtzeit. Sichere Zahlungen. Der Concierge Deines Vertrauens!</p>
                </div>
                <div className="services-block container" style={{ paddingTop: "40px", paddingBottom: "30px" }}>
                    <div className="row">
                        <div className="col-lg-3 services-box">
                            <img className="services-icon" alt="Services" src={require('../img/wos/qualität.png')} />
                            <h3 className="service-box-header-text h2">qualität</h3>
                            <p className="service-box-text t2">Wir achten auf jedes noch so kleine Detail,
                                um Dir den bestmöglichen individuellen Service zu bieten.
                        </p>
                        </div>
                        <div className="col-lg-3 services-box">
                            <img className="services-icon" alt="Services" src={require('../img/wos/green.png')} />
                            <h3 className="service-box-header-text h2"> green </h3>
                            <p className="service-box-text t2">Ökologische Nachhaltigkeit durch den Einsatz von 100% erneuerbaren Energien, biologisch abbaubaren Waschmitteln und recyclingfähigen Verpackungen.</p>

                        </div>
                        <div className="col-lg-3 services-box">
                            <img className="services-icon" alt="Services" src={require('../img/wos/smart.png')} />
                            <h3 className="service-box-header-text h2"> smart </h3>
                            <p className="service-box-text t2"> Unser Service ist stets mobil verfügbar, egal wo Du auch bist, ob zu Hause, im Büro
    
                            oder im Gym.  </p>
                        </div>
                        <div className="col-lg-3 services-box">
                            <img className="services-icon" alt="Services" src={require('../img/wos/24Hours.png')} />
                            <h3 className="service-box-header-text h2"> 24 stunden </h3>
                            <p className="service-box-text t2"> Abholung und Lieferung in 24 Stunden an sechs Tagen in der Woche zur Zeit Deiner Wahl. Unser Rundum-sorglos-Service für Dich! </p>
                        </div>
                    </div>
                </div>

<div style = {{backgroundColor: "#ececea" }}>
                <div className="container" style={{ paddingTop: "40px"}}>
                    <h2 className="home-page-header h1" style={{ paddingBottom: "30px"}}>
                        Unsere Geschichte
                    </h2>
                    <p className="home-page-text t2">
                        wös.ch ist ein Online-Projekt für Dienstleistungen rund um die Person,
                        wie Wäscherei und Änderungs- und Reparaturschneiderei, das 2018 gestartet wurde.
                        Zuerst waren wir auf der Suche nach jemanden, der sich zuverlässig, einfach und termingerecht
                        um unsere Wäsche und Accessoires kümmert, und heute tun wir dasselbe mit viel Liebe für Dich.
                        Dabei bieten wir Dir stets die beste Qualität im Einklang mit Deinen Werten und Ansprüchen.
                        Unser Service hat eine starke menschliche und soziale Seite: handwerkliche Arbeit unter Beachtung
                        der kleinsten Details, Einsatz biologisch abbaubarer Waschmittel und Materialien, Lieferung mit
                        umweltfreundlichen Transportmitteln, individueller Conciergerie-Service und vieles mehr.
                        Unsere ständige Zusammenarbeit mit unseren sorgfältig ausgewählten Partnern und Lieferanten
                        ermöglicht es uns, hohe Standards, einen individuellen Service und die Beachtung jedes noch
                        so kleinen Details zu garantieren. Unsere Mission bei wös.ch besteht darin, ein einzigartiges
                        Service-Erlebnis zu schaffen, in dem die Leidenschaft, die Sorge um den Menschen, das Achten auf
                        Qualität und der Blick fürs Detail zur Geltung kommen.
                    </p>
                </div>
                <br/>
                <div className="container" style={{ marginTop: "55px" }}>
                    <h2 className="home-page-header h1" style={{ paddingBottom: "30px"}}>
                        Unsere Werte
                    </h2>
                    <p className="home-page-text t2">
                        Unsere Werte stehen für unsere Kultur, unsere Einstellung und unsere Hingabe an das was wir tun.
                        Diese Werte helfen uns, stets zu sein, was wir wollen, und die richtigen Entscheidungen zu treffen:
                        Von der Wäsche, die wir waschen, über die Handhabung der Annahme und Ausgabe bis hin zum
                        Online-Erlebnis, dass wir unseren Kunden bieten.
                    </p>
                </div>
                <br />
                <div className="services-block container t2" style={{ marginTop: "55px", paddingBottom: "119px" }}>
                    <div className="row">
                        <div className="col-lg-3">
                            <p className='home-page-text-small' style={{ textAlign: "left", fontSize:"16px" }}>
                                <span style={{ fontFamily: "Brendon-Grotseque-medium" }}>* Dinge und Lösungen</span>
                                <br />
                                mit Mehrwert für alle schaffen: für den Kunden, für das Unternehmen, für die Allgemeinheit
                        </p>
                        </div>
                        <div className="col-lg-3">
                            <p className='home-page-text-small' style={{ textAlign: "left", fontSize:"16px" }}>
                                <span style={{ fontFamily: "Brendon-Grotseque-medium" }}>* nachhaltig Denken,</span>
                                <br />
                                Schaffen, Managen und Entscheiden für dauerhafte Nachhaltigkeit bei allem
                            <br />
                                was wir tun
                        </p>
                        </div>
                        <div className="col-lg-3">
                            <p className="home-page-text-small" style={{ textAlign: "left", fontSize:"16px" }}>
                                <span style={{ fontFamily: "Brendon-Grotseque-medium" }}>* menschlich bleiben</span>
                                <br />
                                und stets authentische, individuelle persönliche Beziehungen entwickeln und pflegen
                        </p>
                        </div>
                        <div className="col-lg-3">
                            <p className="home-page-text-small" style={{ textAlign: "left", fontSize:"16px" }}>
                                <span style={{ fontFamily: "Brendon-Grotseque-medium" }}>* ein Zeichen für solide Arbeit setzen,</span>
                                <br />
                                kreative und intelligente Lösungen erdenken, sich stets selbst übertreffen
                        </p>
                        </div>
                        <img alt="" src={require('../img/home/stitching_unsere_werte.png')} className="img-our-values" />
                    </div>
                </div>
                </div>
        </div>
            )
        }
        
export default Home