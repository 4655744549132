import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Switch } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer"
import AuthModal from './auth/AuthModal'
import LinksModal from './LinksModal';
import * as actions from '../store/actions'
import NewPasswordModal from './auth/NewPasswordModal';
import ResetPasswordModal from './auth/ResetPasswordModal';
import HowItWorks from './HowItWorks';
import Services from "./Services";
import Home from "./Home"
import PricesPage from "./pricesPage/PricesPage";
import ForCompanies from './ForCompanies';
import EcoFriendly from './EcoFriendly';
import Disclaimer from './Agb'
import Privacy from './Datenschutz'
import Faq from './Faq'
//import UserProfile from './auth/UserProfile/UserProfile'
//import UserOrdersDetails from './auth/UserProfile/OrdersTab/OrderDetails'
import OrderDetails from './admin/OrderDetails'
import ShoppingCartWrapper from "./shoppingCart/ShoppingCartWrapper";
//import Products from './products/Products'
import Contact from './Contact';
import OrdersGrid from './admin/OrdersGrid';
import UsersGrid from './admin/UsersGrid';
import UserDetails from './admin/UserDetails';
import Payengine from './Payengine';
import ProductLoader from './products/ProductLoader';
import { Fade } from 'reactstrap';

const Products = lazy(() => import('./products/Products'));
const UserProfile = lazy(() => import('./auth/UserProfile/UserProfile'));
const UserOrdersDetails = lazy(() => import('./auth/UserProfile/OrdersTab/OrderDetails'));

const ScrollToTop = () => {
    let a = document.querySelector('.content')
    if (a) {
        a.scrollTop = 0
    }
    return null;
};

const authRoutes = (isAdmin) => {
    const routes = [
        { path: "/profile", component: UserProfile },
        { path: "/profile/orders/:id", component: UserOrdersDetails },
        { path: "/profile/orders", component: UserProfile },
        { path: "/profile/bonuses", component: UserProfile },
        { path: "/shopping-cart/page2", component: ShoppingCartWrapper },
    ]

    if (isAdmin) {
        return [...routes, { path: "/admin/orders", component: OrdersGrid },
        { path: "/admin/orders/:id", component: OrderDetails },
        { path: "/admin/users", component: UsersGrid },
        { path: "/admin/users/:id", component: UserDetails }]
    }

    return routes
}

class Layout extends Component {

    state = {
        fadeIn: false,
    }

    componentDidMount() {
        const { location: { pathname } } = this.props
        this.props.onTryAutoSignup(pathname);
    }

    toggleSideBar = () => {
        this.props.toggleSideBar()
    };

    render() {

        const { isAuthenticated, isAdmin } = this.props

        const withFade = component => <Fade timeout={50}>{component}</Fade>

        return (
            <div className="wrap" >
                <AuthModal />
                <NewPasswordModal />
                <ResetPasswordModal />
                <LinksModal
                    show={this.props.showSideBarModal}
                    toggle={this.toggleSideBar}
                />
                <Header />
                <div className="content">
                    <Route component={ScrollToTop} />
                    <Suspense fallback={<div><ProductLoader /></div>}>
                        <Switch>
                            <Route exact path="/" component={() => withFade(<Home />)} />
                            <Route exact path="/contact" component={() => withFade(<Contact />)} />
                            <Route exact path="/products/:defaultValue?" component={() => withFade(<Products />)} />
                            <Route exact path="/how-it-works" component={() => withFade(<HowItWorks />)} />
                            <Route exact path="/services" component={() => withFade(<Services />)} />
                            <Route exact path="/price" component={() => withFade(<PricesPage />)} />
                            <Route exact path="/agb" component={() => withFade(<Disclaimer />)} />
                            <Route exact path="/privacy" component={() => withFade(<Privacy />)} />
                            <Route exact path="/faq" component={() => withFade(<Faq />)} />
                            <Route exact path="/shopping-cart/page1" component={ShoppingCartWrapper} />
                            <Route exact path="/for-companies" component={() => withFade(<ForCompanies />)} />
                            <Route exact path="/eco-friendly" component={() => withFade(<EcoFriendly />)} />
                            <Route exact path="/payengine" component={Payengine} />
                            {
                                isAuthenticated && authRoutes(isAdmin).map((route, index) => (<Route key={index} exact path={route.path} component={route.component} />))
                            }
                            <Route component={Home} />
                        </Switch>
                    </Suspense>
                    <Footer />
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: (pathname) => dispatch(actions.authCheckState(pathname)),
        toggleSideBar: () => dispatch(actions.toggleSideBarModal()),
        openAuthentication: () => dispatch(actions.authModalToggle())
    };
};

const mapStateToProps = state => {
    return {
        isAdmin: state.auth.isAdmin,
        showSideBarModal: state.layout.sideBarModal,
        isAuthenticated: state.auth.token !== null
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));