import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from 'final-form-material-ui';
import { Form as FinalForm, Field } from 'react-final-form'
import {
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { Button } from "react-bootstrap";
import { formatNumber } from '../../Utils'
import * as actions from '../../store/actions'
import { FORM_ERROR } from "final-form";


class BonusField extends React.Component {

    componentWillUnmount() {
        const { deleteCode } = this.props
        deleteCode()
    }

    codeValid = async (value, token, totalPrice) => {
        if (value) {
            try {
                let result = await fetch(`/api/users/checkcode`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept-Language": "de-DE",
                        "x-auth-token": token
                    },
                    body: JSON.stringify({ code: value, totalPrice: totalPrice })
                });

                let resultObj = await result.json()

                if (!resultObj.valid) {
                    return resultObj.message
                }

            } catch (error) {
                return 'Code ungültig'
            }
        }
    }


    render() {

        const { bonus, deleteCode, totalPrice, codeUsed, token, applyCode, codeValue } = this.props;

        return (
            <>
                {
                    !codeUsed && <div className="gifts">
                        <FinalForm
                            onSubmit={
                                async values => {
                                    await new Promise((resolve, reject) => {
                                        try {
                                            applyCode(values.code, token, resolve, reject)
                                        } catch (error) {
                                            return { [FORM_ERROR]: "" };
                                        }
                                    })
                                }
                            }
                            initialValues={{}}
                            subscription={{
                                validating: true,
                                values: true,
                                submitting: true,
                                pristine: true,
                                dirtySinceLastSubmit: true,
                                submitSucceeded: true,
                                submitError: true,
                                submitErrors: true,
                                hasValidationErrors: true
                            }}
                            render={({
                                form,
                                handleSubmit,
                                submitting,
                                pristine,
                                validating,
                                values,
                                hasValidationErrors,
                                submitError,
                            }) => {
                                return (
                                    <Form onSubmit={(event) => handleSubmit(event).then(() => form.reset())}>
                                        <FormGroup>
                                            <div style={{ display: 'block' }}>Bon, Gift Card, Prepaid-Karte</div>
                                            <Row>
                                                <Col sm="12">
                                                    <Field
                                                        validate={async (value) => this.codeValid(value, token, totalPrice)}
                                                        className="prepaid-input"
                                                        name="code"
                                                        render={(props) => <TextField {...props} disabled={submitting} />}
                                                        type="search"
                                                        fullWidth
                                                        placeholder="Code eingeben"
                                                    />
                                                </Col>
                                                <Col className="text-right mt-2">
                                                    <Button className='float-right'
                                                        type="submit"
                                                        style={{ width: '115px', fontSize: "14px" }}
                                                        disabled={submitting || validating || pristine || hasValidationErrors || !values}
                                                    >{`BESTÄTIGEN${validating || submitting ? "..." : ""}`}</Button>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Form>
                                )
                            }}
                        />
                    </div>
                }
                {
                    codeUsed &&
                    <div>
                        <Row style={{ marginLeft: "10px", marginRight: "15px", fontWeight: "600", paddingTop: "5px" }}>
                            <button
                                className="btn btn-link" style={{ backgroundColor: "transparent", width: "22px", margin: "0px", padding: "0px" }}
                                onClick={() => deleteCode()}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <span className="ml-2 mr-auto p-2">Verwendetes Guthaben und Bons</span>
                            <span className="price-total" style={{ paddingRight: "15px", paddingTop: "10px" }}>-{formatNumber(bonus)} CHF</span>
                        </Row>
                        <div className="d-flex justify-content-between"
                            style={{ paddingLeft: "15px", paddingRight: "15px", paddingBottom: "5px" }}>
                            <div>Restguthaben</div>
                            <div style={{ paddingRight: "14px" }}>{`${codeValue - bonus} CHF`}</div>
                        </div>
                    </div>
                }
            </>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        applyCode: (code, token, resolve, reject) => dispatch(actions.applyCode(code, token, resolve, reject)),
        deleteCode: () => dispatch(actions.deleteCode())
    };
};

const mapStateToProps = state => ({
    totalPrice: state.shoppingCart.totalPrice,
    bonus: state.shoppingCart.bonus,
    codeUsed: state.shoppingCart.codeUsed,
    shippingPrice: state.shoppingCart.shippingPrice,
    token: state.auth.token,
    welcomecode: state.shoppingCart.welcomecode,
    codeValue: state.shoppingCart.codeValue
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusField);

