import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { getItemsCount } from '../store/reducers/shoppingCart';
import { getUsername } from '../store/reducers/auth';
import { formatNumber } from '../Utils'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';

import * as actions from '../store/actions'

class Header extends Component {

    state = {
        dropdownOpen: false
    };

    render() {

        const { totalPrice, itemsCount, userName, OnAuthModalToggle, OnUserLogout, isAdmin } = this.props

        return (
            <React.Fragment>
                <header className="App-header">
                    <nav className="navbar navbar-expand-xl">
                        <div className="container-fluid">
                            <button
                                type="button"
                                aria-controls="navbarCollapse"
                                aria-expanded="false"
                                className="navbar-toggler navbar-toggler-right"
                                onClick={() => { this.props.toggleSideBar() }}
                            >
                                <i className="fa fa-bars" />
                            </button>
                            <div id="navbarCollapse" className="collapse navbar-collapse">
                                <ul className="navbar-nav navbar-margin" style={{ whiteSpace: "nowrap" }}>
                                    <NavLink to="/" className="navbar-brand">
                                        <img style={{ height: '34px' }} src={require("../img/wos_logo.png")} alt="Home" />
                                    </NavLink>
                                    {/* <li className="nav-item">
                                        <NavLink to="/" className="nav-link" activeClassName="active" exact>Home</NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <NavLink to="/how-it-works" className="nav-link" activeClassName="active">Und so geht's</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/services" className="nav-link" activeClassName="active">Service</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/price" className="nav-link" activeClassName="active">Preise</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/for-companies" className="nav-link" activeClassName="active">Für Firmen</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/eco-friendly" className="nav-link" activeClassName="active">Eco-Friendly</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/contact" className="nav-link" activeClassName="active">Kontakt</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/products" className="nav-link shopping-button" activeClassName="active" style={{ marginLeft: "20px" }}>
                                            JETZT BESTELLEN<FontAwesomeIcon className="ml-2" icon={faShoppingBasket} />
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="right-col d-flex align-items-lg-end flex-row flex-lg-row ml-auto" style={{ minWidth: "200px", whiteSpace: "nowrap" }}>
                                <div className="user ml-auto">
                                    <a
                                        href="/#"
                                        className="user-link"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if (userName) {
                                                this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))
                                            } else {
                                                OnAuthModalToggle()
                                            }
                                        }}>
                                        <span style={{ color: "white", fontFamily: "Brendon-Grotseque-regular", fontSize: "14px" }}>
                                            {userName}
                                        </span>
                                        {' '}
                                        <i className="icon-profile">
                                        </i>
                                    </a>
                                </div>
                                <div className="cart dropdown show">
                                    <Link
                                        to="/shopping-cart/page1"
                                        className="dropdown-toggle"
                                    >
                                        <span style={{ color: "white", fontFamily: "Brendon-Grotseque-regular", fontSize: "14px" }}>
                                            {formatNumber(totalPrice)}
                                        </span>
                                        <span style={{ color: "white", fontFamily: "Brendon-Grotseque-regular", fontSize: "14px" }} className="ml-1">&#67;&#72;&#70;</span>
                                        {' '}
                                        <i className="icon-cart">
                                        </i>
                                        {
                                            itemsCount > 0 &&
                                            <div className="cart-no">{itemsCount}</div>
                                        }
                                    </Link>
                                    <Link to="/shopping-cart" className="text-primary view-cart"></Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                <Dropdown isOpen={this.state.dropdownOpen}
                    toggle={() => this.setState(prevState => ({
                        dropdownOpen: !prevState.dropdownOpen
                    }))}>
                    <DropdownToggle caret style={{ display: "none" }} />
                    <DropdownMenu className="user-login-menu">
                        <DropdownItem tag={Link} to="/profile">Siehe Profil</DropdownItem>
                        {isAdmin && <DropdownItem tag={Link} to="/admin/orders">Admin - Orders</DropdownItem>}
                        {isAdmin && <DropdownItem tag={Link} to="/admin/users">Admin - Users</DropdownItem>}
                        <DropdownItem tag={Link} to="#" onClick={() => OnUserLogout()}>Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnAuthModalToggle: () => dispatch(actions.authModalToggle()),
        OnUserLogout: () => dispatch(actions.logout()),
        toggleSideBar: () => dispatch(actions.toggleSideBarModal())
    };
};

const mapStateToProps = state => ({
    isAdmin: state.auth.isAdmin,
    userName: getUsername(state),
    itemsCount: getItemsCount(state),
    totalPrice: state.shoppingCart.totalPrice
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);