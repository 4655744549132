import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import SubtotalItem from "./SubtotalItem";
import { formatNumber } from '../../Utils'
import BonusField from './BonusField';


class ShoppingCartTotals extends Component {
    state = {
        collapse: false
    };

    toggleCollapse = () => {
        this.setState({ collapse: !this.state.collapse })
    };

    render() {
        const { collapse } = this.state;
        const { cartItems, itemsPrice, totalPrice, shippingPrice, bonus } = this.props;

        return (
            <React.Fragment>
                <Collapse isOpen={this.state.collapse}>
                    <div className='subtotals'>
                        <div className='subtotal-items'>
                            {cartItems.map((item, index) => {
                                return (
                                    <SubtotalItem
                                        key={index}
                                        item={item}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </Collapse>
                <div className="totals">
                    {/* Cart items. */}
                    <div className="totals-sum">
                        <span>
                            <Label>Auftragssumme</Label>
                        </span>
                        <span />
                        <span className='price-total' onClick={this.toggleCollapse}>
                            {formatNumber(itemsPrice)} CHF &nbsp;
                            <FontAwesomeIcon icon={collapse ? faChevronUp : faChevronDown} />
                        </span>

                    </div>

                    {/* Shipping cost. */}

                    <div className="shipping-cost">
                        <div className="shipping-cost-note">
                            <span>Abholung und Lieferung für Aufträge über 30.00 CHF gratis</span>
                        </div>
                        <div className="shipping-total">
                            <span>
                                <Label>Zustellungskosten</Label>
                            </span>
                            <span />
                            <span className='price-total'>
                                {formatNumber(shippingPrice)} CHF
                            </span>
                        </div>
                    </div>

                    {/*Total including shipping*/}
                    <div>
                        <div className="total-including-shipping">
                            <span>
                                <Label>Summe inkl. Zustellungskosten</Label>
                            </span>
                            <span />
                            <span className='price-total'>
                                {formatNumber(totalPrice + bonus)} CHF
                            </span>
                        </div>
                    </div>

                    {/*Promo and gifts and coupons*/}
                    {/* <div className="gifts">
                        <FinalForm
                            onSubmit={(values) => { return values }}
                            initialValues={{}}

                            render={({
                                form,
                                handleSubmit,
                            }) => (
                                    <Form onSubmit={(event) => handleSubmit(event).then(() => form.reset())}>
                                        <FormGroup>
                                            <Label style={{ display: 'block' }}>Code for Prepaid-/Geschenkkarte/Bon</Label>
                                            <Field
                                                className="prepaid-input"
                                                name="prepaid"
                                                component={TextField}
                                                type="text"
                                                placeholder="Eingeben"
                                            />
                                            <Button className='float-right' style={{ width: 'unset' }}>BESTÄTIGEN</Button>
                                        </FormGroup>
                                    </Form>
                                )}
                        />
                    </div> */}
                    <BonusField />
                    {/*Final total inlcuing bonous and shipment and copuons*/}
                    <div>
                        <div className="total-including-shipping">
                            <span>
                                <Label>Zu zahlender Betrag</Label>
                            </span>
                            <span />
                            <span className='price-total'>
                                {formatNumber(totalPrice)} CHF
                            </span>
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    cartItems: state.shoppingCart.items,
    itemsPrice: state.shoppingCart.itemsPrice,
    totalPrice: state.shoppingCart.totalPrice,
    bonus: state.shoppingCart.bonus,
    shippingPrice: state.shoppingCart.shippingPrice,
    token: state.auth.token,
    welcomecode: state.shoppingCart.welcomecode
});

export default connect(mapStateToProps, null)(ShoppingCartTotals);