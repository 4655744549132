import { put, call } from "redux-saga/effects";

import * as actions from "../actions"

export function* GetAdminUsers({ token, dataState }) {

    yield put(actions.getUsersStart());

    try {
        const response = yield call(fetch, '/api/users/admin', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            },
            body: JSON.stringify(dataState),
        });

        const users = yield response.json();

        yield put(actions.getUsersSuccess(users.users, dataState, users.total));

    } catch (e) {
        yield put(actions.getUsersFail(e));
        return;
    }
}


export function* getAdminUserDetails({ token, id }) {

    yield put(actions.getUserStart());

    try {
        const response = yield call(fetch, `/api/users/admin/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            }
        });

        const user = yield response.json();

        yield put(actions.getUserDetailsSuccess(user));

    } catch (e) {
        yield put(actions.getUserDetailsFail(e));
        return;
    }

}