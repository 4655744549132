import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'

export default ({
    button,
    buttonSelected,
    buttonPrice,
    buttonQuantity,
    hem,
    hemSelected,
    hemQuantity,
    hemPrice,
    zipper,
    zipperSelected,
    zipperQuantity,
    zipperPrice
}) =>
    (
        <ListGroup flush className="shopping-item-section">
            <ListGroupItem className="item-main-header">
                <strong>Schneiderei</strong>
            </ListGroupItem>
            {
                buttonSelected &&
                <ListGroupItem className="item-button" active={buttonSelected}>
                    <div className="d-flex">
                        <div className="item-header">Knopf</div>
                        <div className="small-price">
                            {formatNumber(buttonPrice)}
                        </div>
                    </div>
                    {
                        buttonSelected &&
                        <div className="d-flex pt-1">
                            <div className="ml-auto">
                                Menge: {buttonQuantity}
                            </div>
                        </div>
                    }
                </ListGroupItem>
            }
            {
                hemSelected &&
                <ListGroupItem className="item-button" active={hemSelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">Saum</div>
                        <div className="small-price">
                            {formatNumber(hemPrice)}
                        </div>
                    </div>
                    {
                        hemSelected &&
                        <div className="d-flex pt-1">
                            <div className="ml-auto">
                                Menge: {hemQuantity}
                            </div>
                        </div>
                    }
                </ListGroupItem>
            }
            {
                zipperSelected &&
                <ListGroupItem className="item-button" active={zipperSelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">Reissverschluss</div>
                        <div className="small-price">
                            {formatNumber(zipperPrice)}
                        </div>
                    </div>
                    {
                        zipperSelected &&
                        <div className="d-flex pt-1">
                            <div className="ml-auto">
                                Menge: {zipperQuantity}
                            </div>
                        </div>
                    }
                </ListGroupItem>
            }
        </ListGroup>
    )