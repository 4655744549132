import _ from 'lodash'

import {
    GET_USER_DETAILS_FAIL,
    GET_USER_DETAILS_SUCCESS,
    // GET_USER_DETAILS,
    // GET_ADMIN_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    GET_USERS_START,
    GET_USER_START
    //GET_USERS
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    list: [],
    pages: 0,
    dataState: {
        pageSize: 20,
        page: 0,
        sorted: [{ id: "dateCreated", desc: true }],
        filtered: [],
    }
};

const getUsersStart = (state) => {
    return ({
        ...state,
        loading: true,
        list: [],
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        }
    })
}

const getUsersSuccess = (state, { list, dataState, pages }) => {
    return ({
        ...state,
        pages,
        loading: false,
        list: [...list],
        dataState: {
            ...dataState,
            sorted: [...dataState.sorted],
            filtered: [...dataState.filtered]
        }
    })
}

const getUsersFail = (state, { error }) => {
    return ({
        ...state,
        loading: false,
        list: [],
        error: error,
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        }
    })
}

const getUserDetailsSuccess = (state, { user }) => {
    let newState = _.cloneDeep(state)
    newState.user = user;
    newState.loading = false;
    return newState;
}

const getUserStart = (state) => {
    let newState = _.cloneDeep(state)
    newState.loading = true;
    return newState;
}

const getUserDetailsFail = (state, { error }) => {
    return ({
        ...state,
        loading: false,
        list: [...state.list],
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        },
        error: error
    })
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_START:
            return getUserStart(state, action)
        case GET_USERS_START:
            return getUsersStart(state, action)
        case GET_USERS_SUCCESS:
            return getUsersSuccess(state, action)
        case GET_USERS_FAIL:
            return getUsersFail(state, action)
        case GET_USER_DETAILS_SUCCESS:
            return getUserDetailsSuccess(state, action)
        case GET_USER_DETAILS_FAIL:
            return getUserDetailsFail(state, action)
        default:
            return state;
    }
};