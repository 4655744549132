import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { Form as FinalForm, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui';
// import { FormSpy } from 'react-final-form'
import { giftCartItemSubmit } from '../../store/actions';

const validate = async values => {
    const errors = {};

    if (!values.name) {
        errors.name = "Der Vor- und Nachname ist erforderlich"
    }

    if (!values.email) {
        errors.email = "Die E-Mail ist erforderlich"
    } else {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        if (!expression.test(String(values.email).toLowerCase())) {
            errors.email = "Nicht gültige E-Mail-Adresse"
        }
    }
    if (!values.emailCheck) {
        errors.emailCheck = "Wiederholtes E-Mail ist erforderlich"
    }
    if (values.email && values.emailCheck) {
        if (values.email !== values.emailCheck) {
            errors.emailCheck = "Die eingegebenen E-Mail stimmen nicht überein"
        }
    }

    if (!values.message) {
        errors.message = "Der Kommentar ist erforderlich"
    }

    return errors;
}

export default ({ itemIndex }) => {

    const item = useSelector(state => state.shoppingCart.items[itemIndex])

    const dispatch = useDispatch()

    const onSubmit = (values) => {
        dispatch(giftCartItemSubmit(values, itemIndex))
    }

    const { confirmed } = item.giftCard

    return (
        <>
            <FinalForm
                onSubmit={onSubmit}
                validate={validate}
                initialValues={{
                    message: item.giftCard.message,
                    email: item.giftCard.email,
                    emailCheck: item.giftCard.email,
                    name: item.giftCard.name
                }}
                subscription={{
                    submitting: true,
                    pristine: true,
                    dirtySinceLastSubmit: true,
                    submitSucceeded: true,
                    submitError: true,
                    submitErrors: true,
                    hasValidationErrors: true
                }}
                render={({
                    values,
                    form,
                    handleSubmit,
                    submitting,
                    pristine,
                    validating,
                    hasValidationErrors,
                    submitError
                }) => (
                        <>
                            <Col lg className="mt-3 mb-3 ml-2">
                                <div className="controls" style={{ minWidth: "398px" }}>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <Field
                                                    fullWidth
                                                    name="name"
                                                    component={TextField}
                                                    type="text"
                                                    InputProps={{
                                                        readOnly: confirmed,
                                                    }}
                                                    label="Vor- und Nachname der Person, die das Geschenk erhalten wird"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <Field
                                                    fullWidth
                                                    name="email"
                                                    component={TextField}
                                                    type="text"
                                                    label="E-Mail"
                                                    InputProps={{
                                                        readOnly: confirmed,
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <Field
                                                    fullWidth
                                                    name="emailCheck"
                                                    component={TextField}
                                                    type="text"
                                                    label="E-Mail wiederholen"
                                                    InputProps={{
                                                        readOnly: confirmed,
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Field
                                                        fullWidth
                                                        name="message"
                                                        component={TextField}
                                                        multiline
                                                        rows="3"
                                                        type="text"
                                                        label="Persönliche Nachricht"
                                                        InputProps={{
                                                            readOnly: confirmed,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg className="mb-3 ml-2" style={{ marginTop: "26px" }}>
                                <Row>
                                    <Col>
                                        <h5 align="center">Schenke deinen Liebsten die Dienstleistungen der wös.ch!</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="info">
                                            <img src={require('../../img/baseline-face-24px.svg')} alt="" /><br />
                                            <span style={{ fontSize: "14px" }}>
                                                Fülle das Formular mit dem Namen und der E-Mail Adresse der Person aus, die das Geschenk erhalten wird und gebe eine persönliche Nachricht ein.
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <button
                                            disabled={(submitting || validating || hasValidationErrors) && !confirmed}
                                            type="submit"
                                            onClick={() => handleSubmit(values)}
                                            className={`btn ${confirmed ? "btn-info" : "message-btn"}`}
                                            style={{ width: "140px", height: "39px" }}>
                                            {confirmed ? "Ändern" : "Bestätigen"}
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
            />
            <Col style={{ marginLeft: "-30px" }}>
            </Col>
        </>
    )
}