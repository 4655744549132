import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Jumbotron } from 'reactstrap';


const ProductCardPrice = ({ title, price, imageSrc, priceTitle, url }) => (
    <a target="_blank" href={`/products${url ? '/'+title.toLowerCase() : ''}`} class="col-sm-auto mb-2">
        <div className="card card ml-auto mr-auto"
            style={{
                width: "200px",
                borderRadius: "0",
                border: "0"
            }}>
            <img className="card-img-top" src={imageSrc} alt="Card image cap"
                style={{
                    width: "200px",
                    borderRadius: "0",
                    border: "0"
                }}
            />
            <div className="card-footer"
                style={{ borderRadius: "0", backgroundColor: "#acc7de", border: "0", padding: "1px" }}>
                <div className="d-flex justify-content-end"
                    style={{ border: "2px dashed #23479e", alignItems: "center", height: "70px" }}>
                    <div style={{
                        color: "#23479e",
                        height: "100%",
                        display: "table"
                    }} className="mr-auto ml-auto">
                        <div
                            style={{
                                display: "table-cell",
                                verticalAlign: "middle",
                                fontFamily: "holtzman-textured",
                                letterSpacing: "0.1em",
                                fontSize: "26px"
                            }}>
                            {title}
                        </div>
                    </div>
                    <div style={{
                        color: "#23479e",
                        backgroundColor: "#dedad4",
                        marginRight: "2px",
                        height: "63px",
                        display: "table"
                    }} >
                        <div style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            fontFamily: "holtzman-textured"
                        }}>
                            <div className="d-flex" style={{ paddingLeft: "8px", paddingRight: "6px", lineHeight: "1", paddingBottom: "10px" }}>
                                <span style={{ fontSize: "40px" }}>{`${price.split(".")[0]}.`}</span>
                                <div>
                                    <span style={{ fontSize: "24px", paddingTop: "7px" }}>{`${price.split(".")[1]}`}</span>
                                    {
                                        priceTitle && <span style={{
                                            fontSize: "10px",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                            marginTop: "-4px"
                                        }}>{`${priceTitle}`}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
)

class PricesPage extends Component {
    render() {

        const bekleidung = [
            { title: "HEMD", imageSrc: "/content/images/prices/rsz_hemd.jpg", price: "4.90", url: true},
            { title: "HOSE", imageSrc: "/content/images/prices/rsz_hose.jpg", price: "13.70", url: true },
            { title: "BLUSE", imageSrc: "/content/images/prices/rsz_bluse.jpg", price: "9.80", url: true },
            { title: "JACKE", imageSrc: "/content/images/prices/rsz_jacke.jpg", price: "21.70", url: true },
            { title: "KLEID", imageSrc: "/content/images/prices/rsz_kleid.jpg", price: "18.90", url: true }
        ]

        const heimtextilien = [
            { title: "DECKE", imageSrc: "/content/images/prices/rsz_decke.jpg", price: "23.00", url: true },
            { title: "KISSEN", imageSrc: "/content/images/prices/rsz_kissen.jpg", price: "25.00", url: true },
            { title: "VORHÄNGE", imageSrc: "/content/images/prices/rsz_vorhange.jpg", price: "7.50", priceTitle: "pro m2" },
            { title: "DUVET", imageSrc: "/content/images/prices/rsz_duvet.jpg", price: "35.00", url: true },
            { title: "TEPPICH", imageSrc: "/content/images/prices/rsz_teppich.jpg", price: "21.70", priceTitle: "pro m2", url: true }
        ]

        const schneiderei = [
            { title: "KNOPF", imageSrc: "/content/images/prices/rsz_knopf.jpg", price: "5.00" },
            { title: "KÜRZEN", imageSrc: "/content/images/prices/rsz_kurzen.jpg", price: "20.00" },
            { title: "ZIPP", imageSrc: "/content/images/prices/rsz_zipp.jpg", price: "35.00" },
            { title: "ENGER", imageSrc: "/content/images/prices/rsz_enger.jpg", price: "35.00" },
            { title: "KÜRZEN", imageSrc: "/content/images/prices/rsz_kurzen2.jpg", price: "35.00" }
        ]

        return (
            <div style={{ textAlign: 'center', marginBottom: "50px" }}>
                <MetaTags>
                    <title>Unsere attraktiven Preise | WÖS.CH</title>
                    <meta name="description" content="Hier erhälst Du eine detaillierte Übersicht unserer attraktiven Preise - Wir reinigen alle Deine Kleidungsstücke zuverlässig & schnell." />
                    <meta name="image" content="https://www.wös.ch/images/waschbrett.png" />
                    <meta itemProp="name" content="Unsere attraktiven Preise | WÖS.CH" />
                    <meta itemProp="description" content="Hier erhälst Du eine detaillierte Übersicht unserer attraktiven Preise - Wir reinigen alle Deine Kleidungsstücke zuverlässig & schnell." />
                    <meta itemProp="image" content="https://www.wös.ch/images/waschbrett.png" />
                    <meta name="og:title" content="Unsere attraktiven Preise | WÖS.CH" />
                    <meta name="og:description" content="Hier erhälst Du eine detaillierte Übersicht unserer attraktiven Preise - Wir reinigen alle Deine Kleidungsstücke zuverlässig & schnell." />
                    <meta name="og:image" content="https://www.wös.ch/images/waschbrett.png" />
                    <meta name="og:url" content="https://www.wös.ch/price" />
                </MetaTags>
                <div>
                    <div className="services-page jumbotron">
                        <h1 className="carousel-text-header h1" style={{ paddingBottom: "20px" }}>Unsere attraktiven preise für sie</h1>
                        <p className="carousel-text-content py-20 t1">
                            Wir sind auf die professionelle Reinigung und Pflege von Textilien spezialisiert.<br />
                            Ihre Kleidungsstücke wie Anzüge, Jacken,<br />
                            Blusen, Jupes oder Hosen reinigen wir zuverlässig und schnell.
                        </p>
                    </div>
                    <img alt="" src={require('../../img/stitching_unsere_attraktiven.png')} className="img-prices" />
                </div>
                <Jumbotron className="m-0" style={{ backgroundColor: "white", paddingTop: "32px", paddingBottom: "32px" }}>
                    <h2 className="h2 m-0" style={{ letterSpacing: "0.1em", paddingBottom: "64px" }}>Bekleidung</h2>
                    <div class="row justify-content-md-center">
                        {
                            bekleidung.map(t => (
                                <ProductCardPrice
                                    key={t.title}
                                    title={t.title}
                                    price={t.price}
                                    url={t.url}
                                    imageSrc={t.imageSrc} />
                            ))
                        }
                    </div>
                </Jumbotron>
                <Jumbotron className="m-0" style={{ backgroundColor: "white", paddingTop: "24px", paddingBottom: "32px" }}>
                    <h2 className="h2 m-0" style={{ letterSpacing: "0.1em", paddingBottom: "64px" }}>Heimtextilien</h2>
                    <div class="row justify-content-md-center">
                        {
                            heimtextilien.map(t => (
                                <ProductCardPrice
                                    key={t.title}
                                    title={t.title}
                                    price={t.price}
                                    imageSrc={t.imageSrc}
                                    url={t.url}
                                    priceTitle={t.priceTitle} />
                            ))
                        }
                    </div>
                </Jumbotron>
                <Jumbotron className="m-0" style={{ backgroundColor: "white", paddingTop: "24px", paddingBottom: "32px" }}>
                    <h2 className="h2 m-0" style={{ letterSpacing: "0.1em", paddingBottom: "64px" }}>Schneiderei</h2>
                    <div class="row justify-content-md-center">
                        {
                            schneiderei.map(t => (
                                <ProductCardPrice
                                    key={t.title}
                                    title={t.title}
                                    price={t.price}
                                    imageSrc={t.imageSrc}
                                    priceTitle={t.priceTitle} />
                            ))
                        }
                    </div>
                </Jumbotron>
            </div>
        )
    }
}

export default PricesPage;