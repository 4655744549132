import * as actionTypes from '../actions/actionTypes'
import _ from 'lodash'


export const getProductsStart = (state) => {
    return ({
        ...state,
        loading: true
    })
}

export const updateProducts = (state, { products, categoryId, searchTerm }) => {
    return ({
        ...state,
        products: products.map(product => _.cloneDeep(product)),
        allProducts: state.allProducts.map(product => _.cloneDeep(product)),
        categoryId: categoryId,
        searchTerm: searchTerm
    })
}

export const getProductsSuccess = (state, { products }) => {
    return ({
        ...state,
        products: products.map(product => _.cloneDeep(product)),
        allProducts: products.map(product => _.cloneDeep(product)),
        loading: false
    })
}

export const getProductsFail = (state, { error }) => {
    return ({
        ...state,
        error: error,
        loading: false
    })
}

export default (state = { products: [], loading: true, searchTerm: "", categoryId: "0", allProducts: [] }, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PRODUCTS:
            return updateProducts(state, action)
        case actionTypes.GET_PRODUCTS_START:
            return getProductsStart(state, action)
        case actionTypes.GET_PRODUCTS_SUCCESS:
            return getProductsSuccess(state, action)
        case actionTypes.GET_PRODUCTS_FAIL:
            return getProductsFail(state, action)
        default:
            return state;
    }
};