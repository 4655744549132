import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';

import ResetPassword from './ResetPassword'

import * as actions from '../../store/actions'


class ResetPasswordModal extends React.Component {

  render() {

    const { resetPasswordShowed, OnResetPasswordModalToggle } = this.props

    return (
      <Modal isOpen={resetPasswordShowed} toggle={() => OnResetPasswordModalToggle()} centered size="sm">
        <ModalHeader toggle={() => OnResetPasswordModalToggle()}>Passwort zurücksetzen</ModalHeader>
        <ModalBody>
          <ResetPassword />
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resetPasswordShowed: state.auth.resetPasswordShowed
  };
};

const mapDispatchToProps = dispatch => {
  return {
    OnResetPasswordModalToggle: () => dispatch(actions.resetPasswordModalToggle())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal)