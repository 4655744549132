import {
    GET_ADDRESSES_START,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_FAIL
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    modalShowed: false,
    list: []
};

const getAddressesStart = (state) => {
    return ({
        ...state,
        loading: true,
        list: []
    })
}

const getAddressesSuccess = (state, { list }) => {
    return ({
        ...state,
        loading: false,
        list: [...list]
    })
}

const getAddressesFail = (state, { error }) => {
    return ({
        ...state,
        loading: false,
        list: [],
        error: error
    })
}

export const getDefaultPickupAddress = state => {

    return state.addresses.list.filter(address => address.isDefaultPickUp === true)[0];
}

export const getDefaultDeliveryAddress = state => {

    return state.addresses.list.filter(address => address.isDefaulDelivery === true)[0];
}

export const getBillingAddress = state => {

    return state.addresses.list.filter(address => address.isDefaultBilling === true)[0];
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ADDRESSES_FAIL:
            return getAddressesFail(state, action)
        case GET_ADDRESSES_SUCCESS:
            return getAddressesSuccess(state, action)
        case GET_ADDRESSES_START:
            return getAddressesStart(state, action)
        default:
            return state;
    }
};