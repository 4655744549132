import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faMinus
} from '@fortawesome/free-solid-svg-icons';

const QuantityField = ({ quantity, add, remove, lg, quantityType }) =>
    (
        <div style={!lg ? { display: "flex" } : {}}>
            <span className="quantity-label">{quantityType === "m2" ? "m2" : "Menge"}</span>
            <div className="quantity-control">
                <button className="quantity-button ripple" disabled={quantity === 1} onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    remove()
                }}>
                    <FontAwesomeIcon size="sm" icon={faMinus} />
                </button>
                <span className="quantity-number">{quantity}</span>
                <button className="quantity-button ripple" onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    add()
                }}>
                    <FontAwesomeIcon size="sm" icon={faPlus} />
                </button>
            </div>
        </div>
    )

export default QuantityField