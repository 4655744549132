import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import Address from './Address'


class AddressModal extends React.Component {

  render() {

    const { modalShowed, modalToggle, address } = this.props

    return (
      <Modal isOpen={modalShowed} toggle={modalToggle} centered size="lg" modalClassName="address-modal">
        <ModalHeader toggle={modalToggle}>Adresse bearbeiten</ModalHeader>
        <ModalBody>
          <div style={{ padding: "20px" }}>
            <Address data={address} modalToggle={modalToggle}/>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}


export default AddressModal