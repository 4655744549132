import {
    GET_ADDRESSES,
    GET_ADDRESSES_START,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_FAIL,
    ADDRESS_SUBMIT,
    ADDRESS_DELETE
} from "./actionTypes";


export const getAddresses = (token) => ({
    type: GET_ADDRESSES,
    token
});

export const getAddressesStart = () => ({
    type: GET_ADDRESSES_START
})

export const getAddressesSuccess = (list) => ({
    type: GET_ADDRESSES_SUCCESS,
    list
})

export const getAddressesFail = (error) => ({
    type: GET_ADDRESSES_FAIL,
    error
})

export const addressSubmit = (values, token, resolve, reject) => ({
    type: ADDRESS_SUBMIT,
    values,
    token,
    resolve,
    reject
})

export const deleteAddress = (id, token) => ({
    type: ADDRESS_DELETE,
    id,
    token
})