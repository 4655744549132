import React, { Component } from 'react';
import ReactTable from "react-table";
import { Link } from 'react-router-dom'
import "react-table/react-table.css";
import { connect } from 'react-redux';
import * as actions from '../../store/actions'
import Moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { Portal } from 'react-overlays'
import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap'
import _ from 'lodash'
import { CSVLink } from "react-csv";


const CalendarContainer = ({ children }) => {
    const el = document.getElementsByClassName('table-container');
    return (
        <Portal container={el[0]}>
            {children}
        </Portal>
    )
};



class UsersGrid extends Component {

    gridState
    reactTable

    columns = [
        {
            Header: 'User ID',
            accessor: '_id',
            sortable: false,
            Cell: ({ row }) => (<Link to={{ pathname: `/admin/users/${row._id}` }}>{row._id}</Link>)
        },
        {
            Header: "Email",
            id: 'email',
            accessor: d => `${d.email}`
        },
        {
            Header: "First Name",
            id: 'firstName',
            accessor: d => `${d.firstName}`
        },
        {
            Header: "Last Name",
            id: 'lastName',
            accessor: d => `${d.lastName}`
        },
        {
            Header: "Company",
            id: 'company',
            accessor: d => `${d.company || ""}`
        },
        {
            Header: "Gender",
            id: 'gender',
            accessor: d => `${d.gender === '1' ? "Male" : "Female"}`,
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "Created"}
                >
                    <option value="">Alles</option>
                    <option value="1">{"Male"}</option>
                    <option value="2">{"Female"}</option>
                </select>
        },
        {
            Header: "Registered Date",
            id: 'dateCreated',
            accessor: d => {
                return new Moment(d.dateCreated)
                    .local()
                    .format("DD-MM-YYYY HH:mm")
            },
            Filter: ({ filter, onChange }) =>
                <DatePicker
                    selected={filter ? filter.value : undefined}
                    onChange={event => {
                        onChange(event)
                    }}
                    popperContainer={CalendarContainer}
                    isClearable={true}
                />
        },
        {
            Header: "Number of orders",
            id: 'numOfOrders',
            accessor: d => `${d.numOfOrders}`
        },
        {
            Header: "Admin",
            id: 'isAdmin',
            accessor: d => d.isAdmin || false,
            Cell: ({ original }) => {
                return (
                    <div className="checkbox">
                        <input type="checkbox"
                            id={`isAdmin${original._id}`}
                            checked={original.isAdmin || false}
                            onChange={() => { }}
                            readOnly
                        />
                        <label htmlFor={`isAdmin${original._id}`}></label>
                    </div>
                )
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "Vip"}
                >
                    <option value="">Alles</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
        },
        {
            Header: "Vip",
            id: 'isVip',
            accessor: d => d.isVip || false,
            Cell: ({ original }) => {
                return (
                    <div className="checkbox">
                        <input type="checkbox"
                            id={`isVip${original._id}`}
                            checked={original.isVip || false}
                            onChange={() => { }}
                            onClick={() => this.changeVip(original)}
                            readOnly
                        />
                        <label htmlFor={`isVip${original._id}`}></label>
                    </div>
                )
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "Vip"}
                >
                    <option value="">Alles</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
        }
    ]

    state = {
        modalShowed: false,
        userName: "",
        isVip: false,
        loading: false,
        userId: "",
        error: "",
        dataToDownload: []
    }

    componentDidMount() {
        const { fetchUsers, token, dataState } = this.props
        fetchUsers(token, dataState);
    }

    modalToggle = () => {
        this.setState(prevState => ({ modalShowed: !prevState.modalShowed }))
    }

    changeVip = (user) => {
        this.setState({
            modalShowed: true,
            userName: `${user.firstName} ${user.lastName}`,
            isVip: user.isVip === true,
            userId: user._id,
            error: ""
        })
    }

    confirmClick = async () => {
        const { token } = this.props
        const { isVip, userId } = this.state
        try {
            this.setState({ loading: true })
            await fetch('/api/users/admin', {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "de-DE",
                    "x-auth-token": token
                },
                body: JSON.stringify({ userId: userId, isVip: !isVip })
            })
            this.setState({ loading: false, modalShowed: false })
            this.fetchData(this.gridState, null)

        } catch (error) {
            this.setState({ loading: false, error: error })
        }
    }


    fetchData = (state, instance) => {
        const { fetchUsers, token } = this.props
        fetchUsers(token, {
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
            filtered: state.filtered
        });
        this.gridState = _.cloneDeep(state)
    }

    download = async (event) => {
        const { token } = this.props
        try {
            const response = await fetch('/api/users/admin', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": "de-DE",
                    "x-auth-token": token
                },
                body: JSON.stringify({
                    pageSize: 10000,
                    page: 0,
                    sorted: [{ id: "numOfOrders", desc: false }],
                    filtered: []
                }),
            });
            const users = await response.json();
            const currentRecords = users.users;
            let data_to_download = []
            for (let index = 0; index < currentRecords.length; index++) {
                let record_to_download = {}
                record_to_download["_id"] = currentRecords[index]["_id"]
                record_to_download["First Name"] = currentRecords[index]["firstName"]
                record_to_download["Last Name"] = currentRecords[index]["lastName"]
                record_to_download["Email"] = currentRecords[index]["email"]
                record_to_download["Gender"] = currentRecords[index]["gender"] === "1" ? "Male" : "Female"
                record_to_download["Company"] = currentRecords[index]["company"]
                record_to_download["Admin"] = currentRecords[index]["isAdmin"]
                record_to_download["Vip"] = currentRecords[index]["isVip"]
                record_to_download["Pick-Up Name"] = currentRecords[index]["pickUpAddress"][0]["name"]
                record_to_download["Pick-Up City"] = currentRecords[index]["pickUpAddress"][0]["city"]
                record_to_download["Pick-Up Street"] = currentRecords[index]["pickUpAddress"][0]["street"]
                record_to_download["Pick-Up Phone"] = currentRecords[index]["pickUpAddress"][0]["phone"]
                record_to_download["Pick-Up Note"] = currentRecords[index]["pickUpAddress"][0]["note"]
                record_to_download["Delivery Name"] = currentRecords[index]["deliveryAddress"][0]["name"]
                record_to_download["Delivery City"] = currentRecords[index]["deliveryAddress"][0]["city"]
                record_to_download["Delivery Street"] = currentRecords[index]["deliveryAddress"][0]["street"]
                record_to_download["Delivery Phone"] = currentRecords[index]["deliveryAddress"][0]["phone"]
                record_to_download["Delivery Note"] = currentRecords[index]["deliveryAddress"][0]["note"]
                record_to_download["Number of paid orders"] = currentRecords[index]["numOfOrders"]
                data_to_download.push(record_to_download)
            }
            this.setState({ dataToDownload: data_to_download }, () => {
                // click the CSVLink component to trigger the CSV download
                this.csvLink.link.click()
            })

        } catch (e) {
            return;
        }
    }

    render() {

        const { data, pages, loading } = this.props;
        const { modalShowed, userName, isVip, dataToDownload } = this.state

        return (
            <div style={{ minHeight: "calc(100vh - 65px)" }}>
                <Modal isOpen={modalShowed} toggle={this.modalToggle} centered size="sm">
                    <ModalHeader toggle={this.modalToggle}>Change VIP Status</ModalHeader>
                    <ModalBody>
                        <div style={{ padding: "20px" }}>
                            {isVip ? `Cancel ${userName} VIP status?` : `Make ${userName} VIP status?`}
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: "center" }}>
                        <Button color="primary" style={{ width: "100px" }} onClick={this.confirmClick}>OK</Button>{' '}
                        <Button color="secondary" style={{ width: "100px" }} onClick={this.modalToggle}>CANCEL</Button>
                    </ModalFooter>
                </Modal>
                <Button color="primary" onClick={this.download}>Download CSV</Button>
                <CSVLink
                    separator={';'}
                    data={dataToDownload}
                    filename="users.csv"
                    className="hidden"
                    ref={(r) => this.csvLink = r}
                    target="_blank" />
                <div className="table-container">
                    <ReactTable
                        ref={instance => { this.reactTable = instance }}
                        columns={this.columns}
                        defaultSorted={[
                            {
                                id: "dateCreated",
                                desc: true
                            }]}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={data}
                        pages={pages} // Display the total number of pages
                        loading={loading} // Display the loading overlay when we need it
                        onFetchData={this.fetchData} // Request new data when things change
                        filterable
                        defaultPageSize={20}
                        className="-striped -highlight"
                    />
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchUsers: (token, dataState) => dispatch(actions.getAdminUsers(token, dataState))
    };
};

const mapStateToProps = state => ({
    dataState: state.users.dataState,
    pages: state.users.pages,
    loading: state.users.loading,
    data: state.users.list,
    token: state.auth.token
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersGrid);