import { put, call } from "redux-saga/effects";

import * as actions from "../actions";

export function* GetBonuses({ token }) {

    yield put(actions.getBonusesStart());

    try {
        const response = yield call(fetch, '/api/giftcards', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            }
        });

        const response1 = yield call(fetch, '/api/welcomebonus', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": "de-DE",
                "x-auth-token": token
            }
        });

        const welcomeBonus = yield response1.json();

        const giftcards = yield response.json();

        yield put(actions.getBonusesSuccess([
            ...welcomeBonus.map(t => ({ ...t, type: "welcomebonus" })),
            ...giftcards.map(t => ({ ...t, type: "giftcard" }))
        ]));

    } catch (e) {
        yield put(actions.getBonusesFail(e));
        return;
    }

}
