import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions'
import ProductLoader from '../products/ProductLoader';
import ReactTable from "react-table";
import { Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

class UserDetails extends Component {

    componentDidMount() {
        const { id } = this.props.match.params
        const { token, fetchUser } = this.props
        fetchUser(token, id)
    }

    render() {

        const { loading, user } = this.props

        if (loading || !user) {
            return (<ProductLoader />)
        }

        return (
            <div style={{ minHeight: "calc(100vh - 65px)", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }}>
                <Button color="link" style={{ fontSize: "16px", fontWeight: "600" }} onClick={() => this.props.history.push('/admin/users')}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span className="ml-2">Back to users list</span>
                </Button>
                <Row><Col><h5>User details</h5></Col></Row>
                <Row>
                    <Col lg="6">
                        <Row><Col xs="4">First Name:</Col><Col xs="8">{user.user.firstName}</Col></Row>
                        <Row><Col xs="4">Last Name:</Col><Col xs="8">{user.user.lastName}</Col></Row>
                        <Row><Col xs="4">Email:</Col><Col xs="8">{user.user.email}</Col></Row>
                        <Row><Col xs="4">Company:</Col><Col xs="8">{user.user.company}</Col></Row>
                        <Row><Col xs="4">Gender:</Col><Col xs="8">{user.user.gender === "1" ? "Male" : "Female"}</Col></Row>
                    </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }}><Col><h5>Addresses</h5></Col></Row>
                <Row>
                    <Col>
                        <ReactTable
                            columns={[
                                {
                                    Header: "Ort der Abholung",
                                    id: 'locationName',
                                    accessor: d => `${d.locationName}`,
                                    sortable: false
                                },
                                {
                                    Header: "PLZ/Ort",
                                    id: 'city: ',
                                    accessor: d => `${d.city}`,
                                    sortable: false
                                },
                                {
                                    Header: "Strasse und Hausnummer",
                                    id: 'street',
                                    accessor: d => `${d.street}`,
                                    sortable: false
                                },
                                {
                                    Header: "Telefon",
                                    id: 'phone',
                                    accessor: d => `${d.phone}`,
                                    sortable: false
                                },
                                {
                                    Header: "Note",
                                    id: 'note',
                                    accessor: d => `${d.note}`,
                                    sortable: false
                                },
                                {
                                    Header: "Standard-Rechnungsadresse",
                                    id: 'isDefaultBilling',
                                    accessor: d => d.isDefaultBilling || false,
                                    Cell: ({ original }) => {
                                        return (
                                            <div className="checkbox">
                                                <input type="checkbox"
                                                    id={`isDefaultBilling${original._id}`}
                                                    checked={original.isDefaultBilling || false}
                                                    onChange={() => { }}
                                                    readOnly
                                                />
                                                <label htmlFor={`isDefaultBilling${original._id}`}></label>
                                            </div>
                                        )
                                    },
                                    sortable: false
                                },
                                {
                                    Header: "Standard-Abholadresse",
                                    id: 'isDefaultPickUp',
                                    accessor: d => d.isDefaultPickUp || false,
                                    Cell: ({ original }) => {
                                        return (
                                            <div className="checkbox">
                                                <input type="checkbox"
                                                    id={`isDefaultPickUp${original._id}`}
                                                    checked={original.isDefaultPickUp || false}
                                                    onChange={() => { }}
                                                    readOnly
                                                />
                                                <label htmlFor={`isDefaultPickUp${original._id}`}></label>
                                            </div>
                                        )
                                    },
                                    sortable: false
                                },
                                {
                                    Header: "Lieferadresse",
                                    id: 'isDefaulDelivery',
                                    accessor: d => d.isDefaulDelivery || false,
                                    Cell: ({ original }) => {
                                        return (
                                            <div className="checkbox">
                                                <input type="checkbox"
                                                    id={`isDefaulDelivery${original._id}`}
                                                    checked={original.isDefaulDelivery || false}
                                                    onChange={() => { }}
                                                    readOnly
                                                />
                                                <label htmlFor={`isDefaulDelivery${original._id}`}></label>
                                            </div>
                                        )
                                    },
                                    sortable: false
                                }
                            ]}
                            data={user.addresses}
                            defaultPageSize={10}
                            className="-striped -highlight"
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUser: (token, id) => dispatch(actions.getUserDetails(token, id))
    };
};

const mapStateToProps = state => ({
    user: state.users.user,
    loading: state.users.loading,
    token: state.auth.token
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails));