import { applyMiddleware, compose, createStore } from 'redux';
import { sagaMiddleware } from './sagaMiddleware';
import createRootReducer from './store/reducers';
import { watchAuth, watchShoppingCart, watchProducts, watchAddresses, watchOrders, watchUsers, watchBonuses } from './store/sagas';
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

//const composeEnhancers = compose;

const composeEnhancers =
   process.env.NODE_ENV === 'development'
     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
     : null || compose;

export const history = createBrowserHistory()

const middlewares = [routerMiddleware(history), sagaMiddleware]

const store = createStore(
  createRootReducer(history), // root reducer with router state
  composeEnhancers(
    applyMiddleware(...middlewares),
  ),
)

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchShoppingCart);
sagaMiddleware.run(watchProducts)
sagaMiddleware.run(watchAddresses)
sagaMiddleware.run(watchBonuses)
sagaMiddleware.run(watchOrders)
sagaMiddleware.run(watchUsers)

export default store;