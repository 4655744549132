import { push } from "connected-react-router";
import { put, call } from "redux-saga/effects";
import axios from "axios";

import * as actions from "../actions";

export function* AddToShoppingCartAndGoToCart({ product }) {
  yield put(actions.AddToShoppingCart(product));

  yield put(push("/shopping-cart/page1"));
}

export function* RemoveFromShoppingCartAndBackToProducts({
  productIndex,
  itemsCount
}) {
  yield put(actions.removeProductFromShoppongCart(productIndex));

  if (itemsCount === 1) yield put(push("/products"));
}

export function* createPayEngineOrderIntent(action) {
  try {
    const callback = (error, result) => {
      if (error) {
        //Handle error here
        return;
      }
      //Handle transaction successfully created
    };
    axios.defaults.headers.common["Authorization"] =
      "Basic TWVyY2hhbnQtMDAwY2YyYzktNjZmZS00NzMwLWFjOWUtNjc0ZjdjNTUyZDQyOldzQUdBd0dxamFnVmgwNEw=";
    axios.defaults.headers.post["Content-Type"] = "application/json";
    yield axios
      .post(
        "https://apitest.payengine.de/v1/orders",
        JSON.stringify(action.payload)
      )
      .then(function (response) {
        console.log(response);

        const publishableKey = response.data.merchantId;
        const orderId = response.data.orderId;
        const optionalParameters = {};
        optionalParameters.products = [
          "creditcard",
          "paypal",
          "postfinance"
        ];
        optionalParameters.language = "en";
        optionalParameters.redirectUser = true;
        optionalParameters.theme = "light";

        window.PayEngineWidget.initAsModal(
          publishableKey,
          orderId,
          optionalParameters,
          callback
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
  }
}


export function* applyCode({ code, token, resolve, reject }) {
  try {
    var response = yield call(fetch, `/api/users/checkcode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "de-DE",
        "x-auth-token": token
      },
      body: JSON.stringify({ code: code })
    });

    const result = yield response.json()

    if (!result.valid) {
      yield call(reject, result.message)
    } else {
      yield put(actions.applyCodeSuccess(code, result.codeType, result.value));

      yield call(resolve)
    }

  } catch (e) {
    yield call(reject, e)
  }
}
