import { put, delay, call } from "redux-saga/effects";
import { push } from 'connected-react-router'

import * as actions from "../actions";

export function* checkAuthTimeoutSaga(action) {
    yield delay(action.expirationTime * 1000);
    yield put(actions.logout());
}

export function* logoutSaga() {
    yield call([localStorage, "removeItem"], "token");
    //yield call([localStorage, "removeItem"], "expirationDate");
    yield call([localStorage, "removeItem"], "userId");
    yield call([localStorage, "removeItem"], "userName")
    yield put(actions.logoutSucceed());
}

export function* authCheckStateSaga({ pathname }) {

    //TODO do not store user name in localStorage, get it with method GET /api/users/me
    const token = yield localStorage.getItem("token");
    const userId = yield localStorage.getItem("userId");
    const userName = yield localStorage.getItem("userName");
    if (token && userId && userName) {
        try {
            const response = yield call(fetch, '/api/users/me', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": token
                },
            });
            const user = yield response.json();
            if (userId === user._id) {
                yield put(actions.authSuccess(token, userId, userName, user.isAdmin, user.isVip));
                yield localStorage.setItem("userName", `${user.firstName} ${user.lastName}`);
            } else {
                yield put(actions.logout());
                if (pathname.startsWith("/admin") || pathname.startsWith("/profile") || pathname.startsWith("/shopping-cart/page2")) {
                    yield put(actions.authModalToggle())
                }
            }

        } catch (error) {
            yield put(actions.logout());
            if (pathname.startsWith("/admin") || pathname.startsWith("/profile") || pathname.startsWith("/shopping-cart/page2")) {
                yield put(actions.authModalToggle())
            }
        }
    } else {
        yield put(actions.logout());
        if (pathname.startsWith("/admin") || pathname.startsWith("/profile") || pathname.startsWith("/shopping-cart/page2")) {
            yield put(actions.authModalToggle())
        }
    }
}

export function* authUserSaga({ email, password, resolve, reject }) {
    yield put(actions.authStart());

    try {
        const response = yield call(fetch, '/api/users/login', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password })
        });
        const user = yield response.json();

        if (user.error) {
            yield put(actions.authFail(user.error));
            yield call(reject, user.error)

        } else {
            yield put(actions.authSuccess(response.headers.get("x-auth-token"), user._id, `${user.firstName} ${user.lastName}`, user.isAdmin, user.isVip));

            yield localStorage.setItem("token", response.headers.get("x-auth-token"));
            //yield localStorage.setItem("expirationDate", expirationDate);
            yield localStorage.setItem("userId", user._id);
            yield localStorage.setItem("userName", `${user.firstName} ${user.lastName}`);

            yield call(resolve)

            yield put(actions.authModalToggle())
        }
    } catch (e) {
        yield put(actions.authFail(e));
        yield call(reject, e)
    }
}

export function* setNewPasswordSaga({ token, password, resolve, reject }) {
    try {
        const response = yield call(fetch, '/api/users/reset-password', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token, password })
        });
        const user = yield response.json();

        if (user.error) {
            yield call(reject, user.error)

        } else {
            yield put(actions.authSuccess(response.headers.get("x-auth-token"), user._id, `${user.firstName} ${user.lastName}`, user.isAdmin, user.isVip));

            yield localStorage.setItem("token", response.headers.get("x-auth-token"));
            //yield localStorage.setItem("expirationDate", expirationDate);
            yield localStorage.setItem("userId", user._id);
            yield localStorage.setItem("userName", `${user.firstName} ${user.lastName}`);

            yield call(resolve)

            yield put(actions.newPasswordModalToggle())
        }
    } catch (e) {
        yield put(actions.authFail(e));
        yield call(reject, e)
    }
}

export function* registerUserSaga({ values, resolve, reject }) {
    try {
        const response = yield call(fetch, '/api/users', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        });
        const user = yield response.json();

        if (user.error) {
            yield call(reject, user.error)

        } else {
            yield put(actions.authSuccess(response.headers.get("x-auth-token"), user._id, `${user.firstName} ${user.lastName}`, user.isAdmin, user.isVip));

            yield localStorage.setItem("token", response.headers.get("x-auth-token"));
            //yield localStorage.setItem("expirationDate", expirationDate);
            yield localStorage.setItem("userId", user._id);
            yield localStorage.setItem("userName", `${user.firstName} ${user.lastName}`);

            yield call(resolve)

            yield put(actions.authModalToggle())
        }
    } catch (e) {
        yield call(reject, e)
    }
}

export function* discamerClickSaga() {
    yield put(actions.authModalToggle())

    yield put(push('/agb'))
}

export function* routingChangeSaga(action) {

    const pathArray = action.payload.location.pathname.split("/")

    if (pathArray[1] === "reset-password" && pathArray[2]) {

        yield put(push('/'))
        yield put(actions.newPasswordModalToggle(pathArray[2]))
    }
}

export function* sendPasswordResetSaga({ email, resolve, reject }) {
    try {
        const response = yield call(fetch, `/api/users/request-reset-password/${email}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });

        if (response.status === 400) {
            let error = yield response.json()
            yield call(reject, error)
        } else {
            yield call(resolve)
            yield put(actions.resetPasswordModalToggle())
        }

    } catch (e) {
        yield call(reject, e)
    }
}


export function* userUpdatedSaga({ values, token }) {
    yield put(actions.authSuccess(token, values._id, `${values.firstName} ${values.lastName}`));

    yield localStorage.setItem("token", token);
    //yield localStorage.setItem("expirationDate", expirationDate);
    yield localStorage.setItem("userId", values._id);
    yield localStorage.setItem("userName", `${values.firstName} ${values.lastName}`);
}