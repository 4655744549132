import { TOGGLE_SIDEBAR_MODAL } from '../actions/actionTypes';

const initialState = {
    sideBarModal: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR_MODAL: {
            const newState = {...state};
            newState.sideBarModal = !newState.sideBarModal;
            return newState;
        }
        default:
            return state;
    }
};