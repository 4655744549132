import React, { Component } from "react";

import Layout from './components/Layout';

import store, { history } from './store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const THEME = createMuiTheme({
  typography: {
    useNextVariants: true,
    "fontFamily": "Brendon-Grotseque-regular",
  },
  palette: {
    primary: {
      main: "#b0c8db",
    },
    secondary: {
      main: "#2a4a9a"
    }
    // secondary: {
    //   light: palette.secondary.A200,
    //   main: palette.secondary.A400,
    //   dark: palette.secondary.A700,
    //   contrastText: getContrastText(palette.secondary.A400),
    // },
    // error: {
    //   light: palette.error[300],
    //   main: palette.error[500],
    //   dark: palette.error[700],
    //   contrastText: getContrastText(palette.error[500]),
    // },
  },
});


class App extends Component {

  render() {
    return (
      <MuiThemeProvider theme={THEME}>
        <Provider store={store}>
          <div className="App">
            <ConnectedRouter history={history}>
              <Layout />
            </ConnectedRouter>
          </div>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;




// TODO, add a 404 page not found.