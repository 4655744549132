import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'

export default ({
    noLaundrySelected,
    noLaundryPrice,
    washingAndIroningSelected,
    washingAndIroningPrice,
    washingAndIroningSpeedySelected,
    washingAndIroningSpeedyPrice,
    withSpotCleaningSelected,
    withSpotCleaningPrice,
    cleaningAndRefreshing
}) => {

    return (
        <ListGroup flush className="shopping-item-section">
            <ListGroupItem className="item-main-header">
                <strong>Wäscherei</strong>
            </ListGroupItem>
            {
                noLaundrySelected &&
                <ListGroupItem className="item-button" active={noLaundrySelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">Keine Wäscherei</div>
                        {
                            noLaundryPrice === 0 &&
                            <div className="ml-auto" style={{ textTransform: "none" }}>Gratis</div>
                        }
                        {
                            noLaundryPrice > 0 &&
                            <div className="small-price">
                                {formatNumber(noLaundryPrice)}
                            </div>
                        }
                    </div>
                </ListGroupItem>
            }
            {
                washingAndIroningSelected &&
                <ListGroupItem
                    className="item-button"
                    active={washingAndIroningSelected}
                >
                    <div className="d-flex item-header-font">
                        <div className="item-header">{cleaningAndRefreshing ? "REINIGEN UND AUFFRISCHEN" : "Waschen & Bügeln"}</div>
                        <div className="small-price">
                            {formatNumber(washingAndIroningPrice)}
                        </div>
                    </div>
                    {
                        withSpotCleaningSelected &&
                        <div className="item-checkbox">
                            <div className="d-flex item-header-font">
                                <div className="checkbox">
                                    <input type="checkbox"
                                        id={`withSpotCleaningCheck`}
                                        checked={!!withSpotCleaningSelected}
                                        onChange={() => { }}
                                        readOnly
                                    />
                                    <label htmlFor={`withSpotCleaningCheck`}></label>
                                </div>
                                <div style={{ textTransform: "none" }}>Mit Fleckenreinigung</div>
                                {
                                    withSpotCleaningPrice === 0 &&
                                    <div className="ml-auto" style={{ textTransform: "none" }}>Gratis</div>
                                }
                                {
                                    withSpotCleaningPrice > 0 &&
                                    <div className="small-price">
                                        {formatNumber(withSpotCleaningPrice)}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </ListGroupItem>
            }
            {
                washingAndIroningSpeedySelected &&
                <ListGroupItem action className="item-button" active={washingAndIroningSpeedySelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">Waschen & Bügeln Speedy</div>
                        <div className="small-price">
                            <span>{formatNumber(washingAndIroningSpeedyPrice + washingAndIroningPrice)}</span>
                        </div>
                    </div>
                </ListGroupItem>
            }
        </ListGroup>
    )
}