import React, { Component } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { connect } from 'react-redux'
import { formatNumber } from '../../Utils'

import * as actions from '../../store/actions'

class AdditionalItem extends Component {

    render() {

        const
            {
                decoration,
                decorationSelected,
                decorationPrice,
                plated,
                platedSelected,
                platedPrice,
                sequined,
                sequinedSelected,
                sequinedPrice,
                OnAdditionalCheck,
                itemIndex
            } = this.props


        return (
            <ListGroup flush className="shopping-item-section">
                <ListGroupItem className="item-main-header">
                    <strong>Zuschlag</strong>
                </ListGroupItem>
                {
                    decoration &&
                    <ListGroupItem className="item-button" active={decorationSelected}
                        onClick={() => OnAdditionalCheck(itemIndex, "decorationSelected")}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">mit Deko</div>
                            <div className="small-price">
                                {formatNumber(decorationPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
                {
                    plated &&
                    <ListGroupItem className="item-button" active={platedSelected}
                        onClick={() => OnAdditionalCheck(itemIndex, "platedSelected")}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">mit Plissée</div>
                            <div className="small-price">
                                {formatNumber(platedPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
                {
                    sequined &&
                    <ListGroupItem className="item-button" active={sequinedSelected}
                        onClick={() => OnAdditionalCheck(itemIndex, "sequinedSelected")}>
                        <div className="d-flex">
                            <div className="item-header">mit Strass, Pailetten</div>
                            <div className="small-price">
                                {formatNumber(sequinedPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
            </ListGroup>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnAdditionalCheck: (itemIndex, checkType) => dispatch(actions.additionalCheck(itemIndex, checkType))
    };
};

export default connect(null, mapDispatchToProps)(AdditionalItem)