import * as actionTypes from './actionTypes';

export const confirmPickUpLocation = (value, isVip) => ({
    type: actionTypes.CONFIRM_PICKUP_LOCATION,
    value,
    isVip
});

export const confirmDeliveryLocation = (value, isVip) => ({
    type: actionTypes.CONFIRM_DELIVERY_LOCATION,
    value,
    isVip
})

export const AddToShoppingCart = (product) => ({
    type: actionTypes.ADD_TO_SHOPPING_CART,
    product
});

export const AddToShoppingCartAndGoToCart = (product) => ({
    type: actionTypes.ADD_TO_SHOPPING_CART_AND_GO_TO_CART,
    product
})

export const removeProductFromShoppongCart = (productIndex) => ({
    type: actionTypes.REMOVE_FROM_SHOPPING_CART,
    productIndex
})

export const removeProductFromShoppongCartAndBackToProducts = (productIndex, itemsCount) => ({
    type: actionTypes.REMOVE_FROM_SHOPPING_CART_AND_BACK_TO_PRODUCTS,
    productIndex,
    itemsCount
})

export const noLaundryCheck = (itemIndex) => ({
    type: actionTypes.NO_LAUNDRY_CHECK,
    itemIndex
})

export const washingAndIroningCheck = (itemIndex) => ({
    type: actionTypes.WASHING_AND_IRONING_CHECK,
    itemIndex
})

export const withSpotCleaningCheck = (itemIndex) => ({
    type: actionTypes.WITH_SPOT_CLEANING_CHECK,
    itemIndex
})

export const washingAndIroningSpeedyCheck = (itemIndex) => ({
    type: actionTypes.WASHING_AND_IRONING_SPEEDY_CHECK,
    itemIndex
})

export const buttonCheck = (itemIndex) => ({
    type: actionTypes.BUTTON_CHECK,
    itemIndex
})

export const hemCheck = (itemIndex) => ({
    type: actionTypes.HEM_CHECK,
    itemIndex
})

export const zipperCheck = (itemIndex) => ({
    type: actionTypes.ZIPPER_CHECK,
    itemIndex
})

export const quantityAdd = (itemIndex, itemType) => ({
    type: actionTypes.QUANTITY_ADD,
    itemIndex,
    itemType
})

export const quantityRemove = (itemIndex, itemType) => ({
    type: actionTypes.QUANTITY_REMOVE,
    itemIndex,
    itemType
})

export const notesChange = (itemIndex, text) => ({
    type: actionTypes.NOTES_CHANGE,
    itemIndex,
    text
})

export const deliveryCheck = (itemIndex) => ({
    type: actionTypes.DELIVERY_CHECK,
    itemIndex
})

export const leatherTypeCheck = (itemIndex) => ({
    type: actionTypes.LEATHER_TYPE_CHECK,
    itemIndex
})

export const additionalCheck = (itemIndex, checkType) => ({
    type: actionTypes.ADDITIONAL_CHECK,
    itemIndex,
    checkType
})

export const removeAllProductsFromShoppingCart = () => ({
    type: actionTypes.REMOVE_ALL_PRODUCTS_FROM_SHOPPING_CART
})

export const createPayEngineOrderIntent = payload => ({
    type: actionTypes.CREATE_PAYENGINE_ORDER_INTENT,
    payload
});


export const applyCode = (code, token, resolve, reject) => ({
    type: actionTypes.APPLY_CODE,
    code,
    token,
    resolve,
    reject
})

export const applyCodeSuccess = (code, codeType, value) => ({
    type: actionTypes.APPLY_CODE_SUCCESS,
    code,
    codeType,
    value
})

export const deleteCode = () => ({
    type: actionTypes.DELETE_CODE,
})

export const confirmWithoutAddress = (addressType) => ({
    type: actionTypes.CONFIRM_WITHOUT_ADDRESS,
    addressType
})

export const changeNumberOfDeliveries = (number) => ({
    type: actionTypes.CHANGE_NUMBER_OF_DELIVERIES,
    number
})

export const giftCartItemSubmit = (values, itemIndex) => ({
    type: actionTypes.GIFT_CARD_ITEM_SUBMIT,
    values,
    itemIndex
})