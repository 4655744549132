import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';

import AuthWrapper from './AuthWrapper'
import * as actions from '../../store/actions'


class AuthModal extends React.Component {

  render() {

    const { authShowed, OnModalToggle } = this.props

    return (
      <Modal isOpen={authShowed} toggle={() => OnModalToggle()} centered size="xl" modalClassName="register-modal" className="modal-dialog-scrollable">
        <ModalHeader toggle={() => OnModalToggle()}>Anmelden oder Registrieren</ModalHeader>
        <ModalBody>
          <AuthWrapper />
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authShowed: state.auth.authShowed
  };
};

const mapDispatchToProps = dispatch => {
  return {
    OnModalToggle: () => dispatch(actions.authModalToggle())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal)