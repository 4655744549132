import * as actionTypes from '../actions/actionTypes'

const initialState = {
    loading: false,
    categories: [
        { "_id": "0", "name": "Alle" },
        { "_id": "5c98b94fd2f2fd13b47044a5", "name": "Bekleidung" },
        { "_id": "5c98b950d2f2fd13b47044a6", "name": "Outdoor / Sport" },
        { "_id": "5c98b950d2f2fd13b47044a7", "name": "Festkleidung" },
        { "_id": "5c98b950d2f2fd13b47044a8", "name": "Accessoires" },
        { "_id": "5c98b950d2f2fd13b47044a9", "name": "Heimtextilien" },
        { "_id": "5c98b951d2f2fd13b47044aa", "name": "Leder" },
        { "_id": "5c98b951d2f2fd13b47044ab", "name": "Vorhangreinigung" },
        { "_id": "5c98b951d2f2fd13b47044ac", "name": "Haustiere" },
        { "_id": "5c98b951d2f2fd13b47044ad", "name": "Berufsbekleidung" },
        { "_id": "5c98b952d2f2fd13b47044ae", "name": "Kinderbekleidung 0-10 Jahre" },
        { "_id": "5e3ff9a54a23e834dc60b58e", "name": "Gift Card" }
    ],
    loaded: true,
    selectedCategory: ""
}

const selectCategory = (state, { categories, categoryId, selectedCategory }) => {
    const category = categories.find(t => t._id === categoryId)
    const categoryName = category ? category.name : ""
    if (selectedCategory !== categoryName)
        return ({
            ...state,
            selectedCategory: category ? (category.name === "Alle" ? "" : category.name) : "",
            categories: [...state.categories]
        })

    return ({
        ...state,
        categories: [...state.categories]
    })
}


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_CATEGORY:
            return selectCategory(state, action)
        default:
            return state;
    }
};