import * as actionTypes from './actionTypes';

export const submitOrder = (payload) => ({
    type: actionTypes.SUBMIT_ORDER,
    payload
})

export const submitOrderAndGoToDetails = (payload) => ({
    type: actionTypes.SUBMIT_ORDER_GO_TO_DETAILS,
    payload
})

export const getOrders = (token, dataState) => ({
    type: actionTypes.GET_ORDERS,
    token,
    dataState
})

export const getOrdersStart = () => ({
    type: actionTypes.GET_ORDERS_START,
})

export const getOrdersFail = (error) => ({
    type: actionTypes.GET_ORDERS_FAIL,
    error
})

export const getOrdersSuccess = (list, dataState, pages) => ({
    type: actionTypes.GET_ORDERS_SUCCESS,
    list,
    dataState,
    pages
})

export const getAdminOrders = (token, dataState) => ({
    type: actionTypes.GET_ADMIN_ORDERS,
    token,
    dataState
})

export const getOrderDetails = (token, id) => ({
    type: actionTypes.GET_ORDER_DETAILS,
    token,
    id
})

export const getOrderDetailsSuccess = (order) => ({
    type: actionTypes.GET_ORDER_DETAILS_SUCCESS,
    order
})

export const getOrderDetailsFail = (error) => ({
    type: actionTypes.GET_ORDER_DETAILS_FAIL,
    error
})

export const orderStatusChange = (token, id, newStatus) => ({
    type: actionTypes.ORDER_STATUS_CHANGE,
    token,
    id,
    newStatus
})

export const orderStatusChangeStart = () => ({
    type: actionTypes.ORDER_STATUS_CHANGE_START,
})

export const orderStatusChangeSuccess = (newStatus) => ({
    type: actionTypes.ORDER_STATUS_CHANGE_SUCCESS,
    newStatus
})

export const orderStatusChangeFail = (error) => ({
    type: actionTypes.ORDER_STATUS_CHANGE_FAIL,
    error
})