import React, { Component } from 'react'
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import AddressBox from './AddressBox';

class CheckoutPageStepBox extends Component {

    render() {

        const { address,
            icon,
            headerText,
            needsConfirmation,
            confirmed,
            confirm,
            editVisible,
            editClick,
            updateFromToDateTime,
            date,
            time,
            disabledButton,
            confirmWithoutAddress,
            noAddress,
            noAddressButtonText
        } = this.props;

        if (!address) {
            return null
        }

        let bodyContent;
        if (!confirmed && !noAddress) {
            bodyContent = (
                <div className="info">
                    <img src={require('../../img/baseline-face-24px.svg')} alt="" />
                    <span>
                        Info: vor Festlegung der Lieferung bitte Abholung bestätigen!
                    </span>
                </div>
            )
        }

        if (confirmed && noAddress) {
            bodyContent = (
                <div className="info">
                    <img src={require('../../img/baseline-face-24px.svg')} alt="" /><br />
                    <span>
                        {noAddressButtonText}
                    </span>
                </div>
            )
        }

        return (
            <Card className="checkout-step-box">
                <CardHeader>
                    <img alt="" src={icon} />
                    {headerText}
                    {editVisible &&
                        <img alt="" className="checkout-page-address-edit-btn float-right" style={{ cursor: "pointer" }} src={require("../../img/checkoutPage/pen_icon.png")} onClick={editClick} />
                    }
                </CardHeader>
                <CardBody>

                    {
                        needsConfirmation || noAddress ?
                            <div style={{ display: "flex", height: "100%" }}>{bodyContent}</div> : <AddressBox {...address} date={date} time={time} updateFromToDateTime={updateFromToDateTime} />
                    }
                </CardBody>
                <CardFooter>
                    <div className="d-flex flex-row">
                        {
                            <React.Fragment>
                                {
                                    !confirmed &&
                                    <React.Fragment>
                                        <button className="btn btn-info checkout-btn" onClick={() => confirm(true)} disabled={disabledButton}>
                                            BESTÄTIGEN
                                        </button>
                                        &nbsp;&nbsp;
                                        <button className="btn btn-info checkout-btn" style={{ textTransform: "uppercase", fontSize: "14px" }} onClick={confirmWithoutAddress} disabled={disabledButton}>
                                            {noAddressButtonText}
                                        </button>
                                    </React.Fragment>
                                }
                                {
                                    confirmed &&
                                    <button className="btn btn-info checkout-btn" onClick={() => confirm(false)}>
                                        ÄNDERN
                                    </button>
                                }
                            </React.Fragment>
                        }
                    </div>
                </CardFooter>
            </Card>
        );
    }
}

export default CheckoutPageStepBox;