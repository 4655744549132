import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import ServicePageSection from "./ServicesPageSection";

class Services extends Component {
    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Dieser Service erwartet Dich | WÖS.CH</title>
                    <meta name="description" content="Schneller Wäscherei- & Schneiderei-Service in einem, für Dich erdacht. Hier erfährst Du, welche Services Dir zur Verfügung stehen. " />
                    <meta name="image" content="https://www.wös.ch/images/buegeleisen.png" />
                    <meta itemProp="name" content="Dieser Service erwartet Dich | WÖS.CH" />
                    <meta itemProp="description" content="Schneller Wäscherei- & Schneiderei-Service in einem, für Dich erdacht. Hier erfährst Du, welche Services Dir zur Verfügung stehen. " />
                    <meta itemProp="image" content="https://www.wös.ch/images/buegeleisen.png" />
                    <meta name="og:title" content="Dieser Service erwartet Dich | WÖS.CH" />
                    <meta name="og:description" content="Schneller Wäscherei- & Schneiderei-Service in einem, für Dich erdacht. Hier erfährst Du, welche Services Dir zur Verfügung stehen. " />
                    <meta name="og:image" content="https://www.wös.ch/images/buegeleisen.png" />
                    <meta name="og:url" content="https://www.wös.ch/services" />
                </MetaTags>
                <div>
                    <div className="services-page jumbotron" style={{ paddingBottom: "60px"}}>
                        <h1 className="carousel-text-header h1" style={{ paddingBottom: "20px" }}>dein butler</h1>
                        <p  className="carousel-text-content py-20 t1">
                            Klingle nach dem Butler, wann und wo immer Du willst.<br/>                        
                            Schneller Wäscherei- und Schneiderei-Service in einem, für Dich erdacht.<br/>    
                            Rufe Deinen Butler per Smartphone, Tablet oder Computer.
                        </p>
                        <img alt="" src={require('../img/stitching_dein_butler.png')} className="img-services" />
                    </div>
                </div>
                <div className='container mobile-center' style={{textAlign: 'center'}}>
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                            <ServicePageSection
                                image={<img alt="" className="service-page-section-img" src={require('../img/services/Schermata.png')}/>}
                                header="Wäscherei"
                                subHeader="Umweltfreundliche Pflege"
                                textHeader="Wir lieben Deine Kleidung genau wie Du."
                                text="Wir pflegen Deine Wäsche sorgsam mit biologisch abbaubaren Waschmitteln,
                             erneuerbaren Energien und recyclingfähigen Verpackungen.
                            Eine umweltfreundliche Entscheidung für Deine Haut und unsere Welt!"
                            />
                            <br/>
                            <div className="row mobile-center" style={{textAlign: 'end'}}>
                                <div className="col-lg-5 lg-order-1">
                                    <img alt="" className="service-page-section-img" src={require('../img/services/iron.png')}/>
                                </div>
                                <div className="col-lg-7 lg-order-0">
                                    <span>
                                        <h2 className='service-page-section-header h2-service'>
                                           Bügelservice
                                        </h2>
                                        <p className="service-page-section-sub-header">
                                            Faltenfreie Perfektion
                                        </p>
                                    </span>
                                </div>
                                <div
                                    style={{
                                        transformOrigin: 'center 0.5px',
                                        width: '852px',
                                        height: '5px',
                                        borderBottom: '1px solid black',
                                        marginBottom: "20px"
                                    }}/>
                                <span className="service-page-text-container-right">
                         <p className='service-page-section-text-header'>
                            Bei uns ist der Luxus mit Händen spürbar.
                         </p>
                         <p className="service-page-section-text t2 mobile-center" style={{textAlign: 'end'}}>
                           Tadellos gebügelt mit höchster Qualität und Blick für jedes noch so kleine
                            Detail — damit garantieren wir ein einzigartiges und hochwertiges Ergebnis.
                            Erlebe die Schönheit der Perfektion!
                         </p>
                     </span>
                            </div>
                            <br/>
                            <ServicePageSection
                                image={<img alt="" className="service-page-section-img" src={require('../img/services/sewing.png')}/>}
                                header="Schneiderei"
                                subHeader="Präzision bis ins kleinste Detail "
                                textHeader="Wir arbeiten mit Blick für jedes Detail."
                                text="Perfekte Anpassung Deiner Wäsche durch Schneiderarbeiten mit hoher Präzision.
                                Säume, Reissverschlüsse, Nähte, Sticken von Monogrammen und Ersetzen von Knöpfen.
                                Der feine Unterschied liegt im Detail!"
                            />
                        </div>
                        <div className="col-lg-2"></div>
                    </div>

                </div>
                <br/>
                <div style={{backgroundColor:"#ececea", paddingTop: "50px", paddingBottom:"50px"}}>
                <div className="services-block container">
                    <div className="row">
                        <div className="col-lg-3 services-box">
                            <img alt="einfach" className="services-icon" src={require('../img/wos/smart.png')}/>
                            <p className="service-box-header-text h2">einfach</p>
                            <p className="service-box-text t2">
                                Mit ein paar Klicks per Smartphone, Tablet oder Computer, wo immer Du bist!
                                Um den Rest kümmern uns wir.
                            </p>
                        </div>
                        <div className="col-lg-3 services-box">
                            <img alt="bequem" className="services-icon" src={require('../img/wos/bequem.png')}/>
                            <p className="service-box-header-text h2">bequem</p>
                            <p className="service-box-text t2">
                                Abholung und Lieferung in Zürich innerhalb von 24 Stunden.
                                Wo und wann immer Du willst.
                            </p>
                        </div>
                        <div className="col-lg-3 services-box">
                            <img alt="top preis" className="services-icon" src={require('../img/wos/preise-leistungs.png')}/>
                            <p className="service-box-header-text h2"> top preis </p>
                            <p className="service-box-text t2">
                                Wir garantieren Dir ein hervorragendes
                                Preis-Leistungsverhältnis.
                            </p>
                        </div>
                        <div className="col-lg-3 services-box">
                            <img alt="hilfsbereit" className="services-icon" src={require('../img/wos/hilfsbereit.png')} />
                            <p className="service-box-header-text h2"> hilfsbereit </p>
                            <p className="service-box-text t2">
                                Wir freuen uns auf Dein Feedback, Deine Anregungen und Wünsche.
                                Dir den besten Service zu bieten, dafür sind wir da!
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            </React.Fragment>
                )
            }
        }
export default Services;