import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router'
import { connect } from 'react-redux';
import * as actions from "../store/actions";

class Payengine extends Component {

    render() {

        const { location: { search }, removeAllProductsFromShoppingCart } = this.props

        const status = (new URLSearchParams(search)).get("status")

        switch (status) {
            case "success":
                removeAllProductsFromShoppingCart()
                return <Redirect to="/products" />

            //fail or cancel
            default:
                return <Redirect to="/shopping-cart/page2" />

        }
    }
}

const mapDispatchToProps = dispatch => ({
    removeAllProductsFromShoppingCart: () => dispatch(actions.removeAllProductsFromShoppingCart()),
});

export default withRouter(connect(null, mapDispatchToProps)(Payengine))