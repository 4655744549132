import React, { Component } from 'react'
import { Row, Col, UncontrolledPopover, PopoverHeader, PopoverBody, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import * as actions from '../../store/actions'
import { getItemByIndex, getItemsCount } from '../../store/reducers/shoppingCart';
import { formatNumber } from '../../Utils'
import TextField from '@material-ui/core/TextField';

import LeatherItem from './LeatherItem'
import LanudryItem from './LaundryItem'
import TailoringItem from './TailoringItem'
import DeliveryItem from './DeliveryItem'
import AdditionalItem from './AdditionalItem';
import SvgClose from '../shared/SvgClose'
import QuantityField from './QuantityField'
import PerKiloItem from './PerKiloItem';
import LongProcessingIcon from '../shared/LongProcessingIcon';
import GiftCardItem from './GiftCardItem';


class CartItem extends Component {

    state = {
        notes: this.props.item.notes,
        isOpen: false
    }

    notesChange = (e) => {
        this.setState({ notes: e.target.value })
    }

    render() {

        const {
            item,
            itemIndex,
            itemsCount,
            OnRemoveFromShoppingCart,
            OnQuantityAdd,
            OnQuantityRemove
        } = this.props

        const { isOpen } = this.state

        return (
            <React.Fragment>
                <Modal isOpen={isOpen} toggle={() => this.setState(state => ({ isOpen: !state.isOpen, notes: this.props.item.notes }))} centered size="sm">
                    <ModalHeader toggle={() => this.setState(state => ({ isOpen: !state.isOpen, notes: this.props.item.notes }))}>Hinweis</ModalHeader>
                    <ModalBody>
                        <TextField onChange={(e) => this.setState({ notes: e.target.value })} type="search" multiline rowsMax="5"
                            id="standard-basic"
                            value={this.state.notes}
                            style={{ width: "265px", paddingBottom: "20px" }} />
                        <div className="row justify-content-between">
                            <Button
                                className="ml-3"
                                color="secondary"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    this.setState(state => ({ isOpen: !state.isOpen, notes: this.props.item.notes }))
                                }}>
                                ABBRECHEN
                                </Button>
                            <Button
                                className="mr-3"
                                color="primary"
                                type="submit"
                                onClick={() => {
                                    this.props.OnNotesChange(itemIndex, this.state.notes)
                                    this.setState(state => ({ isOpen: !state.isOpen }))
                                }}
                                disabled={!(this.props.item.notes !== this.state.notes)}>
                                BESTÄTIGEN
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
                <Row className="justify-content-md-center cart-item" style={{ marginBottom: "20px" }}>
                    <Col lg className="image-price-container">
                        <button
                            className="close-btn-item close-btn-item-lg ripple"
                            onClick={() => OnRemoveFromShoppingCart(itemIndex, itemsCount)}>
                            <SvgClose />
                        </button>
                        <img src={item.imgUrl}
                            alt="product"
                            className="image-price-container-image" />
                        <div style={{ width: "100%" }}>
                            <div>
                                <div style={{ textTransform: "uppercase", fontWeight: "600" }}>{item.name}
                                    {
                                        item.laundry &&
                                        item.laundry.perKilo === true &&
                                        <React.Fragment>
                                            <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={`id_item_desc_${itemIndex}`} />
                                            <UncontrolledPopover trigger="hover" placement="right" target={`id_item_desc_${itemIndex}`}>
                                                <PopoverHeader>Beinhaltet nur folgende Artikel</PopoverHeader>
                                                <PopoverBody>Badetuch, Geschirr- und Küchenhandtuch, Unterhemd, Unterwäsche, Socken, Strumpfhose, Pyjama, Jeanshosen, T-Shirt, Polo Shirt, Trainingsjacke und Trainingshose.</PopoverBody>
                                            </UncontrolledPopover>
                                        </React.Fragment>
                                    }
                                </div>
                                <div style={{ paddingTop: "10px" }}>{item.description}</div>
                            </div>
                            <div>
                                {
                                    item.laundry &&
                                    !item.laundry.washingAndIroningSpeedy &&
                                    !item.giftCard &&
                                    <LongProcessingIcon longProcessing={item.longProcessing} />
                                }
                                <div className="quantity-price-box">
                                    <div className="quantity-price-part">
                                        <QuantityField
                                            lg
                                            quantity={item.itemQuantity}
                                            add={() => OnQuantityAdd(itemIndex, "item")}
                                            remove={() => OnQuantityRemove(itemIndex, "item")}
                                            quantityType={item.quantityType} />
                                    </div>
                                    <div className="price-cart-item price-cart-item-left">
                                        {formatNumber(item.sumPrice)}
                                        <span className="ml-1" style={{ fontSize: "12px" }}>&#67;&#72;&#70;</span>
                                    </div>
                                </div>
                            </div>
                            <Button size="sm" className="note-btn-item-lg" onClick={() => this.setState(state => ({ isOpen: !state.isOpen }))}
                                style={{
                                }}>
                                <img className="mr-1" alt="" src={require('../../img/checkoutPage/hinweis_icon.png')} style={{ width: "24px", height: "24px" }} />
                                <span style={{ paddingTop: "6px", verticalAlign: "middle", color: "#7E8080" }}>HINWEIS</span>
                            </Button>
                        </div>
                    </Col>
                    {
                        item.giftCard && <GiftCardItem itemIndex={itemIndex} {...item.giftCard} />
                    }
                    {
                        !item.giftCard &&
                        <>
                            <Col lg>
                                {
                                    item.leather &&
                                    <LeatherItem itemIndex={itemIndex} {...item.leather} />
                                }
                                {
                                    item.laundry &&
                                    item.laundry.perKilo &&
                                    <PerKiloItem itemIndex={itemIndex} {...item.laundry} />
                                }
                                {
                                    item.laundry &&
                                    !item.laundry.perKilo &&
                                    <LanudryItem itemIndex={itemIndex} {...item.laundry} />
                                }
                            </Col>
                            <Col lg>
                                {
                                    item.tailoring &&
                                    <TailoringItem itemIndex={itemIndex} {...item.tailoring} />
                                }
                            </Col>
                            <Col lg>
                                {
                                    item.delivery &&
                                    <DeliveryItem itemIndex={itemIndex} {...item.delivery} />
                                }
                            </Col>
                            <Col lg>
                                {
                                    item.additional &&
                                    <AdditionalItem itemIndex={itemIndex}  {...item.additional} />
                                }
                            </Col>
                        </>
                    }
                    <Col lg className="price-section">
                        <div className="price-cart-item price-cart-item-right">
                            {formatNumber(item.sumPrice)}
                            <span className="ml-1" style={{ fontSize: "12px" }}>&#67;&#72;&#70;</span></div>
                        <button
                            className="close-btn-item close-btn-item-sm ripple"
                            onClick={() => OnRemoveFromShoppingCart(itemIndex, itemsCount)}>
                            <SvgClose />
                        </button>
                        <Button size="sm" className="note-btn-item-sm" onClick={() => this.setState(state => ({ isOpen: !state.isOpen }))}
                            style={{
                            }}>
                            <img className="mr-1" alt="" src={require('../../img/checkoutPage/hinweis_icon.png')} style={{ width: "24px", height: "24px" }} />
                            <span style={{ paddingTop: "6px", verticalAlign: "middle", color: "#7E8080" }}>HINWEIS</span>
                        </Button>
                    </Col>
                </Row>
                {/* <Row className="justify-content-md-center cart-item" style={{ marginBottom: "20px" }}>
                    <Col lg className="image-price-container" style={{ minHeight: "unset" }}>
                    </Col>
                    <Col>
                        <Row className="d-flex justify-content-end" style={{ paddingRight: "15px", marginBottom: "15px" }}>
                            <TextField onChange={(e) => this.setState({ notes: e.target.value })} type="search" multiline rowsMax="4"
                                id="standard-basic"
                                label="Hinweis"
                                value={this.state.notes}
                                style={{ width: "300px" }} />
                            {
                                (this.props.item.notes !== this.state.notes) && <Button
                                    title="Speichern"
                                    color="link"
                                    size="sm"
                                    style={{ height: "32px", marginTop: "16px", color: "#2a4a9a" }}
                                    onClick={() => this.props.OnNotesChange(itemIndex, this.state.notes)}
                                >
                                    <FontAwesomeIcon size="sm" icon={faCheck} /></Button>
                            }
                            {
                                this.state.notes && <Button
                                    title="Klar"
                                    color="link"
                                    size="sm"
                                    style={{ height: "32px", marginTop: "16px", color: "#2a4a9a" }}
                                    onClick={() => {
                                        this.setState({ notes: "" })
                                        this.props.OnNotesChange(itemIndex, "")
                                    }}
                                >
                                    <FontAwesomeIcon size="sm" icon={faTimes} /></Button>
                            }
                        </Row>
                    </Col>
                    <Col lg className="price-section">
                    </Col>
                </Row> */}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        item: getItemByIndex(state, ownProps.itemIndex),
        itemsCount: getItemsCount(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        OnRemoveFromShoppingCart: (itemIndex, itemsCount) => dispatch(actions.removeProductFromShoppongCartAndBackToProducts(itemIndex, itemsCount)),
        OnQuantityAdd: (itemIndex, itemType) => dispatch(actions.quantityAdd(itemIndex, itemType)),
        OnQuantityRemove: (itemIndex, itemType) => dispatch(actions.quantityRemove(itemIndex, itemType)),
        OnNotesChange: (itemIndex, text) => dispatch(actions.notesChange(itemIndex, text))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(CartItem)