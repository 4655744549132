export const formatNumber = (number) => {
    return number.toLocaleString("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

export const getStatusName = (status) => {
    switch (status) {
        case 0:
            return "Nicht bezahlt"
        case 1:
            return "Empfangen"
        case 2:
            return "Wird bearbeitet"
        default:
            return "Abgeschlossen"
    }
}

export const getGiftcardStatusName = (status) => {
    switch (status) {
        case 0:
            return "Verwendbar"
        case 1:
            return "Verwendet"
        case 2:
            return "Wird bearbeitet"
        default:
            return "Abgeschlossen"
    }
}

export const place = [
    { postcode: 5400, place: "Baden", kantonId: 1 },
    { postcode: 5408, place: "Ennetbaden", kantonId: 1 },
    { postcode: 5415, place: "Obersiggenthal", kantonId: 1 },
    { postcode: 5430, place: "Wettingen", kantonId: 1 },
    { postcode: 5432, place: "Neuenhof", kantonId: 1 },
    { postcode: 5436, place: "Würenlos", kantonId: 1 },
    { postcode: 5442, place: "Fislisbach", kantonId: 1 },
    { postcode: 5444, place: "Künten", kantonId: 1 },
    { postcode: 5445, place: "Eggenwil", kantonId: 1 },
    { postcode: 5452, place: "Oberrohrdorf", kantonId: 1 },
    { postcode: 5453, place: "Rementschwil", kantonId: 1 },
    { postcode: 5454, place: "Bellikon", kantonId: 1 },
    { postcode: 5524, place: "Niederwil", kantonId: 1 },
    { postcode: 5525, place: "Fischbach-Göslikon", kantonId: 1 },
    { postcode: 5610, place: "Wohlen", kantonId: 1 },
    { postcode: 5620, place: "Bremgarten", kantonId: 1 },
    { postcode: 5621, place: "Zufikon", kantonId: 1 },
    { postcode: 8000, place: "Zürich", kantonId: 1 },
    { postcode: 8001, place: "Zürich", kantonId: 1 },
    { postcode: 8002, place: "Zürich", kantonId: 1 },
    { postcode: 8003, place: "Zürich", kantonId: 1 },
    { postcode: 8004, place: "Zürich", kantonId: 1 },
    { postcode: 8005, place: "Zürich", kantonId: 1 },
    { postcode: 8006, place: "Zürich", kantonId: 1 },
    { postcode: 8008, place: "Zürich", kantonId: 1 },
    { postcode: 8032, place: "Zürich", kantonId: 1 },
    { postcode: 8037, place: "Zürich", kantonId: 1 },
    { postcode: 8038, place: "Zürich", kantonId: 1 },
    { postcode: 8041, place: "Zürich", kantonId: 1 },
    { postcode: 8044, place: "Gockhausen", kantonId: 1 },
    { postcode: 8044, place: "Zürich", kantonId: 1 },
    { postcode: 8045, place: "Zürich", kantonId: 1 },
    { postcode: 8046, place: "Zürich", kantonId: 1 },
    { postcode: 8047, place: "Zürich", kantonId: 1 },
    { postcode: 8048, place: "Zürich", kantonId: 1 },
    { postcode: 8049, place: "Zürich", kantonId: 1 },
    { postcode: 8050, place: "Zürich", kantonId: 1 },
    { postcode: 8051, place: "Zürich", kantonId: 1 },
    { postcode: 8052, place: "Zürich", kantonId: 1 },
    { postcode: 8053, place: "Zürich", kantonId: 1 },
    { postcode: 8055, place: "Zürich", kantonId: 1 },
    { postcode: 8057, place: "Zürich", kantonId: 1 },
    { postcode: 8063, place: "Zürich", kantonId: 1 },
    { postcode: 8064, place: "Zürich", kantonId: 1 },
    { postcode: 8099, place: "Zürich", kantonId: 1 },
    { postcode: 8102, place: "Oberengstringen", kantonId: 1 },
    { postcode: 8103, place: "Unterengstringen", kantonId: 1 },
    { postcode: 8104, place: "Weiningen ZH", kantonId: 1 },
    { postcode: 8105, place: "Regensdorf", kantonId: 1 },
    { postcode: 8105, place: "Watt", kantonId: 1 },
    { postcode: 8106, place: "Adlikon b. Regensdorf", kantonId: 1 },
    { postcode: 8107, place: "Buchs ZH", kantonId: 1 },
    { postcode: 8108, place: "Dällikon", kantonId: 1 },
    { postcode: 8112, place: "Otelfingen", kantonId: 1 },
    { postcode: 8113, place: "Boppelsen", kantonId: 1 },
    { postcode: 8114, place: "Dänikon ZH", kantonId: 1 },
    { postcode: 8115, place: "Hüttikon", kantonId: 1 },
    { postcode: 8117, place: "Fällanden", kantonId: 1 },
    { postcode: 8118, place: "Pfaffhausen", kantonId: 1 },
    { postcode: 8121, place: "Benglen", kantonId: 1 },
    { postcode: 8122, place: "Binz", kantonId: 1 },
    { postcode: 8123, place: "Ebmatingen", kantonId: 1 },
    { postcode: 8124, place: "Maur", kantonId: 1 },
    { postcode: 8125, place: "Zollikerberg", kantonId: 1 },
    { postcode: 8126, place: "Zumikon", kantonId: 1 },
    { postcode: 8127, place: "Forch", kantonId: 1 },
    { postcode: 8132, place: "Hinteregg", kantonId: 1 },
    { postcode: 8132, place: "Egg b. Zürich", kantonId: 1 },
    { postcode: 8133, place: "Esslingen", kantonId: 1 },
    { postcode: 8134, place: "Adliswil", kantonId: 1 },
    { postcode: 8135, place: "Sihlbrugg Station", kantonId: 1 },
    { postcode: 8135, place: "Sihlwald", kantonId: 1 },
    { postcode: 8135, place: "Langnau am Albis", kantonId: 1 },
    { postcode: 8136, place: "Gattikon", kantonId: 1 },
    { postcode: 8142, place: "Uitikon Waldegg", kantonId: 1 },
    { postcode: 8143, place: "Uetliberg", kantonId: 1 },
    { postcode: 8143, place: "Stallikon", kantonId: 1 },
    { postcode: 8152, place: "Glattpark (Opfikon)", kantonId: 1 },
    { postcode: 8152, place: "Glattbrugg", kantonId: 1 },
    { postcode: 8152, place: "Opfikon", kantonId: 1 },
    { postcode: 8153, place: "Rümlang", kantonId: 1 },
    { postcode: 8154, place: "Oberglatt ZH", kantonId: 1 },
    { postcode: 8155, place: "Niederhasli", kantonId: 1 },
    { postcode: 8156, place: "Oberhasli", kantonId: 1 },
    { postcode: 8157, place: "Dielsdorf", kantonId: 1 },
    { postcode: 8158, place: "Regensberg", kantonId: 1 },
    { postcode: 8162, place: "Steinmaur", kantonId: 1 },
    { postcode: 8164, place: "Bachs", kantonId: 1 },
    { postcode: 8165, place: "Oberweningen", kantonId: 1 },
    { postcode: 8165, place: "Schleinikon", kantonId: 1 },
    { postcode: 8165, place: "Schöfflisdorf", kantonId: 1 },
    { postcode: 8166, place: "Niederweningen", kantonId: 1 },
    { postcode: 8172, place: "Niederglatt ZH", kantonId: 1 },
    { postcode: 8173, place: "Neerach", kantonId: 1 },
    { postcode: 8174, place: "Stadel b. Niederglatt", kantonId: 1 },
    { postcode: 8175, place: "Windlach", kantonId: 1 },
    { postcode: 8180, place: "Bülach", kantonId: 1 },
    { postcode: 8181, place: "Höri", kantonId: 1 },
    { postcode: 8182, place: "Hochfelden", kantonId: 1 },
    { postcode: 8184, place: "Bachenbülach", kantonId: 1 },
    { postcode: 8185, place: "Winkel", kantonId: 1 },
    { postcode: 8187, place: "Weiach", kantonId: 1 },
    { postcode: 8192, place: "Zweidlen", kantonId: 1 },
    { postcode: 8192, place: "Glattfelden", kantonId: 1 },
    { postcode: 8193, place: "Eglisau", kantonId: 1 },
    { postcode: 8194, place: "Hüntwangen", kantonId: 1 },
    { postcode: 8195, place: "Wasterkingen", kantonId: 1 },
    { postcode: 8196, place: "Wil ZH", kantonId: 1 },
    { postcode: 8197, place: "Rafz", kantonId: 1 },
    { postcode: 8212, place: "Nohl", kantonId: 1 },
    { postcode: 8245, place: "Feuerthalen", kantonId: 1 },
    { postcode: 8246, place: "Langwiesen", kantonId: 1 },
    { postcode: 8247, place: "Flurlingen", kantonId: 1 },
    { postcode: 8248, place: "Uhwiesen", kantonId: 1 },
    { postcode: 8302, place: "Kloten", kantonId: 1 },
    { postcode: 8303, place: "Bassersdorf", kantonId: 1 },
    { postcode: 8304, place: "Wallisellen", kantonId: 1 },
    { postcode: 8305, place: "Dietlikon", kantonId: 1 },
    { postcode: 8306, place: "Brüttisellen", kantonId: 1 },
    { postcode: 8307, place: "Ottikon b. Kemptthal", kantonId: 1 },
    { postcode: 8307, place: "Effretikon", kantonId: 1 },
    { postcode: 8308, place: "Agasul", kantonId: 1 },
    { postcode: 8308, place: "Illnau", kantonId: 1 },
    { postcode: 8309, place: "Nürensdorf", kantonId: 1 },
    { postcode: 8310, place: "Kemptthal", kantonId: 1 },
    { postcode: 8310, place: "Grafstal", kantonId: 1 },
    { postcode: 8311, place: "Brütten", kantonId: 1 },
    { postcode: 8312, place: "Winterberg ZH", kantonId: 1 },
    { postcode: 8314, place: "Kyburg", kantonId: 1 },
    { postcode: 8315, place: "Lindau", kantonId: 1 },
    { postcode: 8317, place: "Tagelswangen", kantonId: 1 },
    { postcode: 8320, place: "Fehraltorf", kantonId: 1 },
    { postcode: 8322, place: "Madetswil", kantonId: 1 },
    { postcode: 8330, place: "Hermatswil", kantonId: 1 },
    { postcode: 8330, place: "Pfäffikon ZH", kantonId: 1 },
    { postcode: 8331, place: "Auslikon", kantonId: 1 },
    { postcode: 8332, place: "Russikon", kantonId: 1 },
    { postcode: 8335, place: "Hittnau", kantonId: 1 },
    { postcode: 8340, place: "Hinwil", kantonId: 1 },
    { postcode: 8342, place: "Wernetshausen", kantonId: 1 },
    { postcode: 8344, place: "Bäretswil", kantonId: 1 },
    { postcode: 8345, place: "Adetswil", kantonId: 1 },
    { postcode: 8352, place: "Ricketwil (Winterthur)", kantonId: 1 },
    { postcode: 8352, place: "Elsau", kantonId: 1 },
    { postcode: 8353, place: "Elgg", kantonId: 1 },
    { postcode: 8354, place: "Hofstetten ZH", kantonId: 1 },
    { postcode: 8400, place: "Winterthur", kantonId: 1 },
    { postcode: 8404, place: "Reutlingen (Winterthur)", kantonId: 1 },
    { postcode: 8404, place: "Stadel (Winterthur)", kantonId: 1 },
    { postcode: 8404, place: "Winterthur", kantonId: 1 },
    { postcode: 8405, place: "Winterthur", kantonId: 1 },
    { postcode: 8406, place: "Winterthur", kantonId: 1 },
    { postcode: 8408, place: "Winterthur", kantonId: 1 },
    { postcode: 8409, place: "Winterthur", kantonId: 1 },
    { postcode: 8412, place: "Aesch (Neftenbach)", kantonId: 1 },
    { postcode: 8412, place: "Riet (Neftenbach)", kantonId: 1 },
    { postcode: 8412, place: "Hünikon (Neftenbach)", kantonId: 1 },
    { postcode: 8413, place: "Neftenbach", kantonId: 1 },
    { postcode: 8414, place: "Buch am Irchel", kantonId: 1 },
    { postcode: 8415, place: "Berg am Irchel", kantonId: 1 },
    { postcode: 8415, place: "Gräslikon", kantonId: 1 },
    { postcode: 8416, place: "Flaach", kantonId: 1 },
    { postcode: 8418, place: "Schlatt b. Winterthur", kantonId: 1 },
    { postcode: 8421, place: "Dättlikon", kantonId: 1 },
    { postcode: 8422, place: "Pfungen", kantonId: 1 },
    { postcode: 8424, place: "Embrach", kantonId: 1 },
    { postcode: 8425, place: "Oberembrach", kantonId: 1 },
    { postcode: 8426, place: "Lufingen", kantonId: 1 },
    { postcode: 8427, place: "Freienstein", kantonId: 1 },
    { postcode: 8427, place: "Rorbas", kantonId: 1 },
    { postcode: 8428, place: "Teufen ZH", kantonId: 1 },
    { postcode: 8442, place: "Hettlingen", kantonId: 1 },
    { postcode: 8444, place: "Henggart", kantonId: 1 },
    { postcode: 8447, place: "Dachsen", kantonId: 1 },
    { postcode: 8450, place: "Andelfingen", kantonId: 1 },
    { postcode: 8451, place: "Kleinandelfingen", kantonId: 1 },
    { postcode: 8452, place: "Adlikon b. Andelfingen", kantonId: 1 },
    { postcode: 8453, place: "Alten", kantonId: 1 },
    { postcode: 8457, place: "Humlikon", kantonId: 1 },
    { postcode: 8458, place: "Dorf", kantonId: 1 },
    { postcode: 8459, place: "Volken", kantonId: 1 },
    { postcode: 8460, place: "Marthalen", kantonId: 1 },
    { postcode: 8461, place: "Oerlingen", kantonId: 1 },
    { postcode: 8462, place: "Rheinau", kantonId: 1 },
    { postcode: 8463, place: "Benken ZH", kantonId: 1 },
    { postcode: 8464, place: "Ellikon am Rhein", kantonId: 1 },
    { postcode: 8465, place: "Rudolfingen", kantonId: 1 },
    { postcode: 8465, place: "Wildensbuch", kantonId: 1 },
    { postcode: 8466, place: "Trüllikon", kantonId: 1 },
    { postcode: 8467, place: "Truttikon", kantonId: 1 },
    { postcode: 8468, place: "Waltalingen", kantonId: 1 },
    { postcode: 8468, place: "Guntalingen", kantonId: 1 },
    { postcode: 8471, place: "Rutschwil (Dägerlen)", kantonId: 1 },
    { postcode: 8471, place: "Dägerlen", kantonId: 1 },
    { postcode: 8471, place: "Oberwil (Dägerlen)", kantonId: 1 },
    { postcode: 8471, place: "Berg (Dägerlen)", kantonId: 1 },
    { postcode: 8471, place: "Bänk (Dägerlen)", kantonId: 1 },
    { postcode: 8472, place: "Seuzach", kantonId: 1 },
    { postcode: 8474, place: "Dinhard", kantonId: 1 },
    { postcode: 8475, place: "Ossingen", kantonId: 1 },
    { postcode: 8476, place: "Unterstammheim", kantonId: 1 },
    { postcode: 8477, place: "Oberstammheim", kantonId: 1 },
    { postcode: 8478, place: "Thalheim an der Thur", kantonId: 1 },
    { postcode: 8479, place: "Altikon", kantonId: 1 },
    { postcode: 8482, place: "Sennhof (Winterthur)", kantonId: 1 },
    { postcode: 8483, place: "Kollbrunn", kantonId: 1 },
    { postcode: 8484, place: "Neschwil", kantonId: 1 },
    { postcode: 8484, place: "Theilingen", kantonId: 1 },
    { postcode: 8484, place: "Weisslingen", kantonId: 1 },
    { postcode: 8486, place: "Rikon im Tösstal", kantonId: 1 },
    { postcode: 8487, place: "Rämismühle", kantonId: 1 },
    { postcode: 8487, place: "Zell ZH", kantonId: 1 },
    { postcode: 8488, place: "Turbenthal", kantonId: 1 },
    { postcode: 8489, place: "Wildberg", kantonId: 1 },
    { postcode: 8492, place: "Wila", kantonId: 1 },
    { postcode: 8493, place: "Saland", kantonId: 1 },
    { postcode: 8494, place: "Bauma", kantonId: 1 },
    { postcode: 8495, place: "Schmidrüti", kantonId: 1 },
    { postcode: 8496, place: "Steg im Tösstal", kantonId: 1 },
    { postcode: 8497, place: "Fischenthal", kantonId: 1 },
    { postcode: 8498, place: "Gibswil-Ried", kantonId: 1 },
    { postcode: 8499, place: "Sternenberg", kantonId: 1 },
    { postcode: 8523, place: "Hagenbuch ZH", kantonId: 1 },
    { postcode: 8542, place: "Wiesendangen", kantonId: 1 },
    { postcode: 8543, place: "Bertschikon", kantonId: 1 },
    { postcode: 8543, place: "Gundetswil", kantonId: 1 },
    { postcode: 8543, place: "Kefikon ZH", kantonId: 1 },
    { postcode: 8544, place: "Attikon", kantonId: 1 },
    { postcode: 8545, place: "Rickenbach ZH", kantonId: 1 },
    { postcode: 8545, place: "Rickenbach Sulz", kantonId: 1 },
    { postcode: 8546, place: "Menzengrüt", kantonId: 1 },
    { postcode: 8548, place: "Ellikon an der Thur", kantonId: 1 },
    { postcode: 8600, place: "Dübendorf", kantonId: 1 },
    { postcode: 8602, place: "Wangen b. Dübendorf", kantonId: 1 },
    { postcode: 8603, place: "Schwerzenbach", kantonId: 1 },
    { postcode: 8604, place: "Volketswil", kantonId: 1 },
    { postcode: 8605, place: "Gutenswil", kantonId: 1 },
    { postcode: 8606, place: "Nänikon", kantonId: 1 },
    { postcode: 8606, place: "Greifensee", kantonId: 1 },
    { postcode: 8607, place: "Aathal-Seegräben", kantonId: 1 },
    { postcode: 8608, place: "Bubikon", kantonId: 1 },
    { postcode: 8610, place: "Uster", kantonId: 1 },
    { postcode: 8614, place: "Sulzbach", kantonId: 1 },
    { postcode: 8614, place: "Bertschikon (Gossau ZH)", kantonId: 1 },
    { postcode: 8615, place: "Wermatswil", kantonId: 1 },
    { postcode: 8615, place: "Freudwil", kantonId: 1 },
    { postcode: 8616, place: "Riedikon", kantonId: 1 },
    { postcode: 8617, place: "Mönchaltorf", kantonId: 1 },
    { postcode: 8618, place: "Oetwil am See", kantonId: 1 },
    { postcode: 8620, place: "Wetzikon ZH", kantonId: 1 },
    { postcode: 8623, place: "Wetzikon ZH", kantonId: 1 },
    { postcode: 8624, place: "Grüt (Gossau ZH)", kantonId: 1 },
    { postcode: 8625, place: "Gossau ZH", kantonId: 1 },
    { postcode: 8626, place: "Ottikon (Gossau ZH)", kantonId: 1 },
    { postcode: 8627, place: "Grüningen", kantonId: 1 },
    { postcode: 8630, place: "Rüti ZH", kantonId: 1 },
    { postcode: 8632, place: "Tann", kantonId: 1 },
    { postcode: 8633, place: "Wolfhausen", kantonId: 1 },
    { postcode: 8634, place: "Hombrechtikon", kantonId: 1 },
    { postcode: 8635, place: "Dürnten", kantonId: 1 },
    { postcode: 8636, place: "Wald ZH", kantonId: 1 },
    { postcode: 8637, place: "Laupen ZH", kantonId: 1 },
    { postcode: 8639, place: "Faltigberg", kantonId: 1 },
    { postcode: 8700, place: "Küsnacht ZH", kantonId: 1 },
    { postcode: 8702, place: "Zollikon", kantonId: 1 },
    { postcode: 8703, place: "Erlenbach ZH", kantonId: 1 },
    { postcode: 8704, place: "Herrliberg", kantonId: 1 },
    { postcode: 8706, place: "Meilen", kantonId: 1 },
    { postcode: 8707, place: "Uetikon am See", kantonId: 1 },
    { postcode: 8708, place: "Männedorf", kantonId: 1 },
    { postcode: 8712, place: "Stäfa", kantonId: 1 },
    { postcode: 8713, place: "Uerikon", kantonId: 1 },
    { postcode: 8714, place: "Feldbach", kantonId: 1 },
    { postcode: 8800, place: "Thalwil", kantonId: 1 },
    { postcode: 8802, place: "Kilchberg ZH", kantonId: 1 },
    { postcode: 8803, place: "Rüschlikon", kantonId: 1 },
    { postcode: 8804, place: "Au ZH", kantonId: 1 },
    { postcode: 8805, place: "Richterswil", kantonId: 1 },
    { postcode: 8810, place: "Horgen", kantonId: 1 },
    { postcode: 8815, place: "Horgenberg", kantonId: 1 },
    { postcode: 8816, place: "Hirzel", kantonId: 1 },
    { postcode: 8820, place: "Wädenswil", kantonId: 1 },
    { postcode: 8824, place: "Schönenberg ZH", kantonId: 1 },
    { postcode: 8825, place: "Hütten", kantonId: 1 },
    { postcode: 8833, place: "Samstagern", kantonId: 1 },
    { postcode: 8902, place: "Urdorf", kantonId: 1 },
    { postcode: 8903, place: "Birmensdorf ZH", kantonId: 1 },
    { postcode: 8904, place: "Aesch ZH", kantonId: 1 },
    { postcode: 8905, place: "Arni", kantonId: 1 },
    { postcode: 8905, place: "Islisberg", kantonId: 1 },
    { postcode: 8906, place: "Bonstetten", kantonId: 1 },
    { postcode: 8907, place: "Wettswil", kantonId: 1 },
    { postcode: 8908, place: "Hedingen", kantonId: 1 },
    { postcode: 8909, place: "Zwillikon", kantonId: 1 },
    { postcode: 8910, place: "Affoltern am Albis", kantonId: 1 },
    { postcode: 8911, place: "Rifferswil", kantonId: 1 },
    { postcode: 8912, place: "Obfelden", kantonId: 1 },
    { postcode: 8913, place: "Ottenbach", kantonId: 1 },
    { postcode: 8914, place: "Aeugstertal", kantonId: 1 },
    { postcode: 8914, place: "Aeugst am Albis", kantonId: 1 },
    { postcode: 8915, place: "Hausen am Albis", kantonId: 1 },
    { postcode: 8916, place: "Jonen", kantonId: 1 },
    { postcode: 8917, place: "Oberlunkhofen", kantonId: 1 },
    { postcode: 8918, place: "Unterlunkhofen", kantonId: 1 },
    { postcode: 8919, place: "Rottenschwil", kantonId: 1 },
    { postcode: 8925, place: "Ebertswil", kantonId: 1 },
    { postcode: 8926, place: "Hauptikon", kantonId: 1 },
    { postcode: 8926, place: "Uerzlikon", kantonId: 1 },
    { postcode: 8926, place: "Kappel am Albis", kantonId: 1 },
    { postcode: 8932, place: "Mettmenstetten", kantonId: 1 },
    { postcode: 8933, place: "Maschwanden", kantonId: 1 },
    { postcode: 8934, place: "Knonau", kantonId: 1 },
    { postcode: 8942, place: "Oberrieden", kantonId: 1 },
    { postcode: 8951, place: "Fahrweid", kantonId: 1 },
    { postcode: 8952, place: "Schlieren", kantonId: 1 },
    { postcode: 8953, place: "Dietikon", kantonId: 1 },
    { postcode: 8954, place: "Geroldswil", kantonId: 1 },
    { postcode: 8955, place: "Oetwil an der Limmat", kantonId: 1 },
    { postcode: 8956, place: "Killwangen", kantonId: 1 },
    { postcode: 8957, place: "Spreitenbach", kantonId: 1 },
    { postcode: 8962, place: "Bergdietikon", kantonId: 1 },
    { postcode: 8964, place: "Rudolfstetten-Friedlisberg", kantonId: 1 },
    { postcode: 8965, place: "Berikon", kantonId: 1 },
    { postcode: 8966, place: "Oberwil-Lieli", kantonId: 1 },
    { postcode: 8967, place: "Widen", kantonId: 1 },
]