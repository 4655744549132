import React, { Component } from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';

import { getItems, checkoutPageEnabled, onlyGiftCards } from '../../store/reducers/shoppingCart';
import CartItem from './CartItem'
import * as actions from "../../store/actions";

class ShoppingCart extends Component {
    checkLoggedIn = e => {
        if (!this.props.token) {
            e.preventDefault();
            this.props.OnAuthModalToggle();
            // Trigger loggin modal
        }
    };

    render() {
        const { items, checkoutPageButtonEnabled, onlyGiftCards } = this.props;

        if (items.length === 0) {
            return <Redirect to="/products" />
        }

        return (
            <div style={{ backgroundColor: "#ECECEA", minHeight: "calc(100vh - 322px)" }}>
                <Container className="container-shopping-cart">
                    {
                        items.map((_product, index) => <CartItem key={index} itemIndex={index} />)
                    }
                    <Row>
                        <Link to="/shopping-cart/page2" className='ml-auto' onClick={this.checkLoggedIn}>
                            <button className='btn btn-info' style={{ backgroundColor: "#2a4a9a" }}
                                disabled={!checkoutPageButtonEnabled}>
                                {onlyGiftCards ? "WEITER ZUR ZAHLUNG" : "ANNAHME UND AUSGABE WÄHLEN"}
                            </button>
                        </Link>
                    </Row>
                </Container>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: getItems(state),
        token: state.auth.token,
        checkoutPageButtonEnabled: checkoutPageEnabled(state),
        onlyGiftCards: onlyGiftCards(state)
    };
};

const mapDispatchToProps = dispatch => ({
    OnAuthModalToggle: () => dispatch(actions.authModalToggle()),
});


export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)