import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';

import NewPassword from './NewPassword'

import * as actions from '../../store/actions'


class NewPasswordModal extends React.Component {

  render() {

    const { newPasswordShowed, OnNewPasswordModalToggle } = this.props

    return (
      <Modal isOpen={newPasswordShowed} toggle={() => OnNewPasswordModalToggle()} centered size="sm">
        <ModalHeader toggle={() => OnNewPasswordModalToggle()}>Neues Passwort eingeben</ModalHeader>
        <ModalBody>
          <NewPassword />
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newPasswordShowed: state.auth.newPasswordShowed
  };
};

const mapDispatchToProps = dispatch => {
  return {
    OnNewPasswordModalToggle: () => dispatch(actions.newPasswordModalToggle())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordModal)