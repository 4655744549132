import * as actionTypes from './actionTypes';

export const getUsers = (token) => ({
    type: actionTypes.GET_USERS,
    token
})

export const getUsersStart = () => ({
    type: actionTypes.GET_USERS_START,
})

export const getUserStart = () => ({
    type: actionTypes.GET_USER_START,
})

export const getUsersFail = (error) => ({
    type: actionTypes.GET_USERS_FAIL,
    error
})

export const getUsersSuccess = (list, dataState, pages) => ({
    type: actionTypes.GET_USERS_SUCCESS,
    list,
    dataState,
    pages
})

export const getAdminUsers = (token, dataState) => ({
    type: actionTypes.GET_ADMIN_USERS,
    token,
    dataState
})

export const getUserDetails = (token, id) => ({
    type: actionTypes.GET_USER_DETAILS,
    token,
    id
})

export const getUserDetailsSuccess = (user) => ({
    type: actionTypes.GET_USER_DETAILS_SUCCESS,
    user
})

export const getUserDetailsFail = (error) => ({
    type: actionTypes.GET_USER_DETAILS_FAIL,
    error
})