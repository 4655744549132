import {
    GET_ORDERS_START,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    GET_ORDER_DETAILS_SUCCESS,
    GET_ORDER_DETAILS_FAIL,
    ORDER_STATUS_CHANGE_SUCCESS,
    ORDER_STATUS_CHANGE_FAIL,
    ORDER_STATUS_CHANGE_START
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    list: [],
    pages: 0,
    dataState: {
        pageSize: 20,
        page: 0,
        sorted: [{ id: "dateCreated", desc: true }],
        filtered: [],
    }
};

const getOrdersStart = (state) => {
    return ({
        ...state,
        loading: true,
        list: [],
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        }
    })
}

const getOrdersSuccess = (state, { list, dataState, pages }) => {
    return ({
        ...state,
        pages,
        loading: false,
        list: [...list],
        dataState: {
            ...dataState,
            sorted: [...dataState.sorted],
            filtered: [...dataState.filtered]
        }
    })
}

const getOrdersFail = (state, { error }) => {
    return ({
        ...state,
        loading: false,
        list: [],
        error: error,
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        }
    })
}

const getOrderDetailsSuccess = (state, { order }) => {
    return ({
        ...state,
        loading: false,
        list: [...state.list],
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        },
        order: order
    })
}

const getOrderDetailsFail = (state, { error }) => {
    return ({
        ...state,
        loading: false,
        list: [...state.list],
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        },
        error: error
    })
}

const orderStatusChangeSuccess = (state, { newStatus }) => {
    return ({
        ...state,
        loading: false,
        list: [...state.list],
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        },
        order: {
            ...state.order,
            loadingStatusChange: false,
            status: newStatus
        }
    })
}

const orderStatusChangeStart = (state) => {
    return ({
        ...state,
        loading: false,
        list: [...state.list],
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        },
        order: {
            ...state.order,
            loadingStatusChange: true
        }
    })
}

const orderStatusChangeFail = (state, { error }) => {
    return ({
        ...state,
        loading: false,
        list: [...state.list],
        dataState: {
            ...state.dataState,
            sorted: [...state.dataState.sorted],
            filtered: [...state.dataState.filtered]
        },
        order: {
            ...state.order,
            loadingStatusChange: false,
            error: error
        }
    })
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS_START:
            return getOrdersStart(state, action)
        case GET_ORDERS_SUCCESS:
            return getOrdersSuccess(state, action)
        case GET_ORDERS_FAIL:
            return getOrdersFail(state, action)
        case GET_ORDER_DETAILS_SUCCESS:
            return getOrderDetailsSuccess(state, action)
        case GET_ORDER_DETAILS_FAIL:
            return getOrderDetailsFail(state, action)
        case ORDER_STATUS_CHANGE_START:
            return orderStatusChangeStart(state, action)
        case ORDER_STATUS_CHANGE_SUCCESS:
            return orderStatusChangeSuccess(state, action)
        case ORDER_STATUS_CHANGE_FAIL:
            return orderStatusChangeFail(state, action)
        default:
            return state;
    }
};