import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'

export default ({
    decoration,
    decorationSelected,
    decorationPrice,
    plated,
    platedSelected,
    platedPrice,
    sequined,
    sequinedSelected,
    sequinedPrice
}) =>
    (
        <ListGroup flush className="shopping-item-section">
            <ListGroupItem className="item-main-header">
                <strong>Zuschlag</strong>
            </ListGroupItem>
            {
                decorationSelected &&
                <ListGroupItem className="item-button" active={decorationSelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">mit Deko</div>
                        <div className="small-price">
                            {formatNumber(decorationPrice)}
                        </div>
                    </div>
                </ListGroupItem>
            }
            {
                platedSelected &&
                <ListGroupItem className="item-button" active={platedSelected}>
                    <div className="d-flex item-header-font">
                        <div className="item-header">mit Plissée</div>
                        <div className="small-price">
                            {formatNumber(platedPrice)}
                        </div>
                    </div>
                </ListGroupItem>
            }
            {
                sequinedSelected &&
                <ListGroupItem className="item-button" active={sequinedSelected}>
                    <div className="d-flex">
                        <div className="item-header">mit Strass, Pailetten</div>
                        <div className="small-price">
                            {formatNumber(sequinedPrice)}
                        </div>
                    </div>
                </ListGroupItem>
            }
        </ListGroup>
    )