import React, { Component } from "react";

class ServicePageSection extends Component {
    render() {
        const { subHeader, header, textHeader, text } = this.props;

        return (
            <div className="row mobile-center" style={{ textAlign: "start" }}>
                <div className="col-lg-5">
                    {this.props.image}
                </div>
                <div className="col-lg-7">
                    <span>
                        <h2 className='service-page-section-header h2-service'>
                            {header}
                        </h2>
                        <p className="service-page-section-sub-header">
                            {subHeader}
                        </p>
                    </span>
                </div>
                <div
                    style={{
                        transformOrigin: 'center 0.5px',
                        width: '852px',
                        height: '5px',
                        borderBottom: '1px solid black',
                        marginBottom: "20px"
                    }} />
                <span className="service-page-text-container">
                    <p className='service-page-section-text-header'>
                        {textHeader}
                    </p>
                    <p className="service-page-section-text t2">
                        {text}
                    </p>
                </span>
            </div>)
    }
};

export default ServicePageSection;