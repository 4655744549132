import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Background from '../img/firmen.png'

const delay = t => new Promise(resolve => setTimeout(resolve, t));

class ForCompanies extends Component {

    state = {
        sendButtonText: 'SENDEN',
        email: ''
    }

    onSubmit = async () => {

        // eslint-disable-next-line no-useless-escape
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
            this.setState({ sendButtonText: 'UNGÜLTIG' })
            await delay(500)
            this.setState({ sendButtonText: 'SENDEN' })
        } else {
            this.setState({ sendButtonText: 'SENDEN...' })
            try {
                await fetch('/api/newsletters', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email: this.state.email })
                });
                this.setState({ sendButtonText: 'GESCHICKT' })
                await delay(500)
                this.setState({ sendButtonText: 'SENDEN', email: '' })
            } catch (error) {
                this.setState({ sendButtonText: 'ERROR' })
                await delay(500)
                this.setState({ sendButtonText: 'SENDEN' })
            }
        }
    }


    render() {

        return (
            <div>
                <MetaTags>
                    <title>Der Wäscheservice für Ihr Unternehmen | WÖS.CH</title>
                    <meta name="description" content="Extrem günstige Bedingungen Für Unternehmen – Ein Concierge-Service, der 7 Tage die Woche rund um die Uhr verfügbar ist, ohne zusätzliche Kosten." />
                    <meta name="image" content="https://www.wös.ch/images/naehmaschine.png" />
                    <meta itemProp="name" content="Der Wäscheservice für Ihr Unternehmen | WÖS.CH" />
                    <meta itemProp="description" content="Extrem günstige Bedingungen Für Unternehmen – Ein Concierge-Service, der 7 Tage die Woche rund um die Uhr verfügbar ist, ohne zusätzliche Kosten." />
                    <meta itemProp="image" content="https://www.wös.ch/images/naehmaschine.png" />
                    <meta name="og:title" content="Der Wäscheservice für Ihr Unternehmen | WÖS.CH" />
                    <meta name="og:description" content="Extrem günstige Bedingungen Für Unternehmen – Ein Concierge-Service, der 7 Tage die Woche rund um die Uhr verfügbar ist, ohne zusätzliche Kosten." />
                    <meta name="og:image" content="https://www.wös.ch/images/naehmaschine.png" />
                    <meta name="og:url" content="https://www.wös.ch/for-companies" />
                </MetaTags>
                <div style={{ background: `url(${Background}) no-repeat`, backgroundSize: 'cover', minHeight: "calc(100vh - 65px)", backgroundPosition: "50% 50%" }} >
                    <div className="container" style={{ paddingBottom: "10px", textAlign: "center", maxWidth: "900px" }}>
                        <h1 className="carousel-text-header h1" style={{ textAlign: "center", paddingTop: "140px" }}>
                            wös.ch für unternehmen
                        </h1>
                        <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                            Wir bieten Ihren Mitarbeitenden einen Concierge-Service, der 7 Tage die Woche rund um die Uhr per <br /> Smartphone, Tablet oder Computer verfügbar ist.
                        </p>
                        <img alt="" src={require('../img/stitching_fur_firmen.png')} className="img-company" />
                        <p className="carousel-text-content t1" style={{ textAlign: "center", paddingTop: "100px" }}>
                            Vereinbarungen mit extrem günstigen Bedingungen, mit Abholung und Lieferung direkt
                            im Büro oder zu Hause.
                        </p>
                        <br />
                        <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                            Dabei entstehen für das Unternehmen keine zusätzlichen Kosten.
                        </p>
                        <br />
                        <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                            Eine Vereinbarung beantragen
                        </p>
                        <div className="newsletter">
                            <input type="email"
                                className="for-companies-mail-input"
                                placeholder="E-Mail*"
                                pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })} />
                            <button
                                className="for-companies-mail-input-btn"
                                onClick={this.onSubmit}>
                                <span>{this.state.sendButtonText}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

};

export default ForCompanies;