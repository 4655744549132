import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import auth from '../reducers/auth';
import shoppingCart from '../reducers/shoppingCart';
import products from '../reducers/products';
import layout from '../reducers/layout';
import addresses from '../reducers/addresses';
import bonuses from '../reducers/bonuses';
import orders from '../reducers/orders';
import users from '../reducers/users';
import categories from '../reducers/categories';


export default (history) => combineReducers({
    router: connectRouter(history),
    auth,
    shoppingCart,
    products,
    layout,
    addresses,
    orders,
    users,
    categories,
    bonuses
})