import React, { Component } from 'react'
import { connect } from 'react-redux';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as actions from '../../store/actions'
import { getStatusName } from '../../Utils'

class StatusChange extends Component {

    render() {

        const { status, orderStatusChange, token, id, loadingStatusChange } = this.props

        if (status === 0)
            return <div style={{ fontWeight: "bold" }}>{getStatusName(0)}</div>

        return (
            <UncontrolledDropdown>
                <DropdownToggle caret>
                    {!loadingStatusChange && <React.Fragment>{getStatusName(status)}<span className="mr-2"></span></React.Fragment>}
                    {loadingStatusChange && <React.Fragment>{getStatusName(status)}<FontAwesomeIcon icon={faSpinner} className="ml-2 mr-2" spin /></React.Fragment>}
                </DropdownToggle>
                <DropdownMenu>
                    {
                        status === 1 &&
                        <DropdownItem onClick={() => orderStatusChange(token, id, 2)}>Fang an zu arbeiten <FontAwesomeIcon icon={faArrowRight} className="ml-2 mr-2" />
                            <span style={{ backgroundColor: "#b0c8db" }}>{getStatusName(2)}</span>
                        </DropdownItem>
                    }
                    {
                        status === 2 &&
                        <DropdownItem onClick={() => orderStatusChange(token, id, 3)}>Komplette Arbeit <FontAwesomeIcon icon={faArrowRight} className="ml-2 mr-2" />
                            <span style={{ backgroundColor: "#b0c8db" }}>{getStatusName(3)}</span></DropdownItem>
                    }
                    {
                        status === 3 &&
                        <DropdownItem onClick={() => orderStatusChange(token, id, 1)}>Zurück zum Start <FontAwesomeIcon icon={faArrowRight} className="ml-2 mr-2" />
                            <span style={{ backgroundColor: "#b0c8db" }}>{getStatusName(1)}</span>
                        </DropdownItem>
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        orderStatusChange: (token, id, newStatus) => dispatch(actions.orderStatusChange(token, id, newStatus))
    };
};

const mapStateToProps = state => ({
    loadingStatusChange: state.orders.order ? state.orders.order.loadingStatusChange : false,
    status: state.orders.order.status,
    token: state.auth.token,
    id: state.orders.order._id
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusChange);