import React, { Component } from 'react'
import { ListGroup, ListGroupItem, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../Utils'

import * as actions from '../../store/actions'
import { speedyOptionDisabled } from '../../store/reducers/shoppingCart'

class LaundryItem extends Component {

    render() {

        const {
            OnNoLaundryCheck,
            OnWashingAndIroningCheck,
            OnWashingAndIroningSpeedyCheck,
            itemIndex,
            speedyOptionDisabled
        } = this.props

        const idNoWash = `idNoWash${itemIndex}`
        const idNoSpeedy = `idNoSpeedy${itemIndex}`
        const idSpeedy = `idSpeedy${itemIndex}`

        const {
            noLaundry,
            noLaundrySelected,
            noLaundryPrice,
            washingAndIroning,
            washingAndIroningSelected,
            washingAndIroningPrice,
            washingAndIroningSpeedy,
            washingAndIroningSpeedySelected,
            washingAndIroningSpeedyPrice,
            withSpotCleaning,
            withSpotCleaningSelected,
            withSpotCleaningPrice,
            cleaningAndRefreshing
        } = this.props

        return (
            <ListGroup flush className="shopping-item-section">
                <ListGroupItem className="item-main-header">
                    <strong>Wäscherei</strong>
                </ListGroupItem>
                {
                    noLaundry &&
                    <ListGroupItem
                        className="item-button"
                        active={noLaundrySelected}
                        onClick={() => OnNoLaundryCheck(itemIndex)}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">Keine Wäscherei
                            <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={idNoWash} />
                                <UncontrolledPopover trigger="hover" placement="left" target={idNoWash}>
                                    <PopoverHeader>Keine Wäscherei</PopoverHeader>
                                    <PopoverBody>Wir bieten dir hochpräzise Schneiderarbeiten an. Saum, Reissverschluss und Knopfersatz.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            {
                                noLaundryPrice === 0 &&
                                <div className="ml-auto" style={{ textTransform: "none" }}>Gratis</div>
                            }
                            {
                                noLaundryPrice > 0 &&
                                <div className="small-price">
                                    {formatNumber(noLaundryPrice)}
                                </div>
                            }
                        </div>
                    </ListGroupItem>
                }
                {
                    washingAndIroning &&
                    <ListGroupItem
                        className="item-button"
                        active={washingAndIroningSelected}
                        onClick={() => {
                            if (!washingAndIroningSelected)
                                OnWashingAndIroningCheck(itemIndex)
                        }}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">{cleaningAndRefreshing ? "Reinigen & Auffrischen" : "Waschen & Bügeln"}
                                <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={idNoSpeedy} />
                                <UncontrolledPopover trigger="hover" placement="left" target={idNoSpeedy}>
                                    <PopoverHeader>{cleaningAndRefreshing ? "Reinigen & Auffrischen" : "Waschen & Bügeln"}</PopoverHeader>
                                    <PopoverBody>{cleaningAndRefreshing ?
                                        "Mit unseren innovativen Tiefenreinigungsverfahren werden Ihre Pelze bestmöglich geschont und die Form dieser edlen Kleidungsstücke wird bewahrt. Ganz ohne Rückstände, Reizstoffe und Gerüche." :
                                        "Handgefertigte Qualität in umweltfreundlicher Verarbeitung und Liebe zum Detail, hervorragendes Preis-Leistungs-Verhältnis."}
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <div className="small-price">
                                {formatNumber(washingAndIroningPrice)}
                            </div>
                        </div>
                        {
                            withSpotCleaning &&
                            <div className="item-checkbox" onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                this.props.OnWithSpotCleaningCheck(this.props.itemIndex)
                            }}>
                                <div className="d-flex item-header-font">
                                    <div className="checkbox">
                                        <input type="checkbox"
                                            id={`withSpotCleaningCheck_${itemIndex}`}
                                            checked={!!withSpotCleaningSelected}
                                            onChange={() => { }}
                                            readOnly
                                        />
                                        <label htmlFor={`withSpotCleaningCheck_${itemIndex}`}></label>
                                    </div>
                                    <div style={{ textTransform: "none" }}>Mit Fleckenreinigung</div>
                                    {
                                        withSpotCleaningPrice === 0 &&
                                        <div className="ml-auto" style={{ textTransform: "none" }}>Gratis</div>
                                    }
                                    {
                                        withSpotCleaningPrice > 0 &&
                                        <div className="small-price">
                                            {formatNumber(withSpotCleaningPrice)}
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </ListGroupItem>
                }
                {
                    washingAndIroningSpeedy &&
                    <ListGroupItem
                        className="item-button"
                        disabled={speedyOptionDisabled}
                        active={washingAndIroningSpeedySelected}
                        onClick={() => OnWashingAndIroningSpeedyCheck(itemIndex)}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">Waschen & Bügeln<br />Speedy
                            <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={idSpeedy} />
                                <UncontrolledPopover trigger="hover" placement="left" target={idSpeedy}>
                                    <PopoverHeader>Waschen & Bügeln Speedy</PopoverHeader>
                                    <PopoverBody>Abholung & Lieferung deiner Kleider innerhalb eines Tages. Angebot gültig nur für die Stadt Zürich.</PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <div className="small-price">
                                <span>{formatNumber(washingAndIroningSpeedyPrice + washingAndIroningPrice)}</span>
                            </div>
                        </div>
                    </ListGroupItem>
                }
            </ListGroup>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        speedyOptionDisabled: speedyOptionDisabled(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        OnNoLaundryCheck: (itemIndex) => dispatch(actions.noLaundryCheck(itemIndex)),
        OnWashingAndIroningCheck: (itemIndex) => dispatch(actions.washingAndIroningCheck(itemIndex)),
        OnWithSpotCleaningCheck: (itemIndex) => dispatch(actions.withSpotCleaningCheck(itemIndex)),
        OnWashingAndIroningSpeedyCheck: (itemIndex) => dispatch(actions.washingAndIroningSpeedyCheck(itemIndex)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LaundryItem)