import React from 'react';
import MetaTags from 'react-meta-tags';
import Background from '../img/eco.png'

const EcoFriendly = () => {
    return (
        <div>
            <MetaTags>
                <title>Unsere Wäscherei ist Eco-Friendly | WÖS.CH</title>
                <meta name="description" content="Von biologisch abbaubaren Waschmitteln & Verpackungen bis hin zur Arbeit mit erneuerbarer Energie – WÖS.CH ist Eco-Friendly!" />
                <meta name="image" content="https://www.wös.ch/images/waschbrett.png" />
                <meta itemProp="name" content="Unsere Wäscherei ist Eco-Friendly | WÖS.CH" />
                <meta itemProp="description" content="Von biologisch abbaubaren Waschmitteln & Verpackungen bis hin zur Arbeit mit erneuerbarer Energie – WÖS.CH ist Eco-Friendly!" />
                <meta itemProp="image" content="https://www.wös.ch/images/waschbrett.png" />
                <meta name="og:title" content="Unsere Wäscherei ist Eco-Friendly | WÖS.CH" />
                <meta name="og:description" content="Von biologisch abbaubaren Waschmitteln & Verpackungen bis hin zur Arbeit mit erneuerbarer Energie – WÖS.CH ist Eco-Friendly!" />
                <meta name="og:image" content="https://www.wös.ch/images/waschbrett.png" />
                <meta name="og:url" content="https://www.wös.ch/eco-friendly" />
            </MetaTags>
            <div style={{ background: `url(${Background}) no-repeat`, backgroundSize: 'cover', minHeight: "calc(100vh - 65px)", backgroundPosition: "50% 50%" }} >
                <div className="container" style={{ paddingBottom: "10px" }}>
                    <h1 className="carousel-text-header h1" style={{ textAlign: "center", paddingTop: "100px", paddingBottom: "10px" }}>
                        Unsere wäscherei ist eco-friendly!
                    </h1>
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        Eine wichtige Entscheidung für Deine Haut und unsere Welt!
                </p>
                    <img alt="" src={require('../img/stitching_ecofriendly.png')} className="img-eco" />
                    <p className="carousel-text-content t1" style={{ textAlign: "center", paddingTop: "100px" }}>
                        Wir arbeiten umweltfreundlich. Denn wir benützen biologisch abbaubare
                        Waschmittel und achten auf die Umwelt. Bei allem was wir tun.
                </p>
                    <br />
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        Deine Wäsche und Kleidung haben bei uns höchste Priorität,
                        wir pflegen sie mit viel Liebe und achten dabei auf die Welt, in der wir leben.
                        Da wir stets mit Umsicht handeln, ist es uns gelungen, alle unsere Tätigkeiten umweltgerecht
                        zu gestalten.
                </p>
                    <br />
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        In unserer umweltfreundlichen Wäscherei steht Ihr, Du,
                        Deine Kleidung und die Umwelt, im Mittelpunkt.
                        Wir pflegen Deine Wäsche sorgsam, mit erneuerbaren Energien und biologisch abbaubaren Waschmitteln und Verpackungen.
                </p>
                    <br />
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        Denn nur die Achtung des gesamten Ökosystems ermöglicht es uns,
                        zu lernen, nachhaltig zu leben, ohne das Gleichgewicht der Natur zu stören.
                        Deshalb sollte jeder Mensch sich für eine gesündere Umwelt verantwortlich fühlen.
                </p>
                    <br />
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        Aus genau diesem Grund möchten wir von wös.ch mit gutem Beispiel vorangehen
                        und uns am Modell der natürlichen Systeme orientieren, die in der Lage sind,
                        sich effizient selbst zu regulieren.
                </p>
                </div>
            </div>
        </div>
    )
};

export default EcoFriendly;